import React, {useEffect, useState} from 'react'
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react'
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

function Contracts() {

    const [driverHash, setDriverHash] = useState('')
    const [noDocFound, setNoDocFound] = useState(false);

    useEffect(() => {
        ApiService.getDriverSsn('contracts').then(it => {
            setDriverHash(it);
            checkLink(it);
        });
    }, []);

    const checkLink = (link: any) => {
        const requestOptions = {
            method: 'GET',
            headers: {}
        };
        fetch('https://westernexpressmobile.com/agreements/' + link + "-0.pdf", requestOptions)
            .then(response => {
                if (response.status === 404) {
                    setNoDocFound(true);
                }
            })
            .catch(() => {
                setNoDocFound(true);
            })
    }

    const agreementHandler = () => {
        if (noDocFound) {
            toastStore.getState().setToast('No Contract Found');
        } else {
            window.open('https://westernexpressmobile.com/agreements/' + driverHash + "-0.pdf", '_blank')
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Contracts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    <IonItem lines="none">
                        <div className='item-container'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff", padding: "8px", borderRadius: "10px"}}
                             onClick={agreementHandler}>
                            <p>Training Agreement</p>
                            <p>View</p>
                        </div>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default Contracts
