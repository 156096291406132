import React, {useEffect, useState} from "react"
import {IonContent, IonFooter, IonHeader, IonItem, IonPage, IonToolbar} from "@ionic/react"
import Search from "./Search"
import Contacts from "./Contacts"
import Load from "./Load"
import ProfileInfo from "./ProfileInfo"
import Trip from "./Trip"
import Money from "./Money"
import Docs from "./Docs"
import Nav from "./Nav"
import {docsSearch, loadSearch, moneySearch, statsSearch, tripSearch} from "../Util"
import Onboard from "./Onboard"
import Toolbar from "./Toolbar"
import chevronRight from "../imgs/chevron-right.png"
import {Link} from "react-router-dom";

const Home: React.FC = () => {
    const [isSearching, setIsSearching] = useState(false)
    const [showProfile, setShowProfile] = useState(true)
    const [featured, setFeatured] = useState<JSX.Element[]>([])
    const [featuredHeader, setFeaturedHeader] = useState("")
    const [quickSelect, setQuickSelect] = useState("load")

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const product = urlParams.get('search')
        if (product) {
            setIsSearching(true)
        } else {
            setIsSearching(false)
        }
    }, [])


    function handleSearch(value: string) {
        if (value) {
            matchKeywords(value)
        } else {
            setFeaturedHeader('')
            setQuickSelect("load")
            setShowProfile(true)
        }
    }

    function handleMode(value: boolean) {
        setIsSearching(value)
    }

    function matchKeywords(key: string) {
        let keywords = {
            load: loadSearch,
            trip: tripSearch,
            money: moneySearch,
            docs: docsSearch,
            stats: statsSearch
        }
        keywords.load.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("Load")
                handleQuickSelect("load")
                setComponent(<Load/>)
            }
        })
        keywords.trip.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Trip")
                handleQuickSelect("trip")
                setComponent(<Trip/>)
            }
        })
        keywords.money.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Pay")
                handleQuickSelect("money")
                setComponent(<Money/>)
            }
        })
        keywords.docs.forEach(word => {
            if (key.toLocaleUpperCase() === word) {
                setShowProfile(false)
                setFeaturedHeader("My Docs")
                handleQuickSelect("docs")
                setComponent(<Docs/>)
            }
        })
    }

    function setComponent(component: JSX.Element) {
        let temp = new Set(featured)
        temp.add(component)

        let arr = Array.from(temp)
        temp = new Set(arr)
        setFeatured([...Array.from(temp)])
    }


    function getComponent() {
        return (
            <div>
                <IonItem color="light" lines="none">
                    <div className="item-container"><h3><strong style={{margin: '0 8px'}}>Suggested: {featuredHeader}</strong></h3></div>
                </IonItem>
                <IonItem color="light" lines="none">{featured[featured.length - 1]}</IonItem>
            </div>
        )
    }

    function handleQuickSelect(selected: string) {
        let bar = document.getElementById("scroll-bar")
        if (bar) {
            switch (selected) {
                case "load":
                    bar.style.marginLeft = "20%"
                    break
                case "trip":
                    bar.style.marginLeft = "120%"
                    break
                case "money":
                    bar.style.marginLeft = "220%"
                    break
                case "docs":
                    bar.style.marginLeft = "320%"
                    break
                default:
                    break
            }
        }
        setQuickSelect(selected)
    }

    function getQuickSelect() {
        switch (quickSelect) {
            case "load":
                return <IonItem lines="none" color="light"><Load/></IonItem>
            case "trip":
                return <IonItem lines="none" color="light"><Trip/></IonItem>
            case "money":
                return <IonItem lines="none" color="light"><Money/></IonItem>
            case "docs":
                return <IonItem lines="none" color="light"><Docs/></IonItem>
            default:
                break
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                    {isSearching ? <Search onChange={handleSearch} featured={featuredHeader}/> : null}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <div className="main">
                    <div>
                        {showProfile ? (
                            <div>
                                <ProfileInfo/>
                                <IonItem color="light" lines="none">
                                    <div className="nav-wrapper">
                                        <ul className="quick-nav">
                                            <li onClick={() => handleQuickSelect("load")}>Load</li>
                                            <li onClick={() => handleQuickSelect("trip")}>Trip</li>
                                            <li onClick={() => handleQuickSelect("money")}>Pay</li>
                                            <li onClick={() => handleQuickSelect("docs")}>Docs</li>
                                            <hr id="scroll-bar"/>
                                        </ul>
                                    </div>
                                </IonItem>
                                {getQuickSelect()}
                            </div>
                        ) : getComponent()}
                    </div>
                    <div>
                        {localStorage.getItem("type") !== "driver" ? <Onboard/> : null}
                        <Contacts/>
                        <IonItem lines="none" color="light">
                            <div className="item-container">
                                <Link className='western-button-link' to="/registervehicle">
                                    <div className="space-between">
                                        <p>Register personal vehicle</p>
                                        <img src={chevronRight} style={{height: "1.8vh"}} alt="arrow"/>
                                    </div>
                                </Link>
                            </div>
                        </IonItem>
                    </div>
                </div>
            </IonContent>
            <IonFooter>
                <Nav onSearch={handleMode}/>
            </IonFooter>
        </IonPage>
    )
}

export default Home
