import React, {useEffect, useState} from 'react';
import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToast,
    IonToolbar
} from '@ionic/react';
import {chevronBackOutline, createOutline, mail, mailOutline, person, personOutline, refreshOutline, send, settings, settingsOutline, speedometer, speedometerOutline} from 'ionicons/icons';
import {RefresherEventDetail} from '@ionic/core';
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import Toolbar from './Toolbar';
import Nav from './Nav';
import {ApiService} from "../api/ApiService";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {useHistory} from "react-router-dom";

type messageChain = {
    time: string;
    message: string;
    from: string;
}

interface Message {
    id: number;
    conversationId: number;
    fromUsername: string;
    fromUserId: number;
    toUsername: string;
    toUserId: number;
    time: string;
    subject: string;
    body: string;
    type: string;
    unread_icon: any;
    read_icon: any;
    retrieved: boolean;
    linked_messages: messageChain[]
}

const firebaseConfig = {
    apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
    authDomain: "western-express-5002c.firebaseapp.com",
    databaseURL: "https://western-express-5002c.firebaseio.com",
    projectId: "western-express-5002c",
    storageBucket: "western-express-5002c.appspot.com",
    messagingSenderId: "799211811572",
    appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
    measurementId: "G-V5MNJV768B"
}

const app = initializeApp(firebaseConfig)

const handleEventLog = (eventType: string, info: string) => {
    const analytics = getAnalytics(app);
    logEvent(analytics, eventType, {eventType: info})
    console.log(eventType);
}

const Inbox: React.FC = () => {

    const history = useHistory();
    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));

    const [msg, setMsg] = useState<Message>();
    const [allMessages, setAllMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [showMessage, setShowMessage] = useState(false)
    const [text, setText] = useState<string>('');
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<string>('');
    const [sqlStart, setSqlStart] = useState(0);
    const [msgHistory, setMsgHistory] = useState([]);

    useEffect(() => {
        getMessages(sqlStart);
        ApiService.sendEventCommand('Inbox View', 'eventCheck', 'User has checked their inbox');
    }, []);

    async function getMessages(start: number) {
        ApiService.getAllInboxMessages('Inbox', {withBodies: 1, start: start, length: 20}).then(it => {
            processData(it, 'all');
            setSqlStart(start + 10);
        });
    }

    const processData = (messageList: any[], type?: string) => {
        let value = type ? type : 'unread'
        let tmessages = messageList;
        let temp = allMessages;
        let conversationIdArray: any[] = []
        for (let i = 0; i < tmessages.length; i++) {
            if (conversationIdArray.includes(tmessages[i].conversationId)) {
                continue;
            } else {
                conversationIdArray.push(tmessages[i].conversationId)
            }
            let msg: any = tmessages[i];
            msg.time = tmessages[i].created.replace('T', ' ').replace('Z', ' ').split('.000');
            msg.unread_icon = tmessages[i].type === "maintenance" ? settings : tmessages[i].type === "qualcomm" ? speedometer : tmessages[i].type === "dm" ? person : mail
            msg.read_icon = tmessages[i].type === "maintenance" ? settingsOutline : tmessages[i].type === "qualcomm" ? speedometerOutline : tmessages[i].type === "dm" ? personOutline : mailOutline
            msg.linked_messages = tmessages[i].type === "maintenance" ? [{time: tmessages[i].created, from: tmessages[i].fromUsername, message: tmessages[i].body}] : []
            temp.push(msg);
        }
        temp.sort(function (x, y) {
            return new Date(y.time).getTime() - new Date(x.time).getTime();
        })

        if (value !== 'unread') {
            setAllMessages(temp);
        }
        setMessages(temp);
    }

    async function processMsg(json: any, msg: any) {
        let lm = [];
        for (let i = 0; i < json.results[0].messages.length; i++) {
            lm.push({time: json.results[0].messages[i].created.toString().replace('T', ' ').slice(0, -8), message: json.results[0].messages[i].body, from: json.results[0].messages[i].fromUsername})
        }
        msg.linked_messages = lm.reverse();
        setMsg(msg);
        setMsgHistory(await messageHistory(msg.linked_messages));
        setShowMessage(true);
        let msg_idx = messages.findIndex(obj => obj.id === msg.id);
        messages[msg_idx].retrieved = true
        let msg_idx1 = allMessages.findIndex(obj => obj.id === msg.id);
        allMessages[msg_idx1].retrieved = true
    }

    const openMessage = (msg: Message) => {
        ApiService.getAllInboxMessages("Inbox", {withBodies: 1, conversationId: msg.conversationId}).then(it => processMsg(it, msg));
    }

    async function addMessageToHistory(body: string, date: string) {
        let array: any = msg?.linked_messages;
        const newMsg: messageChain = {time: date.toString().replace('T', ' ').slice(0, -8), message: body, from: userInfo.driverCode};
        array.push(newMsg);
        const tempArray = await messageHistory(array)
        let tempObj: any = msg
        tempObj.linkded_messages = tempArray
        setMsg(tempObj);
        setMsgHistory(tempArray);

    }

    const sendMessage = (args: {
        body?: string,
        subject?: string,
        type?: string,
        toUserId?: string,
        fromUserId?: string,
        conversationId?: number,
    }, body: string, type: string) => {
        const sentDate = (new Date()).toJSON();
        ApiService.inboxSendMessage('Inbox', args).then(() => {
            ApiService.sendEventCommand('Sent Message', 'eventCheck', 'User has sent a message');
            if (type !== 'reply') {
                setShowMessage(false);
            }
            if (body !== '') {
                if (type === 'reply') {
                    addMessageToHistory(body, sentDate);
                }
            } else {
                addMessageToHistory(body, sentDate);
            }
        });
    }

    const messageHistory = async (msgArray: any) => {
        return await (msgArray.map(function (element: any) {
            return <IonItem key={element.message} lines="none">
                <div className='item-container' style={{backgroundColor: "#fff", borderRadius: "10px"}}>
                    <p className='subtitle'>From: {element.from}</p><p className='subtitle'>Sent: {element.time}</p>
                    <p className='title'>{element.message}</p>
                </div>
            </IonItem>
        }));
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        window.location.replace('./Inbox');
        event.detail.complete();
    }

    const handleNewMessage = () => {
        if (text !== '') {
            let args = {
                body: text,
                subject: userInfo.driverCode,
                type: 'dm',
                toUserId: "MCKJ",
                fromUserId: userInfo.driverCode,
                loginToken: localStorage.getItem('token')
            }
            sendMessage(args, args.body, 'new');
            resetForm();
        } else {
            setToast("Please fill out all fields to send your message");
            setShowToast(true);
        }
    }

    const handleReply = () => {
        let args = {
            body: text,
            subject: msg?.subject,
            type: msg?.type,
            toUserId: "MCKJ",
            fromUserId: userInfo.driverCode,
            conversationId: msg?.conversationId,
            loginToken: localStorage.getItem('token')
        }
        sendMessage(args, text, 'reply');
        resetForm();
    }

    function resetForm() {
        setText('');
        setNewMessage(false);
    }

    async function loadMore($event: CustomEvent<void>) {
        await getMessages(sqlStart + 10);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                </IonToolbar>
            </IonHeader>
            <IonContent id="inbox" fullscreen color="light">
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={toast}
                    color="danger"
                    duration={3000}
                />

                <IonModal isOpen={showMessage} cssClass='my-custom-class'>
                    {!newMessage ? <>
                            <IonHeader>
                                <IonToolbar color="primary">
                                    <IonButtons slot="start">
                                        <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => setShowMessage(false)}>Back</p></IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent id="inbox" fullscreen color='light'>
                                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                                    <IonRefresherContent pullingIcon={refreshOutline} pullingText="Pull to refresh" refreshingSpinner="circles"></IonRefresherContent>
                                </IonRefresher>
                                <div style={{"width": "100%", padding: "4%", height: '83%'}}>
                                    <IonList style={{"width": "100%", "height": "100%", "overflowY": "scroll"}} color="light">
                                        {msgHistory}
                                    </IonList>
                                </div>
                                <IonItem lines="none" color="light">
                                    <div className='item-container'>
                                        <div className="input-container">
                                            <input id="issues" className="input" type="text" placeholder="" value={text} onChange={e => setText(e.target.value!)}/>
                                            <p className="placeholder">Reply</p>
                                        </div>

                                    </div>
                                    <IonIcon icon={send} onClick={() => {
                                        handleReply()
                                    }}/>
                                </IonItem>
                            </IonContent>
                        </>
                        :
                        <>
                            <IonHeader>
                                <IonToolbar color="primary">
                                    <IonButtons slot="start">
                                        <IonButton style={{padding: 0}} onClick={function () {
                                            setShowMessage(false);
                                            setNewMessage(false)
                                        }}>
                                            <IonIcon slot="icon-only" icon={chevronBackOutline}/>
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>New Message</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <IonItem lines="none" color="light">
                                    <div className='item-container'>
                                        <div className="input-container">
                                            <input id="issues" className="input" type="text" placeholder="" value={text} onChange={e => setText(e.target.value!)}/>
                                            <p className="placeholder">Type Here</p>
                                        </div>

                                        <p onClick={() => {
                                            handleNewMessage();
                                            handleEventLog('mail_send', 'submit-thruOutbox')
                                        }}>Send</p>
                                    </div>
                                </IonItem>
                            </IonContent>
                        </>
                    }
                </IonModal>

                <div className="messageList">
                    <IonRefresher className="refresher" slot="fixed" pullMin={80} onIonRefresh={doRefresh} closeDuration="2000ms">
                        <IonRefresherContent pullingIcon={refreshOutline} pullingText="Pull to refresh" refreshingSpinner="circles"></IonRefresherContent>
                    </IonRefresher>
                    <IonList id="messageList">
                        {messages.map(function (msg) {
                            return <IonItem color="light" lines='none'>
                                <div className='item-container' style={{backgroundColor: "#FFF", padding: "8px", borderRadius: "10px"}} key={msg.id} onClick={() => openMessage(msg)}>
                                    <IonAvatar className="message-icon" slot="start">
                                        <IonIcon size="large" icon={msg.retrieved ? msg.read_icon : msg.unread_icon}/>
                                    </IonAvatar>
                                    <IonLabel className="message-content">
                                        <strong>{msg.fromUsername}</strong>
                                        <p className='subtitle'>{msg.time}</p>
                                        <p style={{color: 'black', lineHeight: '1.5'}}>{msg.body}</p>
                                    </IonLabel>
                                </div>
                            </IonItem>
                        })}
                        {messages.length === 0 ? <IonItem lines="none">
                            <div style={{width: "100%", textAlign: "center"}}>
                                <h5><b>No Messages</b></h5>
                            </div>
                        </IonItem> : null}
                    </IonList>
                </div>
                <IonInfiniteScroll threshold='100px' id='infiniteScroll' onIonInfinite={(e: CustomEvent<void>) => loadMore(e)}>
                    <IonInfiniteScrollContent loading-spinner='dots' loadingText="Fetching messages..."></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
            <button id="new_message" onClick={() => {
                setShowMessage(true);
                setNewMessage(true)
            }}>
                <IonIcon slot="icon-only" icon={createOutline}/>
            </button>
            <IonFooter>
                <Nav onSearch={() => history.push('/home?search=true')}/>
            </IonFooter>
        </IonPage>
    );
};

export default Inbox;
