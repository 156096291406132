import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {config} from '../Constants'
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {VideoInfo} from "../api/model/VideosList";
import {DocumentInfo} from "../api/model/DocumentsList";

const MaintenanceVids: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [showModalDoc, setShowModalDoc] = useState(false);
    const [vidLink, setVidLink] = useState("");
    const [docs, setDocList] = useState<DocumentInfo[]>([]);
    const [doc, setDoc] = useState<DocumentInfo>();
    const [vidIds, setVidIds] = useState<VideoInfo[]>();

    useEffect(() => {
        ApiService.getDocsList('maintenance', {isLp: 1, type: 'maintenance'}).then(it => setDocList(it));
        ApiService.getVideosList('maintenance', {isLp: 1, type: 'maintenance'}).then(it => setVidIds(it));
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={localStorage.getItem('username') ? "/Home" : "/"}/>
                    </IonButtons>
                    <IonTitle>Maintenance</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton onClick={function () {
                                    setShowModal(false);
                                    setVidLink("")
                                }}>
                                    <IonIcon slot="icon-only" icon={close}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <iframe title="maintenance video" id="video-modal" width="100%" height="345" src={vidLink}>
                        </iframe>
                    </IonContent>
                </IonModal>

                <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton onClick={() => {
                                    setShowModalDoc(false);
                                    setDoc({id: 0, title: '', type: '', extension: '', sortOrder: 0, hidden: false});
                                }}>
                                    <IonIcon slot="icon-only" icon={close}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div style={{height: '100vh'}}>
                            <iframe src={'https://westernexpressmobile.com/docs/' + doc?.type + '_' + doc?.id + '.' + doc?.extension + "#toolbar=0"} style={{height: '100%', width: '100%'}}
                                    title="docs" allowFullScreen/>
                        </div>
                    </IonContent>
                </IonModal>
                <h1 style={{textAlign: 'center', color: 'black'}}>Videos</h1>
                <div className='video-gal'>{vidIds?.filter(it => it.active === 1).map((vidIds, idx) => <div className="video-box" key={idx} onClick={function () {
                    setShowModal(true);
                    setVidLink('https://www.youtube.com/embed/' + vidIds.youtube)
                }}>
                    <iframe title="safety video" height="200" src={'https://www.youtube.com/embed/' + vidIds.youtube}>
                    </iframe>
                    <p className='title'>{vidIds.title}</p>
                </div>)}</div>
                <IonList style={{marginBottom: "9vh", padding: '8px'}}>
                    <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
                    {docs.length ? null : <p className='title' style={{textAlign: "center"}}>No documents</p>}
                    {docs.map(function (doc) {
                        let id = doc.id
                        let title = doc.title
                        return config.isPlatformSci ?
                            <IonItem key={id} onClick={() => {
                                setDoc(doc);
                                setShowModalDoc(true)
                            }} lines="none">
                                <div className='item-container' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p>{title}</p>
                                    <img src={documentIcon} className="link-icon" alt="doc"/>
                                </div>
                            </IonItem> :
                            <a href={'https://westernexpressmobile.com/docs/' + doc?.type + '_' + doc?.id + '.' + doc?.extension + "#toolbar=0"} target="_blank" rel="noreferrer"
                               style={{textDecoration: "none"}}>
                                <IonItem key={id} onClick={() => {
                                    setDoc(doc);
                                }} lines="none" style={{margin: "8px 0", borderRadius: "10px"}} color="white">
                                    <div className='item-container' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff"}}>
                                        <p>{title}</p>
                                        <img src={documentIcon} className="link-icon" alt="doc"/>
                                    </div>
                                </IonItem>
                            </a>
                    })}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default MaintenanceVids;
