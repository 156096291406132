import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonItemDivider, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {config} from '../Constants'
import img1 from '../imgs/benefit_1.png';
import img2 from '../imgs/benefit_2.png';
import img3 from '../imgs/benefit_3.png';
import img4 from '../imgs/benefit_4.png';
import img5 from '../imgs/benefit_5.png';
import img6 from '../imgs/benefit_6.png';
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";

const Benefits: React.FC = () => {

    const [docs, setDocList] = useState<DocumentInfo[]>([]);

    useEffect(() => {
        ApiService.getDocsList('benefits', {isLp: 1, type: 'hr'}).then(it => setDocList(it));
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Benefits</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList style={{marginBottom: "9vh", padding: '8px'}}>
                    <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
                    {docs.length ? null : <IonItem lines="none">
                        <div style={{width: "100%", textAlign: "center"}}>
                            <h5><b>No Documents</b></h5>
                        </div>
                    </IonItem>}
                    {docs.map(function (doc) {
                        return config.isPlatformSci ?
                            <IonItem key={doc.id} lines="none">
                                <div className='item-container' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p>{doc.title}</p>
                                    <img src={documentIcon} className="link-icon" alt="doc"/>
                                </div>
                            </IonItem> :
                            <a href={'https://westernexpressmobile.com/docs/' + doc?.type + '_' + doc?.id + '.' + doc?.extension + "#toolbar=0"} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                                <IonItem key={doc.id} lines="none" style={{margin: "8px 0", borderRadius: "10px"}} color="white">
                                    <div className='item-container' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: "#ffffff"}}>
                                        <p>{doc.title}</p>
                                        <img src={documentIcon} className="link-icon" alt="doc"/>
                                    </div>
                                </IonItem>
                            </a>
                    })}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Benefits;
