import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';
import {ApiService} from "../api/ApiService";

const KeyContacts: React.FC = () => {

    const [contacts, setContacts] = useState<any>({});

    useEffect(() => {
        ApiService.getContacts('keyContacts', {category: 'keyContacts', isLp: 1}).then(it => {
            const contacts: any = {};
            it.forEach(contact => {
                contacts[contact.subcategory] = (contacts[contact.subcategory] || []).concat(contact);
            });
            setContacts(contacts);
        });
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Key Contacts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{marginBottom: "9vh"}}>
                    {Object.keys(contacts).map(key => <div key={key}>
                        {contacts[key].map((val: any, index: number) => <IonItem lines="none" key={index}><ContactCard contact={val}/></IonItem>)}
                    </div>)}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default KeyContacts;
