import {IonCol, IonGrid, IonRow, IonToggle} from "@ionic/react";
import React from "react";


const OrientationDriverEvalItem: React.FC<{ description: string, status: boolean, value: number }> = ({description, status, value}) => {

    const color = value < 0 ? "danger" : "success"

    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <p>{description}</p>
                </IonCol>
                <IonCol size="3">
                    <p>{status ? "YES" : "NO"} <span style={{color: "red"}}>{value < 0 ? value : ""}</span></p>
                </IonCol>
                <IonCol size="3">
                    <IonToggle checked={status} color={color}></IonToggle>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default OrientationDriverEvalItem
