import React, {useEffect, useState} from 'react';
import bell from '../imgs/notification.png';
import SideMenu from './SideMenu';
import {config} from '../Constants';
import {ApiService} from "../api/ApiService";

const Header: React.FC<{ title: string, titleStyle: {} }> = ({titleStyle}) => {

    const [openedMenu, setOpenedMenu] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        ApiService.getPushNotifications("toolBar").then(it => setNotificationCount(it.filter(notification => !notification.read).length));
    }, []);

    const menuOpener = () => {
        console.log("openedMenu");
        setOpenedMenu(!openedMenu);
    }

    return (
        <div>
            {openedMenu ? <SideMenu handleMenuToggle={menuOpener}/> : null}
            <div className={config.isPlatformSci ? "header-plat-sci" : "header"}>
                <div className={config.isPlatformSci ? "header-item-area-plat-sci" : "header-item-area"}>
                    <div className={config.isPlatformSci ? "header-menu-platsci" : "header-menu"} onClick={() => {
                        setOpenedMenu(!openedMenu);
                        setShowNotificationModal(false);
                    }}>
                        <div className="menu-line"></div>
                        <div className="menu-line"></div>
                        <div className="menu-line"></div>
                    </div>
                    <div className={config.isPlatformSci ? "header-title-plat-sci" : "header-title"} onClick={() => {
                        setShowNotificationModal(!showNotificationModal);
                        setOpenedMenu(false)
                    }} style={titleStyle}>
                        <img src={bell} alt="leaderboard" style={{paddingTop: '1px', width: '33px',}}/>
                        {notificationCount !== 0 ? <div className='header-notification-badge'>{notificationCount}</div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;
