import React, {useState} from 'react';
import {IonContent, IonHeader, IonIcon, IonItem, IonModal, IonToolbar} from '@ionic/react';
import {ellipsisHorizontal} from 'ionicons/icons';
import 'react-circular-progressbar/dist/styles.css';
import placeholder from "../imgs/placeholder_profile.png";
import chevronRight from "../imgs/chevron-right.png"
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {Link} from "react-router-dom";

const ProfileInfo: React.FC = () => {

    const {driver}: { driver: DriverProfile } = userStore((state: UserState) => ({driver: state.driver}));

    const [profileImage, setProfileImage] = useState(localStorage.getItem("profilePicture") || "")
    const [showLatest, setShowLatest] = useState(false)

    const toProperCase = (str: string) => {
        if (str !== undefined) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        } else {
            return "";
        }
    }

    return (
        <IonItem lines="none" color="light">
            <IonModal isOpen={showLatest}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <p style={{fontFamily: "Montserrat, sans-seriff", padding: "0 8px"}} onClick={() => setShowLatest(false)}>Back</p>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem color='light' lines="none">
                        <div className='item-container'>
                            <p>Latest Update</p>
                            <h1><strong>Leader Boards!</strong></h1>
                            <p className='subtitle'>Check out the leader boards to see the MPG standings. Do you have the best fuel efficiency in your division?!</p>
                            <Link className='western-button-link' to="/rewards">
                                <div className="space-between">
                                    <p>Go to Division Standings</p>
                                    <img src={chevronRight} style={{height: "1.8vh"}} alt="arrow"/>
                                </div>
                            </Link>
                        </div>
                    </IonItem>
                </IonContent>
            </IonModal>
            <div className="item-container">
                <div>
                    <div style={{display: "flex", gap: "2vw", alignItems: "center"}}>
                        <img className="profile-photo" onError={() => setProfileImage(placeholder)} src={profileImage} alt="avatar"/>
                        <div style={{display: "flex", margin: 'auto'}}>
                            <div className="game-stat">
                                <p>Unit:</p>
                                <strong>{driver?.unit || '-'}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="green" style={{margin: "8px"}}><b><span
                    style={{color: "black"}}>{toProperCase(driver.name)}</span> {localStorage.getItem('username')! !== undefined ? '|' : ''} {localStorage.getItem('username')!}</b></h5>
                <Link to="/currentload" className='western-button-link'>
                    <div className="green" id="current-load-button">
                        <p>Current Load</p>
                        <img src={chevronRight} style={{height: "1.8vh"}} alt="arrow"/>
                    </div>
                </Link>
                <div style={{display: "grid", gridTemplateColumns: "70% 30%"}}>
                    <Link to="/editprofile" className='western-button-link'>
                        <div className="outline" id="centered">
                            <p>Edit Profile</p>
                        </div>
                    </Link>
                    <div className='western-button-link' onClick={() => setShowLatest(!showLatest)}>
                        <div id="centered" style={{minWidth: "10vw"}}>
                            <p><IonIcon icon={ellipsisHorizontal} style={{color: showLatest ? "#212121" : "#666667", fontSize: 'min(3.6vw, 18px)'}}></IonIcon></p>
                        </div>
                    </div>
                </div>
            </div>
        </IonItem>
    );
};

export default ProfileInfo;
