import React from 'react';
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';

const ServiceLocationsTX: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Help/servicelocations"/>
                    </IonButtons>
                    <IonTitle>Service Location: TX</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <iframe title="PA Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-C99R1wtlYAZdY8SxrbY_te_Exs7Tfus&q=1669+World+Trade+Center+Loop,Laredo+TX">
                </iframe>
                <IonCard>
                    <IonCardContent>
                        <h1>1669 World Trade Center Loop</h1>
                        <h2>Laredo, TX 78045</h2>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Directions</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Coming Soon!
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default ServiceLocationsTX;
