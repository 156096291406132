import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonNote,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";

function TrainerFeedback() {

    const history = useHistory();

    const [overallScore, setOverallScore] = useState('');
    const [overallExp, setOverallExp] = useState('');
    const [cleanliness, setCleanliness] = useState('');
    const [policyScore, setPolicyScore] = useState('');
    const [conductScore, setConductScore] = useState('');
    const [dotReg, setDotReg] = useState('');
    const [logTraining, setLogTraining] = useState('');
    const [refWEXP, setRefWEXP] = useState('');
    const [refTrainer, setRefTrainer] = useState('');
    const [complaint, setComplaint] = useState('');
    const [comments, setComments] = useState<string>();
    const [trainee, setTrainee] = useState<Trainee>({} as Trainee);

    useEffect(() => {
        ApiService.getTraineeProfile('trainerFeedback').then(it => {
            setTrainee(it);
        });
    }, []);

    function collapseContent(id: string, persistent: boolean) {
        const collapseSelect = document.getElementById(id);
        if (collapseSelect != null && !persistent) {
            collapseSelect.classList.toggle("dropdown-list");
        } else if (collapseSelect != null && persistent) {
            collapseSelect.style.setProperty('display', 'inline');
        }
    }

    let surveyNum_Resp = [
        {
            'question': "Rate overall experience on the trainer's truck:",
            'value': overallScore,
            'func': setOverallScore
        },
        {
            'question': "Rate cleanliness of inside the truck:",
            'value': cleanliness,
            'func': setCleanliness
        },
        {
            'question': "Trainer knowledge of Western Express policies?",
            'value': policyScore,
            'func': setPolicyScore
        },
        {
            'question': "Rate professinalism of trainer:",
            'value': conductScore,
            'func': setConductScore
        },
        {
            'question': "Rate effectiveness of log training:",
            'value': logTraining,
            'func': setLogTraining,
        },
        {
            'question': "Rate overall training experience:",
            'value': overallExp,
            'func': setOverallExp,
        },
    ]

    let surveyBool_Resp = [
        {
            'question': "Would you reccommend trainer to other trainees?",
            'value': refWEXP,
            'func': setRefWEXP,
        },
        {
            'question': "Would you reccommend Western Express to other drivers?",
            'value': refTrainer,
            'func': setRefTrainer,
        },
        {
            'question': "Does the training reflect the importance of DOT Rules & Regulation(s)?",
            'value': dotReg,
            'func': setDotReg,
        },
        {
            'question': "At any point, did the trainer cause you to feel uncomfortable?",
            'value': complaint,
            'func': setComplaint,
        },
    ]

    async function submitFeedback() {
        ApiService.submitTrainerFeedback("trainerFeedback", {
            traineeCode: trainee.traineeCode,
            trainerCode: trainee.trainerCode,
            overallScore: overallScore,
            cleanliness: cleanliness,
            policyScore: policyScore,
            conductScore: conductScore,
            logTraining: logTraining,
            overallExp: overallExp,
            dotReg: dotReg,
            refWEXP: refWEXP,
            refTrainer: refTrainer,
            complaint: complaint,
            comments: comments
        }).then(() => {
            toastStore.getState().setSuccessToast('Success! - Report Submited ...');
            history.push('/home');
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home"/>
                    </IonButtons>
                    <IonTitle>Course Survey</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen style={{marginBottom: '35px'}}>
                <div style={{overflowY: 'visible', marginBottom: '18vh', padding: '15px'}}>
                    <IonButton id="western-button" expand="block" onClick={() => collapseContent("content", false)}>Trainee Profile <span style={{float: 'right'}}>[ ⇅ ]</span></IonButton>
                    <IonCardContent class="dropdown-list" id="content">
                        <IonList>
                            <IonItem>
                                <IonLabel>Trainee:</IonLabel>
                                <IonCard style={{color: 'black', padding: '5px'}} slot="start">{trainee.traineeName}</IonCard>
                                <IonNote slot="end" color="black">{trainee.traineeCode}</IonNote>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Trainer:</IonLabel>
                                <IonCard style={{color: 'black', padding: '5px'}} slot="start">{trainee.trainerName}</IonCard>
                                <IonNote slot="end" color="black">{trainee.trainerCode + ' *'} </IonNote>
                            </IonItem>
                            <IonItem>
                                <IonLabel>DM Code:</IonLabel>
                                <IonNote slot="end" color="black">{trainee.dmCode}</IonNote>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Current Course(Day):</IonLabel>
                                <IonNote slot="end" color="black">{trainee.currentCourseDay}</IonNote>
                            </IonItem>
                        </IonList>
                    </IonCardContent>

                    <IonItemDivider>* DRIVER TRAINER FEEDBACK *</IonItemDivider>
                    <IonCard style={{padding: '10px'}}>
                        <IonNote> <strong> *[1] = VERY POOR ... *[5] = VERY GOOD </strong><br/><br/>Please rate each of the following questions with responses 1 - 5:</IonNote>
                    </IonCard>

                    <IonCardContent>
                        {surveyNum_Resp.map((element, index) => <>
                            <IonItem>
                                <IonLabel className='ion-text-wrap'>{element.question}</IonLabel>
                                <IonSelect id={"numResp_" + index} value={element.value} onIonChange={e => element.func(e.detail.value)}>
                                    <IonSelectOption>1</IonSelectOption>
                                    <IonSelectOption>2</IonSelectOption>
                                    <IonSelectOption>3</IonSelectOption>
                                    <IonSelectOption>4</IonSelectOption>
                                    <IonSelectOption>5</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </>)}
                    </IonCardContent>
                    <IonItemDivider>* OVERALL EXPEREINCE FEEDBACK *</IonItemDivider>
                    {surveyBool_Resp.map((element, index) => <>
                        <IonCard style={{padding: '10px', '--background': 'transparent'}}>
                            <IonLabel>{element.question}</IonLabel>
                            <IonRadioGroup id={"boolResp_" + index} value={element.value} style={{padding: '25px'}} onIonChange={e => element.func(e.detail.value)}>
                                <hr></hr>
                                <br/><IonRadio value={1}>{1}</IonRadio><IonLabel style={{marginPadding: '10px', verticalAlign: 'top'}}>Yes.</IonLabel>
                                <br/><IonRadio value={0}>{0}</IonRadio><IonLabel style={{marginPadding: '10px', verticalAlign: 'top'}}>No.</IonLabel>
                            </IonRadioGroup>
                        </IonCard>
                    </>)}
                    <IonItemDivider>* COMMENTS *</IonItemDivider>
                    <IonItem>
                        <IonTextarea id='western' value={comments} onIonChange={e => setComments(e.detail.value!)} maxlength={200} cols={56} placeholder={'Max of 200 Characters ...'}></IonTextarea>
                        <IonButton onClick={() => {
                            submitFeedback()
                        }}>Submit</IonButton>
                    </IonItem>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default TrainerFeedback;

