import React from 'react';
import '../theme/appLoading.css';
import logo from '../imgs/lp_pro.svg';
import {create} from "zustand";

export interface LoadingState {
  requestCount: number;
  increaseCount: () => void;
  decreaseCount: () => void;
}

export const loadingStore = create<LoadingState>((set, get) => ({
  requestCount: 0,
  increaseCount: () => {
    const count = get().requestCount + 1;
    document.getElementById('loading-spinner')?.classList.add('active');
    set(() => ({requestCount: count}));
  },
  decreaseCount: () => {
    const count = get().requestCount - 1;
    if (count <= 0) {
      document.getElementById('loading-spinner')?.classList.remove('active');
      set(() => ({requestCount: 0}));
    } else {
      set(() => ({requestCount: count}));
    }
  },
}));

const AppLoading = () => {
  return (
    <div id="loading-spinner" className="spinner-background">
      <div className="lds-dual-ring"></div>
      <img className="spinner-img" src={logo} alt=""/>
    </div>
  )
}

export default AppLoading;
