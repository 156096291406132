import React, {useState} from 'react';
import {IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import RoadTestToggle from './RoadTestToggle';
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

const RoadTest = (props: any) => {

    const now = new Date()

    const [showModal1, setShowModal1] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState<string>();
    const [startingScore, setStartingScore] = useState('');
    const [truckBackingScore, setTruckBackingScore] = useState('');
    const [hookUpScore, setHookUpScore] = useState('');
    const [preTripScore, setPreTripScore] = useState('');
    const [rampSpeedScore, setRampSpeedScore] = useState('');
    const [upgradeStartScore, setUpgradeStartScore] = useState('');
    const [lanePosScore, setLanePosScore] = useState('');
    const [shiftScore, setShiftScore] = useState('');
    const [useHornScore, setUseHornScore] = useState('');
    const [intersecSpeedScore, setIntersecSpeedScore] = useState('');
    const [distractionScore, setDistractionScore] = useState('');
    const [laneControlScore, setLaneControlScore] = useState('');
    const [seatBeltScore, setSeatBeltScore] = useState('');
    const [trafficLightScore, setTrafficLightScore] = useState('');
    const [stopSignScore, setStopSignScore] = useState('');
    const [otherSignScore, setOtherSignScore] = useState('');
    const [coastingScore, setCoastingScore] = useState('');
    const [laneChangeScore, setLaneChangeScore] = useState('');
    const [overTakeScore, setOverTakeScore] = useState('');
    const [unsafeDrivingScore, setUnsafeDrivingScore] = useState('');
    const [followDistScore, setFollowDistScore] = useState('');
    const [mirrorUseScore, setMirrorUseScore] = useState('');
    const [rightOfWayScore, setRightOfWayScore] = useState('');
    const [speedControlScore, setSpeedControlScore] = useState('');
    const [startStopScore, setStartStopScore] = useState('');
    const [highwaySignalScore, setHighwaySignalScore] = useState('');
    const [highwaySpeedScore, setHighwaySpeedScore] = useState('');
    const [highwayAprLaneScore, setHighwayAprLaneScore] = useState('');
    const [highwayEnterLaneScore, setHighwayEnterLaneScore] = useState('');
    const [highwayIntersecObservScore, setHighwayIntersecObservScore] = useState('');
    const [backingSetupScore, setBackingSetupScore] = useState('');
    const [backingSteerScore, setBackingSteerScore] = useState('');
    const [backingHornScore, setBackingHornScore] = useState('');
    const [backing4WayScore, setBacking4WayScore] = useState('');
    const [citySignalScore, setCitySignalScore] = useState('');
    const [citySpeedScore, setCitySpeedScore] = useState('');
    const [cityAprLaneScore, setCityAprLaneScore] = useState('');
    const [cityEnterLaneScore, setCityEnterLaneScore] = useState('');
    const [cityIntersecObservScor, setcityIntersecObservScore] = useState('');
    const [grade, setGrade] = useState('');

    let autoFail = [
        {
            'answers': ["Good", "Auto Fail"],
            'name': 'Seat Belt Use',
            'value': seatBeltScore,
            'func': setSeatBeltScore
        },
        {
            'answers': ["Yes", "No"],
            'name': 'Backing Correct Setup',
            'value': backingSetupScore,
            'func': setBackingSetupScore
        },
        {
            'answers': ["Yes", "No"],
            'name': 'Backing Steers Properly',
            'value': backingSteerScore,
            'func': setBackingSteerScore
        },
        {
            'answers': ["Yes", "No"],
            'name': 'Backing Uses Goal And Horn',
            'value': backingHornScore,
            'func': setBackingHornScore
        },
        {
            'answers': ["Good", "Poor"],
            'name': 'Coasting Out Of Gear',
            'value': coastingScore,
            'func': setCoastingScore
        },
        {
            'answers': ["Good", "Auto Fail"],
            'name': 'Traffic Light',
            'value': trafficLightScore,
            'func': setTrafficLightScore
        },
        {
            'answers': ["Good", "Auto Fail"],
            'name': 'Stop Sign',
            'value': stopSignScore,
            'func': setStopSignScore
        },
    ]
    let pretrip = {
        name: "Pre Trip",
        info: "Inspection and understanding of safety proceedures prior to departure, etc. ?",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Pre Trip Inspection',
                'value': preTripScore,
                'func': setPreTripScore,
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Starting On Upgrade',
                'value': upgradeStartScore,
                'func': setUpgradeStartScore,
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Starting',
                'value': startingScore,
                'func': setStartingScore,
            },
        ]
    }
    let backing = {
        name: "Backing",
        info: "Understanding of safety procedures, securing freight, backing proficiency etc.",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Use Of Horn',
                'value': useHornScore,
                'func': setUseHornScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Backing Uses 4 Way Flashers',
                'value': backing4WayScore,
                'func': setBacking4WayScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Truck Backing',
                'value': truckBackingScore,
                'func': setTruckBackingScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Hook Up To Trailer',
                'value': hookUpScore,
                'func': setHookUpScore
            },
        ]
    }
    let safety = {
        name: "Safety",
        info: "Use of safety procedures in operation, railroad safety, sign knowledge (i.e. vertical clearance, bridge capacity, etc.)",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Distraction',
                'value': distractionScore,
                'func': setDistractionScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Other Signs',
                'value': otherSignScore,
                'func': setOtherSignScore
            },
            {
                'answers': ["Good", "Fair", "Auto Fail"],
                'name': 'Unsafe Driving Action',
                'value': unsafeDrivingScore,
                'func': setUnsafeDrivingScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Use Of Mirrors',
                'value': mirrorUseScore,
                'func': setMirrorUseScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Right Of Way',
                'value': rightOfWayScore,
                'func': setRightOfWayScore
            },
            {
                'answers': ["Good", "Fair", "Auto Fail"],
                'name': 'Control Speed',
                'value': speedControlScore,
                'func': setSpeedControlScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Start And Stopping',
                'value': startStopScore,
                'func': setStartStopScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Following Distance',
                'value': followDistScore,
                'func': setFollowDistScore
            },
        ]
    }

    let highway = {
        name: "Highway Driving",
        info: "Determine safe following distance, understand emergency procedures (e.g. Flasher while traveling under 45 MPH, etc.)",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'On Off Ramp Speed',
                'value': rampSpeedScore,
                'func': setRampSpeedScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Highway Signal',
                'value': highwaySignalScore,
                'func': setHighwaySignalScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Highway Speed',
                'value': highwaySpeedScore,
                'func': setHighwaySpeedScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Highway Approach Lane',
                'value': highwayAprLaneScore,
                'func': setHighwayAprLaneScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Highway Enter Lane',
                'value': highwayEnterLaneScore,
                'func': setHighwayEnterLaneScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Highway Intersection Observance',
                'value': highwayIntersecObservScore,
                'func': setHighwayIntersecObservScore
            },
        ]
    }
    let laneControl = {
        name: "Lane Control",
        info: "Lane control, changing & overtaking, 'No-Zone' awareness,  etc.",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Lane Position',
                'value': lanePosScore,
                'func': setLanePosScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Lane Control',
                'value': laneControlScore,
                'func': setLaneControlScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Lane Changes',
                'value': laneChangeScore,
                'func': setLaneChangeScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Over Taking',
                'value': overTakeScore,
                'func': setOverTakeScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Shifting',
                'value': shiftScore,
                'func': setShiftScore
            },
        ]
    }

    let city = {
        name: "City Driving",
        info: "Max of 18 in. clearance while Turning, turning Speed, etc.",
        eval: [
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'City Signal',
                'value': citySignalScore,
                'func': setCitySignalScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'City Speed',
                'value': citySpeedScore,
                'func': setCitySpeedScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'City Approach Lane',
                'value': cityAprLaneScore,
                'func': setCityAprLaneScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'City Enter Lane',
                'value': cityEnterLaneScore,
                'func': setCityEnterLaneScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'City Intersection Observance',
                'value': cityIntersecObservScor,
                'func': setcityIntersecObservScore
            },
            {
                'answers': ["Good", "Fair", "Poor"],
                'name': 'Intersection Speed',
                'value': intersecSpeedScore,
                'func': setIntersecSpeedScore
            },
        ]
    }

    const getCompletetion = () => {
        let progression = 0
        let questions = Object.entries(progress).length
        Object.values(progress).forEach(question => {
            if (question === "Auto Fail" && grade !== "Fail") {
                setGrade("Fail")
                let commentBox = document.querySelector("textarea")!
                commentBox.scrollIntoView()
            } else if (question !== "") {
                progression++
            }
        })
        return progression + "/" + questions
    }

    const progress = {
        starting: startingScore,
        truckBacking: truckBackingScore,
        hookUp: hookUpScore,
        preTripInspec: preTripScore,
        rampSpeed: rampSpeedScore,
        onUpgrade: upgradeStartScore,
        lanePos: lanePosScore,
        shifting: shiftScore,
        useHorn: useHornScore,
        intersectionSpeed: intersecSpeedScore,
        distraction: distractionScore,
        laneControl: laneControlScore,
        seatBelt: seatBeltScore,
        trafficLight: trafficLightScore,
        stopSign: stopSignScore,
        otherSign: otherSignScore,
        coastingOutGear: coastingScore,
        laneChange: laneChangeScore,
        overTaking: overTakeScore,
        unsafeDrivingAction: unsafeDrivingScore,
        followDistance: followDistScore,
        useMirrors: mirrorUseScore,
        rightOfWay: rightOfWayScore,
        controlSpeed: speedControlScore,
        startStop: startStopScore,
        highwaySignal: highwaySignalScore,
        highwaySpeed: highwaySpeedScore,
        highwayApproach: highwayAprLaneScore,
        highwayEnter: highwayEnterLaneScore,
        highwayInterObserv: highwayIntersecObservScore,
        backingSetup: backingSetupScore,
        backingSteering: backingSteerScore,
        backingHorn: backingHornScore,
        backing4Way: backing4WayScore,
        citySignal: citySignalScore,
        citySpeed: citySpeedScore,
        cityApproach: cityAprLaneScore,
        cityEnter: cityEnterLaneScore,
        cityInterObserv: cityIntersecObservScor,
        grade: grade,
    }

    const submitTest = () => {
        ApiService.submitRoadTest('roadTest', {
            traineeCode: props.traineeCode,
            trainerCode: props.trainerCode,
            starting: startingScore,
            truckBacking: truckBackingScore,
            hookUp: hookUpScore,
            preTripInspec: preTripScore,
            rampSpeed: rampSpeedScore,
            onUpgrade: upgradeStartScore,
            lanePos: lanePosScore,
            shifting: shiftScore,
            useHorn: useHornScore,
            intersectionSpeed: intersecSpeedScore,
            distraction: distractionScore,
            laneControl: laneControlScore,
            seatBelt: seatBeltScore,
            trafficLight: trafficLightScore,
            stopSign: stopSignScore,
            otherSign: otherSignScore,
            coastingOutGear: coastingScore,
            laneChange: laneChangeScore,
            overTaking: overTakeScore,
            unsafeDrivingAction: unsafeDrivingScore,
            followDistance: followDistScore,
            useMirrors: mirrorUseScore,
            rightOfWay: rightOfWayScore,
            controlSpeed: speedControlScore,
            startStop: startStopScore,
            highwaySignal: highwaySignalScore,
            highwaySpeed: highwaySpeedScore,
            highwayApproach: highwayAprLaneScore,
            highwayEnter: highwayEnterLaneScore,
            highwayInterObserv: highwayIntersecObservScore,
            backingSetup: backingSetupScore,
            backingSteering: backingSteerScore,
            backingHorn: backingHornScore,
            backing4Way: backing4WayScore,
            citySignal: citySignalScore,
            citySpeed: citySpeedScore,
            cityApproach: cityAprLaneScore,
            cityEnter: cityEnterLaneScore,
            cityInterObserv: cityIntersecObservScor,
            grade: grade
        }).then(reportId => {
            props.submittedTest();
            props.reportId(reportId);
            toastStore.getState().setSuccessToast('Road test has been submitted');
        }).finally(() => {
            setShowModal1(false);
        });
    }

    const handleSubmit = () => {
        if (grade !== '') {
            if (trafficLightScore === "Auto Fail" || seatBeltScore === "Auto Fail" || stopSignScore === "Auto Fail" || speedControlScore === "Auto Fail" || unsafeDrivingScore === "Auto Fail") {
                setGrade("Fail");
                submitTest();
            } else {
                submitTest();
            }
        } else {
            toastStore.getState().setToast('Please select a grade for the road test');
            setShowModal1(false);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => props.hideTest()}>Back</p></IonButton>
                    <IonTitle>Road Test</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardSubtitle>Thank You</IonCardSubtitle>
                            <IonCardTitle>Road test submitted.</IonCardTitle>
                        </IonCardHeader>
                    </IonCard>
                    <IonButton id="western-button" expand="block" onClick={function () {
                        setShowModal(false);
                        props.hideTest();
                    }}>Done</IonButton>
                </IonModal>
                <IonModal isOpen={showModal1} cssClass='my-custom-class'>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardSubtitle>Confirm?</IonCardSubtitle>
                            <IonCardTitle>Submit Test for Trainee: {props.traineeCode}?</IonCardTitle>
                        </IonCardHeader>
                        <IonButton id="western-button" expand="block" onClick={handleSubmit}>Confirm</IonButton>
                        <IonButton id="western-button" expand="block" onClick={function () {
                            setShowModal1(false)
                        }}>Cancel</IonButton>
                    </IonCard>
                </IonModal>
                <div className='item-container' style={{position: "fixed", zIndex: 10, backgroundColor: "#ffffff", boxShadow: "0 4px 4px rgba(0,0,0,0.25)", borderRadius: "10px"}}>
                    <div style={{display: "flex"}}>
                        <p className='title'>{props.traineeCode}</p>
                        <p className='subtitle'>Test Date: {now.toDateString()}</p>
                    </div>
                    <div style={{display: "flex"}}>
                        <p style={{fontSize: "small", padding: "0 8px", color: "#2fd573"}}>Completion: {getCompletetion()} </p>
                        <p style={{fontSize: "small", padding: "0 8px"}}>Grade: <span style={{color: grade === "Pass" ? "#2fd573" : "#FF0000"}}>{grade}</span></p>
                    </div>
                </div>
                <IonItem lines="none" color="light" style={{marginTop: "30%"}}>
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>General</p>
                    </div>
                </IonItem>
                {autoFail.map((element, index) => {
                    return (
                        <RoadTestToggle element={element} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{pretrip.name}</p>
                        <p className='subtitle'>{pretrip.info}</p>
                    </div>
                </IonItem>
                {pretrip.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{backing.name}</p>
                        <p className='subtitle'>{backing.info}</p>
                    </div>
                </IonItem>
                {backing.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{safety.name}</p>
                        <p className='subtitle'>{safety.info}</p>
                    </div>
                </IonItem>
                {safety.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{highway.name}</p>
                        <p className='subtitle'>{highway.info}</p>
                    </div>
                </IonItem>
                {highway.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{laneControl.name}</p>
                        <p className='subtitle'>{laneControl.info}</p>
                    </div>
                </IonItem>
                {laneControl.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{padding: 0}}>{city.name}</p>
                        <p className='subtitle'>{city.info}</p>
                    </div>
                </IonItem>
                {city.eval.map((question, index) => {
                    return (
                        <RoadTestToggle element={question} key={index}/>
                    )
                })}
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <div className="input-container">
                            <textarea id="comment-box" className="input" placeholder=" " value={comments} onChange={e => setComments(e.target.value!)}/>
                            <p className="placeholder">Comments</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <RoadTestToggle element={{name: "Overall Grade", value: grade, func: setGrade, answers: ["Pass", "Fail"]}}/>
                        <button className="western-button submit" style={{marginTop: "25px"}} onClick={() => {
                            setShowModal1(true)
                        }}>SUBMIT
                        </button>
                    </div>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default RoadTest;
