import {IonContent, IonFooter, IonHeader, IonItem, IonList, IonPage, IonToolbar} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import NotificationCard from './NotificationCard'
import Nav from './Nav';
import Toolbar from './Toolbar';
import {ApiService} from "../api/ApiService";
import {PushNotification} from "../api/model/PushNotification";
import {useHistory} from "react-router-dom";

function NotificationHub() {

    const history = useHistory();

    const [notificationArray, setNotificationArray] = useState<PushNotification[]>([])
    const [height1, setHeight1] = useState(0);

    useEffect(() => {
        ApiService.sendEventCommand('Notifications', 'eventCheck', 'User viewed their notifications');
        ApiService.getPushNotifications('notificationHub').then(it => setNotificationArray(it));
    }, []);

    const toggle = () => {
        if (height1 === 0) {
            setHeight1(1);
        } else {
            setHeight1(0)
        }
    }

    const abridge = (post: string) => {
        return post.slice(0, 75) + "..."
    }

    const sendRead = (id: number) => {
        ApiService.readPushNotification("notificationHub", {id}).then();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonItem lines="none" color="light">
                    <div className="item-container"><h3><strong>Notifications</strong></h3></div>
                </IonItem>
                <IonList lines="none" color="light" style={{padding: 0}}>

                    <IonItem color="light" lines="none">
                        <div className="item-container">
                            {notificationArray.length !== 0 ? notificationArray.length !== 1 ?
                                    notificationArray.map(element => <NotificationCard notificationElement={element} key={element.id} readStatus={sendRead}/>) :
                                    <div className={notificationArray[0].read !== 1 ? "notification-card" : "notification-card-read"} onClick={() => {
                                        toggle();
                                        sendRead(notificationArray[0].id);
                                    }}>
                                        <div className="post-title" style={{width: "100%"}}>
                                            <h6>{notificationArray[0].sent.replace('T', ' ').slice(0, -14).substr(5) + "-" + notificationArray[0].sent.substr(0, 4)}</h6>
                                        </div>
                                        <p className="post-body">{height1 !== 1 ? abridge(notificationArray[0].message) : notificationArray[0].message}</p>
                                    </div>
                                :
                                <div className="notification-card-read">
                                    <div style={{width: "100%", textAlign: "center"}}>
                                        <h5><b>No Notifications</b></h5>
                                    </div>
                                </div>
                            }
                        </div>
                    </IonItem>
                </IonList>
            </IonContent>
            <IonFooter>
                <Nav onSearch={() => history.push('/home?search=true')}/>
            </IonFooter>
        </IonPage>
    );
}

export default NotificationHub;
