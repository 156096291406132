import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonNote,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";

export interface Report {
    trainerCode: string,
    roadTest: any,
    courseId: number,
    courseDay: number,
    backingScore: number,
    turningScore: number,
    roadHabitsScore: number,
    cityScore: number,
    highwayScore: number,
    tabletScore: number,
    hosScore: number,
    defensiveScore: number,
    overallScore: number,
    dmCode: string,
}

export interface ReportReference {
    date: string,
    trainerCode: string
}

const DailyReports: React.FC = () => {

    const [noTrainees, setNoTrainees] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [hasReports, setHasReports] = useState(false);
    const [traineeCode, setTraineeCode] = useState('');
    const [report, setReport] = useState<Report>({
        trainerCode: '',
        roadTest: '',
        courseId: 0,
        courseDay: 0,
        backingScore: 0,
        turningScore: 0,
        roadHabitsScore: 0,
        cityScore: 0,
        highwayScore: 0,
        tabletScore: 0,
        hosScore: 0,
        defensiveScore: 0,
        overallScore: 0,
        dmCode: ''
    })
    const [reports, setReports] = useState<ReportReference[]>([]);
    const [trainees, setTrainees] = useState<Trainee[]>([]);

    useEffect(() => {
        ApiService.getTrainees('dailyReports', {}).then(it => {
            setNoTrainees(it.length === 0);
            setTrainees(it);
        });
    }, []);

    const getTraineeReports = (traineeCode: string) => {
        ApiService.getTrainingReports2('dailyReports', {traineeCode}).then(it => {
            parseReports(it.reports);
            setHasReports(true);
        });
    }

    const parseReports = (reportsArray: any[]) => {
        let tempArray: any[] = []
        reportsArray.forEach(element => {
            let obj: ReportReference = {date: '', trainerCode: ''};
            obj.date = element.date;
            obj.trainerCode = element.trainerCode
            tempArray.push(obj);
        });
        setReports(tempArray);

    }

    const getReportDetails = (date: string) => {
        ApiService.getTrainingReport2('dailyReports', {traineeCode, timeSubmitted: date}).then(it => {
            parseReport(it);
            setShowModal(true);
        });
    }

    const parseReport = (reportObj: any) => {
        let tempObj: any = reportObj;
        let tempReportData: any = JSON.parse(reportObj.reportData);
        Object.keys(tempReportData).forEach((element: any) => {
            tempObj[element] = tempReportData[element];
        });
        setReport(tempObj);
        setShowModal(true);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home"/>
                    </IonButtons>
                    <IonTitle>Daily Reports</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonButton id="western-button" href="/Home">⌫ Trainees</IonButton>
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <div style={{overflowY: 'scroll'}}>
                        <IonCard>
                            <IonList>
                                <IonItem>
                                    <IonLabel>Trainee</IonLabel>
                                    <IonNote slot="end" color="black">{traineeCode}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Trainer Code</IonLabel>
                                    <IonNote slot="end" color="black">{report.trainerCode}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Dm Code</IonLabel>
                                    <IonNote slot="end" color="black">{report.dmCode}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Road Test</IonLabel>
                                    <IonNote slot="end" color="black">{report.roadTest !== 0 ? 'Yes' : 'No'}</IonNote>
                                </IonItem>

                                { /* REPORT DATA */}
                                <IonItem>
                                    <IonLabel>Backing Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.backingScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Turning Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.turningScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Road Behavior</IonLabel>
                                    <IonNote slot="end" color="black">{report.roadHabitsScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>City Driving Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.cityScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Highway Driving Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.highwayScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Tablet Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.tabletScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>HOS Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.hosScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Defensive Driving Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.defensiveScore}</IonNote>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Overall Report Score</IonLabel>
                                    <IonNote slot="end" color="black">{report.overallScore}/40</IonNote>
                                </IonItem>

                            </IonList>
                        </IonCard>
                    </div>
                    <IonButton className='mb-15' id="western-button" expand="block" onClick={function () {
                        setShowModal(false)
                    }}>Done</IonButton>
                </IonModal>
                {hasReports ? <>
                        <IonList className='mb-20'>
                            {reports.map(function (dayreport) {
                                return <div style={{width: "100%"}}>
                                    <IonItem onClick={function () {
                                        getReportDetails(dayreport.date)
                                    }}>
                                        <IonLabel>Course Day</IonLabel>
                                        <IonNote slot="end" color="black">{dayreport.date}</IonNote>
                                    </IonItem>
                                </div>
                            })}
                        </IonList>
                    </> :
                    <> {!noTrainees ?
                        <IonList className="mb-20">
                            {trainees.map(function (trainee) {
                                const profile = trainee.traineeCode;
                                return <IonItem key={profile} onClick={() => {
                                    setTraineeCode(trainee.traineeCode);
                                    getTraineeReports(trainee.traineeCode)
                                }}>
                                    <IonLabel>
                                        <h2>Trainee: {trainee.traineeCode}</h2>
                                        <h3>Course Id: {trainee.currentCourseId}</h3>
                                        <h3>Course Day: {trainee.currentCourseDay}</h3>
                                    </IonLabel>
                                </IonItem>
                            })}

                        </IonList>
                        :
                        <IonCard>
                            <IonCardHeader>
                                <IonCardSubtitle>Attention!</IonCardSubtitle>
                                <IonCardTitle>You Have No Trainees</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <p style={{fontSize: "17px", marginBottom: '20px'}}>Please go to <span style={{color: "#A9EF01"}}>New Trainee</span> to claim a trainee for instruction.</p>
                                <IonButton id="western-button" expand="block" href="/newtrainee">
                                    <div id="btn-text">New Trainee</div>
                                </IonButton>
                            </IonCardContent>
                        </IonCard>} </>
                }
            </IonContent>
        </IonPage>
    );
};

export default DailyReports;
