import React, {useState} from "react";
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from "@ionic/react";

const Agreements: React.FC = () => {

    const [showTrainingCode, setShowTrainingCode] = useState(true)
    const [showRelation, setShowRelation] = useState(true)
    const [agreeConduct, setAgreeConduct] = useState(false)
    const [agreeRelation, setAgreeRelation] = useState(false)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Agreements</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <p>These documents include useful information about Western Express policy</p>
                        <p className="subtitle">Please read each document in its entirety, and confirm your understanding of the following items (below): </p>
                        <p style={{fontSize: "small", color: "#5096FF"}} onClick={() => setShowTrainingCode(true)}>Code of Conduct</p>
                        <p style={{fontSize: "small", color: "#5096FF"}} onClick={() => setShowRelation(true)}>Trainer / Trainee Relationship Agreement</p>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <p>Trainee Signature: {agreeConduct && agreeRelation ? <span style={{color: "#2fd573"}}>You have agreed.</span> :
                            <span style={{color: "#ff0000"}}>Please read the above items.</span>}</p>
                    </div>
                </IonItem>
                <IonModal isOpen={showTrainingCode}>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons>
                                <IonBackButton></IonBackButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonCard color="gains" className="container" style={{overflowY: "auto"}}>
                        <IonItem lines="none">
                            <div className="item-container">
                                <p>Trainer / Trainee Code of Conduct</p>
                            </div>
                        </IonItem>

                        <IonCardContent>
                            <IonItem lines="none">
                                <div className="item-container">
                                    <p className="subtitle">As a trainer or trainee for Western Express, I understand that I am held to a higher standard
                                        than a regular, over the road driver. I am considered a supervisor / trainee while he / she are in
                                        the care of the trainer / trainee. My actions and conduct must reflect the highest level of
                                        professionalism.</p>

                                    <p className="title">As a trainer / trainee for Western Express, I agree to the following:</p>
                                    <ol>
                                        <li> I will treat my trainee / trainer with respect and dignity at all times.
                                        </li>

                                        <li> I will not subject my trainee / trainer to any form of harassment, including verbal or
                                            physical
                                        </li>

                                        <li> I will not place my trainer / trainee in any situation where he / she may feel threatened,
                                            intimidated, or uncomfortable.
                                        </li>

                                        <li> I will not view any material, printed, video, or otherwise, that could be deemed as
                                            questionable in nature while the trainer / trainee are together on the Western Express
                                            tractor.
                                        </li>

                                        <li> I will not make any statement, joke, etc. that could be deemed as inappropriate to my
                                            trainer / trainee, or in the presence of my trainer / trainee.
                                        </li>

                                        <li> I understand that any physical contact with my trainer / trainee is expressly forbidden.</li>

                                        <li> I will keep a neat and clean professional appearance of my person and my truck. I will
                                            shower regularly and maintain appropriate, professional personal hygiene.
                                        </li>
                                    </ol>
                                </div>
                            </IonItem>
                        </IonCardContent>
                        <IonItem lines="none">
                            <div>
                                <button className="western-button submit" onClick={() => {
                                    setShowTrainingCode(false);
                                    setAgreeConduct(true)
                                }}>I Understand
                                </button>
                            </div>
                        </IonItem>
                    </IonCard>
                </IonModal>
                <IonModal isOpen={showRelation}>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons>
                                <IonBackButton></IonBackButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonCard color="gains" className="container" style={{overflowY: "auto"}}>
                        <IonItem lines="none">
                            <div className="item-container">
                                <p>Trainer / Trainee Relation Agreement</p>
                            </div>
                        </IonItem>
                        <IonCardContent>
                            <IonItem lines="none">
                                <div className="item-container">
                                    <p className="title">Trainees have the responsibility to follow the instructions of the Trainer and learn the
                                        correct way to operate company equipment.</p>

                                    <ol>
                                        <li> DRIVER TRAINERS ARE IN CHARGE OF THE TRUCK.</li>

                                        <li> If there are any questions on this matter, refer to rule #1.</li>

                                        <li> Driver Trainees must drive following the instructions of the Driver Trainer.
                                        </li>

                                        <li> Driver Trainees must thoroughly learn all aspects of paperwork and QUALCOMM
                                            procedures. You will be required to show a log book with a log for each day you are
                                            out with a trainer
                                        </li>

                                        <li> Trainees must complete all phases of the training program as outlined by the
                                            Training Department
                                        </li>

                                        <li> During the training period, each Trainee will be evaluated by trainer and the
                                            Training Department of Western Express. The trainer will fill out a report each day
                                            and will be reviewed by the Trainer and Western Express Training.
                                        </li>

                                        <li> ALCOHOL POLICY it is a violation of Western Express Training Department policy
                                            for the trainer or trainee to use or possess any alcoholic beverages during the
                                            duration of training. You can be assigned a Trainer and Dispatch with little or no
                                            notice and must be able to go without being in violation of D.O.T. regulations and /
                                            or company policy. Failure to comply will result in termination from Western
                                            Express.
                                        </li>

                                        <li> DRUG POLICY failure to comply with D.O.T. and company requirements will result
                                            in termination from Western Express.
                                        </li>

                                        <li> The trainee should drive all available hours each day. The more time behind the
                                            wheel, the more experience and easier it will be when the trainee goes solo.
                                        </li>

                                        <li> TRUCK RECOVERIES At times, you may be asked by operations to split and help
                                            recover a truck. Only Vice Presidents’, Terminal Manager, or the
                                            Training Manager may authorize this. Failure to abide by this will result in your
                                            removal from the training program
                                        </li>

                                        <li> Backing. Trainee will do all backing under supervision of the Trainer. Get out of
                                            the truck and check area to the rear and side of the unit before attempting
                                            maneuvers. GOAL. Get out and Look.
                                        </li>

                                        <li> Remember …. You are training your trainee to become a Profession Solo Driver.
                                            When running as a team on team freight, explain to your trainee if he were running
                                            solo, how far he could legally run, where he would need to plan to take his break for
                                            the day etc. Some work will have to be shared during the dispatch cycle, but the
                                            Driver trainer is there to ensure the Trainee will be able to perform as a
                                            Professional Solo Driver. This means the trainee will perform the majority of all
                                            operations, including communications with customers, operations, and paperwork.
                                        </li>

                                        <li> Routing. Use the route sent to your Qualcomm. If for some reason a route is not
                                            available use the shortest, legal route. Trainee should plan each trip and discuss the
                                            same with the Trainer.
                                        </li>

                                        <li> Trainee shall not use a cell phone or other electronic devices while on duty. On duty
                                            time should be used to focus on learning! These devices are a distraction that
                                            hinders training.
                                        </li>

                                        <li> Each person has an important role in successfully completing the training program.
                                            If tensions arise to the point of stopping training, it is essential that the trainer or
                                            trainee contact the Training Manager.
                                        </li>

                                        <li> No trainee will be required to sleep in a top bunk without factory equipped netting
                                            systems while the truck is in motion. This is due to state laws requiring a
                                            securement device for all passengers.
                                        </li>

                                        <li><strong> While on duty and especially while driving the truck, a trainee cannot use cell
                                            phone, iPods, cd’s, or any other electronic devices!!! </strong>
                                        </li>

                                        <li> If a trainee chooses to get off the trainer’s truck and/or go home for any unrelated
                                            work issues, Western Express will NOT pay for a bus ticket. A ticket may be
                                            purchased at trainee’s request, but will come out of the trainee’s next paycheck.
                                        </li>
                                    </ol>

                                </div>
                            </IonItem>
                        </IonCardContent>
                        <IonItem lines="none">
                            <div>
                                <button className="western-button submit" onClick={() => {
                                    setShowRelation(false);
                                    setAgreeRelation(true);
                                }}>I Understand
                                </button>
                            </div>
                        </IonItem>
                    </IonCard>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default Agreements;
