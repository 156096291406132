import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonModal, IonButtons, IonBackButton, IonItem, IonToast, IonIcon } from '@ionic/react';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import {config} from '../Constants';
import { cameraOutline, trashOutline } from 'ionicons/icons';
//Google Analytics:
import { Camera } from '@capacitor/camera';

const timages: any[] = [];

interface Photo {
  filepath: string;
  webviewPath?: string;
  data: any;
}

var c = document.getElementById("canv");
// var $ = c!.getContext("2d");

const PhotoUpload: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {

  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successPhoto, setSuccess] = useState(false);
  const [toast, setToast] = useState<string>("")
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [images, setImages] = useState<Photo[]>([]);
  const [userLat, setUserLat] = useState<number>(36.1627);
  const [userLong, setUserLong] = useState<number>(-86.7816);
  const [loadNumber, setLoadNumber] = useState<string>("");
  const [unitNumber, setUnitNumber] = useState("");
  const [dmEmail, setDmEmail] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [trailerNumber, setTrailerNumber] = useState("");
  const [dmCode, setDmCode] = useState("");
  const [division, setDivision] = useState("");

  useEffect(() => {
    // get unit number from userProfile
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "driverProfile", args: {driverCode: localStorage.getItem('driverCode')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    //console.log(requestOptions)
    fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} 
        else if (json.results[0].errmsg) {
          
          console.error("Error: ", json.results[0].errmsg);
        }
          else {
            setDmCode(json.results[0].dmCode)
            setDmEmail(json.results[0].dmEmail)
            setTrailerNumber(json.results[0].trailer)
            setUnitNumber(json.results[0].unit)
            getLoadNumber(json.results[0].unit)
            setDivision(json.results[0].division)
          }})
          // get load number from loadCurrent
        }, [])

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  } 
  
  function getLoadNumber(unitNumber: string) {
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: "loadDetails", args: {unit: unitNumber, username: localStorage.getItem('driverCode')?.toUpperCase(), loginToken: localStorage.getItem('token')}}] }))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(json => {if (json.results[0].errcode === 'NOTLOGGEDIN') {localStorage.removeItem('token')} else if (json.results[0].errcode === "SYSERR") {console.log("SYSERR: loadDetails - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber)} })
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())

  function sendDmMessage() {
    const toList = "kshinkle@westernexp.com";
    const emailBody = 'Driver ' + dmCode + " has submitted a tarp photo. To view this photo, go to: http://software.westernexp.com/admin-console/tarppay and enter the driver code." ;
    var data = new FormData()
    data.append("payload", JSON.stringify({ cmds: [{cmd: 'genericEmail', args: {to: toList, subject: 'Tarp Photo Submitted', body: emailBody, loginToken: localStorage.getItem('token')}}]}))
    const requestOptions = {
      method: 'POST',
      headers: {},
      body: data
    };
    fetch(config.url.BASE_URL, requestOptions)
    .then(response => response.json())
    .then(json => {if(json.results[0].errmsg){setToast((json.results[0].errcode + ": " + json.results[0].errmsg)); console.log("SYSERR: genericEmail - PhotoUpload"); setShowToast(true)}else{redirectPage();}})
  }
  
  async function submitReport() {
    setSubmitDisabled(true);
    if (images.length >= 3) {
      await getUserLongLat();
      setLoading(true);
      let ln = loadNumber;
      if (!ln) {console.log('returned'); setToast("No active load found"); setShowToast(true); setShowLoadingModal(false); return;}
      // Submit record
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "genericReportSubmission", args: {driverCode: localStorage.getItem('driverCode')?.toUpperCase(), loginToken: localStorage.getItem('token'), load: ln, trailer: trailerNumber, unit: unitNumber, comment: 'Empty'}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(async json => {if (json.results[0].errcode === "SYSERR") {console.log("SYSERR: genericReportSubmission - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber)}{let refId = json.results[0].genericReportId; submitPhotos(refId, ln);}})
    } else {
        setToast("Please ensure you have the required number of photos for a submission (3)")
        setShowToast(true);
      }
    }

    async function submitPhotos(refId: any, ln: any) {
      // Submit pictures --
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoSubmission", args: {driverCode: localStorage.getItem('driverCode')?.toUpperCase(), loginToken: localStorage.getItem('token'), refId: refId, load: ln, division: division, trailer: trailerNumber, unit: unitNumber, type: "tarp", dmCode: dmCode}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then( async () => {uploadPhotos(refId)})

      // let failedCount = 0;
      // let uploadCount = 0;
      // for (let i = 0; i < images.length; i++) {
      //   var data = new FormData();
      //   data.append("uploadType", 'tarp');
      //   data.append("refId", refId);
      //   data.append("file", new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      //   const requestOptions = {
      //     method: 'POST',
      //     body: data
      //   };
      //   const res = fetch(config.url.BASE_URL_PHOTOS, requestOptions)
      //   .then(response => {
      //     if (response.status.toString().indexOf("50") !== -1) {
      //       sendErrorMsg("Error with Photo server communication: " + response.status)
      //       setSubmitDisabled(false);
      //     } 
      //     if (response.status !== 200 && failedCount <= 3) {
      //       return -1;
      //     } else if (failedCount > 3) {
      //       console.log("returned 0");
      //       return 0;
      //       uploadCount += 1;
      //     } else {
      //       uploadCount += 1;
      //       return 1;
      //     }
      // })
      //   .then(result => { console.log("results", result, uploadCount); if(result === -1) {return -1} else if (result === 0) {setShowErrorModal(true); setLoading(false);} else if (uploadCount == images.length) {setLoading(false); setSuccess(true); sendDmMessage();}});
      //   if (await res === -1) {
      //     failedCount += 1;
      //     sendErrorMsg("error file upload for photo");
      //   }
      // }
    }

    async function uploadPhotos(refId: any) {
      var data = new FormData();
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoUpload", args: {loginToken: localStorage.getItem('token'), refId: refId, type: "tarp", dmCode: dmCode}}] }))
      for (let i = 0; i < images.length; i++) {
        data.append(`photos${i}`, new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      }
      const requestOptions = {
        method: 'POST',
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {if(json.results[0].errmsg){setShowErrorModal(true)}else{setLoading(false); setSuccess(true); sendDmMessage(); console.log("photos successful", json.results[0]);}})
    }

    const redirectPage = () => {
      setTimeout(function() {
        window.location.replace('./Home');
      }, 3000)
    }
    
    async function takePicture() {
      const image = await Camera.getPhoto({
        quality: 30,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      const fileName = new Date().getTime() + '.jpeg';
      // await addDateTime(image.webPath!)
      const base64Data = await base64FromPath(image.webPath!);
      const newPhotos = [{
        filepath: fileName,
        webviewPath: image.webPath, 
        data: base64Data
      }, ...images];
      setImages(newPhotos)
      timages.push(image)
      if (timages.length > 0) {
        setPhotoMsg("Take another photo")
      }
      getUserLongLat();
    }

    const removePhoto = (e: any) => {
      const name = e.filepath;
      setImages(images.filter(image => image.filepath !== name));
    }

    async function getUserLongLat() {
      navigator.geolocation.getCurrentPosition(success, error)
    }

    async function success(pos: {coords: any; }) {
      setUserLat(await pos.coords.latitude);
      setUserLong(await pos.coords.longitude);
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    
  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Tarp Pay</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        color="danger"
        duration={5000}
      />
       <IonModal isOpen={loading} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                          <p>Please wait... your photos are being submitted.</p>
                          <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
      <IonToast
        isOpen={successPhoto}
        onDidDismiss={() => {setLoading(false); setSuccess(false)}}
        message="Your photos were submitted."
        color="success"
        duration={3000}
      />
       <IonToast
        isOpen={showErrorModal}
        message="Error! One or more photos could not be uploaded, check your connection and please try again."
        color="danger"
        duration={5000}
      />
      <IonContent color='white'>
        <div className='main'>
          <div>
          <IonItem color="white" lines="none">
            <div className='item-container'>
              <h1>Submit Tarp Photos</h1>
              <p style={{fontSize: "medium"}}><b>Please submit the following photos of the load:</b></p><br/>
              <p><b>Rear</b> view of the load with trailer number</p><br/>
              <p><b>Side</b> view of the tarped load</p><br/>
              <p><b>Front</b> view of the tarped load</p><br/>
              <button className='button' style={{margin: "auto"}} onClick={e => {takePicture();}}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
            </div>
          </IonItem>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', padding: "8px", backgroundColor: "#ffffff", margin: "auto"}} >
              {images.map(function(img, index) {
                return <div key={index} onClick={e => {removePhoto(img)}} style={{position: "relative"}}>
                  <img alt="tarppay img"  style={{width: "100px", borderRadius: "10px"}}src={img.webviewPath} />
                  <button style={{ backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
                </div>
              })}
            </div>
          </div>
        </div>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <button className="western-button submit" disabled={submitDisabled}  onClick={e => {submitReport();}}>SUBMIT FOR TARP PAY</button>
            </div>
          </IonItem>
      </IonContent>
 
    </IonPage>
  );
};

export default PhotoUpload;
