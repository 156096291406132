import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {deliverError} from '../Util';
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {LoadHistoryInfo} from "../api/model/LoadHistory";
import {Link} from "react-router-dom";

const LoadHistory: React.FC = () => {

    const [lastNum, setLastNum] = useState(0);
    const [loads, setLoads] = useState<LoadHistoryInfo[]>([]);

    useEffect(() => {
        loadMore();
    }, []);

    const loadMore = () => {
        ApiService.getLoadHistory('loadHistory', {start: lastNum, length: 3}, true).then(it => {
            setLoads(loads.concat(it).reverse());
            setLastNum(loads.length);
        }).catch(it => {
            if (it?.results[0]?.errcode) {
                toastStore.getState().setToast(deliverError(it.results[0].errcode));
            }
        })
    }

    const displayDate = (date: string) => {
        if (date) {
            let parts = date.split('-')
            return parts[1] + "/" + parts[2] + "/" + parts[0]
        } else {
            return "-"
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Load History</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <div className="container">
                    {loads.map((load, index) => {
                        let isFiled = true
                        if (load.emptyDate && !load.paperworkDate) {
                            isFiled = false
                        }
                        return (
                            <div>{isFiled ?
                                <Link to={"/loadhistory/" + load.orderNumber} key={index} className="load-history">
                                    <IonItem color="light" lines="none">
                                        <div className='item-container'>
                                            <h4 className='title boldest' style={{color: "#99cc00"}}>{load.orderNumber}</h4>
                                            <p>Complete: {displayDate(load.emptyDate)}</p>
                                            <p>Filed: {displayDate(load.paperworkDate)}{load.paidDate ? <span style={{color: "#a7a7a7"}}>(Paid: {displayDate(load.paidDate)})</span> : ""}</p>
                                            <p className='subtitle'>See Detail</p>
                                        </div>
                                    </IonItem>
                                </Link> : <div className='load-history' key={index}>
                                    <IonItem color="light" lines="none">
                                        <div className='item-container'>
                                            <h4 className='title boldest' style={{color: "#99cc00"}}>{load.orderNumber}</h4>
                                            <p>Complete: {displayDate(load.emptyDate)}</p>
                                            <Link to="/Home" style={{color: "#D0A024", textDecoration: "none"}}>Don't see a filed date yet?</Link>
                                            <br/>
                                            <br/>
                                            <Link to={"/loadhistory/" + load.orderNumber} style={{textDecoration: "none"}}><p className='subtitle'>See Detail</p></Link>
                                        </div>
                                    </IonItem>
                                </div>
                            }</div>
                        )
                    })}
                    <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <button onClick={() => {
                                loadMore()
                            }} className="western-button submit">LOAD MORE
                            </button>
                        </div>
                    </IonItem>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoadHistory;
