import {testSymbol, BasePredicate} from './predicates/base-predicate';

/**
 * Validate the value against the provided predicate.
 *
 * @hidden
 * @param value Value to test.
 * @param label Label which should be used in error messages.
 * @param predicate Predicate to test to value against.
 */
export default function test<T>(value: T, label: string | Function, predicate: BasePredicate<T>) {
	predicate[testSymbol](value, test, label);
}
