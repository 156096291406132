import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {SettlementChecks} from "../api/model/SettlementChecks";
import {Link} from "react-router-dom";


const Settlements: React.FC = () => {

    const [checks, setChecks] = useState<SettlementChecks[]>([]);
    const [lastNum, setLastNum] = useState<number>(0);
    const [showNoPayroll, setShowNoPayroll] = useState(false)

    useEffect(() => {
        ApiService.lpSettlementsGet('settlements', {}).then(it => {
            setChecks(it);
            setLastNum(checks.length);
        }).finally(() => {
            if (!checks?.length) {
                setShowNoPayroll(true);
            }
        });
    }, []);

    const loadMore = () => {
        ApiService.lpSettlementsGet('settlements', {start: lastNum, length: 10}).then(it => {
            setChecks(ch => ch.concat(it));
            setLastNum(checks.length);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Settlements</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {checks.map(check => <IonCard style={{backgroundColor: "#414141", color: "white"}}>
                    <Link to={"/settlements/" + check.checknum}><IonButton size="small" style={{
                        margin: "14px 10px 0 10px",
                        float: "right",
                        color: "#fff",
                        background: "#0e375a",
                        "--background": "#0e375a"
                    }}>Details</IonButton></Link>
                    <IonCardHeader style={{width: "70%", 'background-color': "transparent"}}>
                        <IonCardTitle style={{fontSize: "18px"}} color="secondary">Settlement:# <b>{check.checknum}</b> </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <h2>Date: <b>{check.date.substr(5) + '-' + check.date.substr(0, 4)}</b></h2>
                    </IonCardContent>
                </IonCard>)}
                {showNoPayroll ? <IonItem><IonLabel><h2>No Settlement History</h2></IonLabel></IonItem> : <IonButton onClick={() => {
                    loadMore()
                }} color="secondary" expand="block" className="home" fill="solid">Load More</IonButton>}
            </IonContent>
        </IonPage>
    );
};

export default Settlements;
