import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {IonRouterOutlet} from '@ionic/react'
import {IonReactRouter} from '@ionic/react-router'

import Work from './Work'
import Inbox from './Inbox'
import Help from './Help'
import AccidentInfo from './AccidentInfo'
import Breakdown from './Breakdown'
import ChainOfCommand from './ChainOfCommand'
import CurrentLoad from './CurrentLoad'
import HelpDesk from './HelpDesk'
import KeyContacts from './KeyContacts'
import LoadHistory from './LoadHistory'
import MaintenanceVids from './MaintenanceVids'
import Payroll from './Payroll'
import PersonalAdvance from './PersonalAdvance'
import PrePlanned from './PrePlanned'
import PreTrip from './PreTrip'
import TrailerTracking from './TrailerTracking'
import SafetyVids from './SafetyVids'
import ServiceLocations from './ServiceLocations'
import ServiceLocationsCA from './ServiceLocationsCA'
import ServiceLocationsIA from './ServiceLocationsIA'
import ServiceLocationsPA from './ServiceLocationsPA'
import ServiceLocationsTN from './ServiceLocationsTN'
import ServiceLocationsVA from './ServiceLocationsVA'
import ServiceLocationsTX from './ServiceLocationsTX'
import PayrollPayChecks from './PayrollPayChecks'
import PayrollFAQ from './PayrollFAQ'
import PayrollContacts from './PayrollContacts'
import LoadDetail from './LoadDetail'
import Safety from './Safety'
import SafetyDocs from './SafetyDocs'
import SafetyContacts from './SafetyContacts'
import PaycheckDetail from './PaycheckDetail'
import TrainingDay from './TrainingDay'
import TrainingContacts from './TrainingContacts'
import TraineeProfile from './TraineeProfile'
import ReleaseTrainee from './ReleaseTrainee'
import DailyReports from './DailyReports'
import BackingEvents from './BackingEvents'
// import RoadTestHistory from './RoadTestHistory';
import RoadTest from './RoadTest'
//import Quizzes from './Quizzes';
import Agreements from './Agreements'
import NewTrainee from './NewTrainee'
import Referral from './Referral'
import PhotoUpload from './PhotoUpload'
import Benefits from './Benefits'
import OrientationDrivers from './OrientationDrivers'
import OrientationRetest from './OrientationRetest'
import PassengerForm from './PassengerForm'
import LeasePurchase from './LeasePurchase'
import Rewards from './Rewards'
import EditProfile from './EditProfile'
import TrainerFeedback from './TrainerFeedback'
import Home from './Home'
import Contacts from './Contacts'
import Settlements from './Settlements';
import Deferment from './Deferment'
import NewDriverPacket from './NewDriverPacket'
import Load from './Load'
import Support from './Support'
import News from './News'
import Settings from './Settings'
import DivisionInfo from './DivisionInfo'
import NotificationHub from './NotificationHub'
import RegisterPlate from './RegisterPlate'
import Contracts from './Contracts'
import Drive from './Drive'
import LoadSelection from './LoadSelection'

export default class Main extends React.Component {
	render() {
		return (
			<IonReactRouter>
				{/* <SideMenu /> */}
				{/* <Header /> */}
				{/* <IonTabs> */}
				<div>
					<IonRouterOutlet>
						<Switch>
							<Route path='/Home' component={Home} exact={true} />
							<Route path='/notifications' component={NotificationHub} exact={true} />
							<Route path='/division' component={DivisionInfo} exact={true} />
							<Route path='/settings' component={Settings} exact={true} />
							<Route path='/support' component={Support} exact={true} />
							<Route path='/rewards' component={Rewards} exact={true} />
							<Route path='/editprofile' component={EditProfile} exact={true} />

							<Route path='/pretrip' component={PreTrip} exact={true} />
							<Route path='/currentload' component={CurrentLoad} exact={true} />
							<Route path='/loadhistory' component={LoadHistory} exact={true} />
							<Route path='/loadhistory/:loadnum' component={LoadDetail} exact={true} />
							<Route path='/preplanned' component={PrePlanned} exact={true} />
							{/* Simon Code */}
							<Route path='/LoadSelection' component={LoadSelection} exact={true} />
							<Route path='/trailertracking' component={TrailerTracking} exact={true} />
							<Route path='/breakdown' component={Breakdown} exact={true} />
							<Route path='/pretrip' component={PreTrip} exact={true} />
							<Route path='/photoupload' component={PhotoUpload} exact={true} />
							<Route path='/personaladvance' component={PersonalAdvance} exact={true} />
							<Route path='/payroll' component={Payroll} exact={true} />
							<Route path='/paychecks' component={PayrollPayChecks} exact={true} />
							<Route path='/settlements/:checkno' component={PaycheckDetail} exact={true} />
							<Route path='/payrollfaq' component={PayrollFAQ} exact={true} />
							<Route path='/payrollcontacts' component={PayrollContacts} exact={true} />
							<Route path='/orientationroadtests' component={OrientationDrivers} exact={true} />
							<Route path='/retest' component={OrientationRetest} exact={true} />
							<Route path='/Work' component={Work} exact={true} />
							<Route path='/leasepurchase' component={LeasePurchase} exact={true} />
							<Route path='/referral' component={Referral} exact={true} />
							<Route path='/inbox' component={Inbox} />
							<Route path='/Help' component={Help} />
							<Route path='/news' component={News} />
							<Route path='/settlements' component={Settlements} />
							{/* <Route path="/guide" component={Guide}/> */}
							<Route path='/safety' component={Safety} />
							<Route path='/benefits' component={Benefits} exact={true} />
							<Route path='/safety/videos' component={SafetyVids} />
							<Route path='/safety/documents' component={SafetyDocs} />
							<Route path='/safetycontacts' component={SafetyContacts} />
							<Route path='/maintenancevids' component={MaintenanceVids} />
							<Route path='/servicelocations' component={ServiceLocations} />
							<Route path='/servicelocations/servicelocationsca' component={ServiceLocationsCA} />
							<Route path='/servicelocations/servicelocationsia' component={ServiceLocationsIA} />
							<Route path='/servicelocations/servicelocationspa' component={ServiceLocationsPA} />
							<Route path='/servicelocations/servicelocationstn' component={ServiceLocationsTN} />
							<Route path='/servicelocations/servicelocationsva' component={ServiceLocationsVA} />
							<Route path='/servicelocations/servicelocationstx' component={ServiceLocationsTX} />
							<Route path='/accidentinfo' component={AccidentInfo} />
							<Route path='/helpdesk' component={HelpDesk} />
							<Route path='/chainofcommand' component={ChainOfCommand} />
							<Route path='/deferment' component={Deferment} />
							<Route path='/newdriverpacket' component={NewDriverPacket} />
							<Route path='/passenger' component={PassengerForm} />
							<Route path='/keycontacts' component={KeyContacts} />
							<Route path='/contacts' component={Contacts} />
							<Route path='/load' component={Load} />
							<Route path='/registervehicle' component={RegisterPlate} />
							{/* <Route path="/HowToDrive" component={HowToDrive} /> */}
							<Route path='/contracts' component={Contracts} />
							<Route path='/trainingday' component={TrainingDay} />
							<Route path='/traineeprofile' component={TraineeProfile} />
							<Route path='/trainerfeedback' component={TrainerFeedback} />
							<Route path='/releasetrainee' component={ReleaseTrainee} />
							<Route path='/dailyreports' component={DailyReports} />
							<Route path='/backingevents' component={BackingEvents} />
							{/* <Route path="/roadtesthistory" component={RoadTestHistory} /> */}
							<Route path='/trainingroadtest' component={RoadTest} />
							<Route path='/trainingcontacts' component={TrainingContacts} />
							{/* <Route path="/quizzes" component={Quizzes} /> */}
							{/* <Route path="/courseoverview" component={CourseOverview} /> */}
							<Route path='/agreements' component={Agreements} />
							<Route path='/newtrainee' component={NewTrainee} />
							<Route path='/Drive' component={Drive} />
              <Route path='/' render={() => <Redirect to='/Home' />} exact={true} />

              {/* Simon Code: added defualt route */}
							<Route path='*'>
								<Redirect to='/Home' />
							</Route>
						</Switch>
					</IonRouterOutlet>
				</div>
				{/* <NavBar /> */}

				{/* <IonTabBar slot="bottom">
            <IonTabButton tab="Work" href="/Work">
              <IonIcon icon={speedometer} />
              <IonLabel>Work</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Money" href="/Money">
              <IonIcon icon={cash} />
              <IonLabel>Money</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Inbox" href="/Inbox">
              <IonIcon icon={mail} />
              <IonLabel>Inbox</IonLabel>
            </IonTabButton>
            {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ?
            <IonTabButton tab="Training" href="/Training">
              <IonIcon icon={school} />
              <IonLabel>Trainer</IonLabel>
            </IonTabButton> : null}
            {localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "admin" ?
            <IonTabButton tab="Orientation" href="/Orientation">
              <IonIcon icon={ribbon} />
              <IonLabel>Orientation</IonLabel>
            </IonTabButton> : null}
            <IonTabButton tab="Help" href="/Help">
              <IonIcon icon={help} />
              <IonLabel>Help</IonLabel>
            </IonTabButton>
          </IonTabBar> */}
				{/* </IonTabs> */}
			</IonReactRouter>
		)
	}
}
