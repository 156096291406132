import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';
import {ApiService} from "../api/ApiService";
import {ContactsInfo} from "../api/model/Contacts";

const ChainOfCommand: React.FC = () => {

    const [contacts, setContacts] = useState<any>({});

    useEffect(() => {
        ApiService.getContacts('chainContact', {
          category: 'chainOfContact',
          isLp: 1
        }).then(it => parseContacts(it));
    }, []);

    const parseContacts = (contacts: ContactsInfo[]) => {
        let tempArray: any[] = contacts;
        let tempObj: any = {}
        tempArray.forEach(element => {
            if (typeof tempObj[element.subcategory] !== 'undefined') {
                tempObj[element.subcategory].push(element)
            } else {
                tempObj[element.subcategory] = [element]
            }
        });
        setContacts(tempObj);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Company Directory</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{marginBottom: "9vh"}}>
                    {Object.keys(contacts).map(key => <div>{contacts[key].map((val: any, index: number) => <IonItem lines="none" key={index}><ContactCard contact={val}/></IonItem>)}</div>)}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ChainOfCommand;
