import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonIcon, IonButtons, IonBackButton } from '@ionic/react';

import { call } from 'ionicons/icons';

const ServiceLocationsCA: React.FC = () => {
  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Help/servicelocations" />
          </IonButtons>
          <IonTitle>Service Location: CA</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <iframe title="CA Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-C99R1wtlYAZdY8SxrbY_te_Exs7Tfus&q=18244+Valley+Blvd,Bloomington+CA">
          </iframe>
          <IonCard>
            <IonCardContent>
              <h1>18244 Valley Blvd</h1>
              <h2>Bloomington, CA 92316</h2>
              <IonButton id="western-button" href="tel:866-726-8509" expand="block" >
                <IonIcon slot="start" icon={call}></IonIcon>
                 866-726-8509
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Directions</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              TAKE I 10 EAST TO CHERRY ST EXIT, GO SOUTH TO SLOV ER, GO LEFT ON SLOVER TO ELM AVE, NEW OFFICE IS ON THE CORNER OF ELM/SLOVER. AS SOON AS YOU PASS ELM OUR DRIVEWAY IS ON THE RIGHT TO TURN INTO THE YARD
            </IonCardContent>
            </IonCard>
      
      </IonContent>
    </IonPage>
  );
};

export default ServiceLocationsCA;
