import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonThumbnail, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {ApiService} from "../api/ApiService";
import {VideoInfo} from "../api/model/VideosList";

const SafetyVids: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [vidLink, setVidLink] = useState("");
    const [vidIds, setVidIds] = useState<VideoInfo[]>();

    useEffect(() => {
        ApiService.getVideosList('safetyVids', {type: 'safety'}).then(it => setVidIds(it));
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={localStorage.getItem('token') ? "/Help/safety" : "/"}/>
                    </IonButtons>
                    <IonTitle>Safety Videos</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton onClick={function () {
                                    setShowModal(false);
                                    setVidLink("")
                                }}>
                                    <IonIcon slot="icon-only" icon={close}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <iframe id="video-modal" title="safety video" width="100%" height="345" src={vidLink}>
                        </iframe>
                    </IonContent>
                </IonModal>
                <IonList style={{marginBottom: "9vh"}}>
                    {vidIds?.filter(it => it.active === 1).map((vidIds, idx) => {
                            let src = "https://img.youtube.com/vi/" + vidIds.youtube + "/0.jpg"
                            let vidLink = 'https://www.youtube.com/embed/' + vidIds.youtube
                            return <IonItem key={idx} onClick={function () {
                                setShowModal(true);
                                setVidLink(vidLink)
                            }}>
                                <IonThumbnail slot="start">
                                    <img alt="youtube video thumbnail" src={src}/>
                                </IonThumbnail>
                                <IonLabel text-wrap>{vidIds.title}</IonLabel>
                            </IonItem>
                        }
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SafetyVids;
