import React from "react"
import payIcon from "../imgs/payroll.png"
import referIcon from "../imgs/refer.png"
import faqIcon from "../imgs/payroll_faq.png"
import {Link} from "react-router-dom";

const Money = () => {
    return (
        <div className="item-container">
            <div className="grid50">
            <Link to="/settlements" className="western-button-link">
                    <div className="loading-div" id="centered">
                        <p style={{margin: "15% 0 0%"}}>Settlements</p>
                        <img src={payIcon} alt="key" className="link-icon"/>
                    </div>
                </Link>
                <div>
                    <Link to="/deferment" className="western-button-link">
                        <div>
                            <img src={referIcon} alt="key" className="link-icon"/><p>Deferment</p>
                        </div>
                    </Link>
                    <Link to="/payrollfaq" className="western-button-link">
                        <div>
                            <img src={faqIcon} alt="key" className="link-icon"/><p>Settlements FAQ</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Money
