import React, {useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToggle, IonToolbar} from '@ionic/react';
import {alertCircleOutline, warning} from 'ionicons/icons';
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

const PreTrip: React.FC = () => {

    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));

    const [loading, setLoading] = useState(false);
    const [checked1, setChecked1] = useState(true);
    const [checked2, setChecked2] = useState(true);
    const [checked3, setChecked3] = useState(true);
    const [checked4, setChecked4] = useState(true);
    const [checked5, setChecked5] = useState(true);
    const [checked6, setChecked6] = useState(true);
    const [checked7, setChecked7] = useState(true);
    const [checked8, setChecked8] = useState(true);
    const [checked9, setChecked9] = useState(true);
    const [checked10, setChecked10] = useState(true);
    const [checked11, setChecked11] = useState(true);
    const [checked12, setChecked12] = useState(true);
    const [checked13, setChecked13] = useState(true);
    const [checked14, setChecked14] = useState(true);
    const [checked15, setChecked15] = useState(true);
    const [checked16, setChecked16] = useState(true);
    const [checked17, setChecked17] = useState(true);
    const [checked18, setChecked18] = useState(true);
    const [checked19, setChecked19] = useState(true);
    const [checked20, setChecked20] = useState(true);
    const [checked21, setChecked21] = useState(true);
    const [checked22, setChecked22] = useState(true);
    const [checked23, setChecked23] = useState(true);
    const [checked24, setChecked24] = useState(true);
    const [checked25, setChecked25] = useState(true);
    const [checked26, setChecked26] = useState(true);
    const [text, setText] = useState<string>();

    const isIssue = !checked1 || !checked2 || !checked3 || !checked4 || !checked5 || !checked6 || !checked7 || !checked8 || !checked9 || !checked10 || !checked11 || !checked12 || !checked13 || !checked14 || !checked15 || !checked16 || !checked17 || !checked18 || !checked19 || !checked20 || !checked21 || !checked22 || !checked23 || !checked24 || !checked25 || !checked26;

    let array = [checked1, checked2, checked3, checked4, checked5, checked6, checked7, checked8, checked9, checked10, checked11, checked12, checked13, checked14, checked15, checked16, checked17, checked18, checked19, checked20, checked21, checked22, checked23, checked24, checked25, checked26]
    let problems = ["PM on current equipment", "Service Breaks", "Air Lines", "Parking Brakes", "Steering Mechanism", "Lighting and Reflectors", "Horn", "Windshield Wipers", "Rear Vision Mirrors", "Coupling Devices", "Wheels and Rims", "Emergency Equipment", "Fluid Level: Oil", "Fluid Level: Coolant", "Fluid Level: Fuel", "Fluid Level: Diesel Exhaust", "Steer: Left", "Steer: Right", "Drive: Front Axle Left", "Drive: Front Axle Right", "Drive: Rear Axle Left", "Drive: Rear Axle Right", "Trailer: Front Axle Left", "Trailer: Front Axle Right", "Trailer: Rear Axle Left", "Trailer: Rear Axle Right"]

    const getIssues = () => {
        let issues: any = {}
        for (let i = 0; i < array.length; i++) {
            issues[problems[i]] = !array[i]
        }
        issues.comments = text
        issues.isIssue = isIssue
        return issues
    }

    const getUserId = () => {
        if (isIssue) {
            ApiService.inboxSendMessage('preTrip', {id: 60, toUserId: userStore.getState().user.id, body: 'Your Pre-trip has been submitted.', subject: '', type: 'dm'}).then(it => {
                submitReport(it.conversationId);
            });
        } else {
            submitReport();
        }
    }

    const submitReport = (conversationId?: number) => {
        let reportData = getIssues();
        if (conversationId) reportData.conversationId = conversationId;
        reportData = JSON.stringify(reportData);

        ApiService.inspectionSubmission("preTrip", {
            dmCode: userInfo.dmCode,
            division: userInfo.division,
            load: userStore.getState().loadNumber,
            unit: userInfo.unit,
            trailer: userInfo.trailer,
            reportData: reportData,
            type: 'pretrip',
            photos: 0
        }).then(() => {
            toastStore.getState().setSuccessToast('Your pretrip was submitted.');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Pre-Trip Inspection</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="container">
                    <IonModal isOpen={loading} id="transparent">
                        <div className="modal-div">
                            <div id="modal">
                                <div className='loading-div'>
                                    <p>Please wait... your pretrip is being submitted.</p>
                                    <div className="snippet" data-title=".dot-flashing" style={{margin: "4px 16px"}}>
                                        <div className="stage">
                                            <div className="dot-flashing"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonModal>
                    <IonList>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <p>Tractor Number: {userInfo.unit}</p>
                                <p>Trailer Number: {userInfo.trailer}</p>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <p>Inspection Areas: If anything has an issue, it should NOT be green.</p>
                            </div>
                        </IonItem>


                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>PM current on equipment</p>
                                <IonToggle checked={checked1} onIonChange={e => setChecked1(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Service Brakes</p>
                                <IonToggle checked={checked2} onIonChange={e => setChecked2(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Air Lines</p>
                                <IonToggle checked={checked3} onIonChange={e => setChecked3(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Parking Brakes</p>
                                <IonToggle checked={checked4} onIonChange={e => setChecked4(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steering Mechanism</p>
                                <IonToggle checked={checked5} onIonChange={e => setChecked5(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Lighting and Reflectors</p>
                                <IonToggle checked={checked6} onIonChange={e => setChecked6(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Horn</p>
                                <IonToggle checked={checked7} onIonChange={e => setChecked7(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Windshield Wipers</p>
                                <IonToggle checked={checked8} onIonChange={e => setChecked8(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Rear Vision Mirrors</p>
                                <IonToggle checked={checked9} onIonChange={e => setChecked9(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Coupling Devices</p>
                                <IonToggle checked={checked10} onIonChange={e => setChecked10(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Wheels and Rims</p>
                                <IonToggle checked={checked11} onIonChange={e => setChecked11(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Emergency Equipment</p>
                                <IonToggle checked={checked12} onIonChange={e => setChecked12(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Oil</p>
                                <IonToggle checked={checked13} onIonChange={e => setChecked13(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Coolant</p>
                                <IonToggle checked={checked14} onIonChange={e => setChecked14(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Fuel</p>
                                <IonToggle checked={checked15} onIonChange={e => setChecked15(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Diesel Exhaust</p>
                                <IonToggle checked={checked16} onIonChange={e => setChecked16(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steer: Left</p>
                                <IonToggle checked={checked17} onIonChange={e => setChecked17(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steer: Right</p>
                                <IonToggle checked={checked18} onIonChange={e => setChecked18(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Front Axle Left</p>
                                <IonToggle checked={checked19} onIonChange={e => setChecked19(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Front Axle Right</p>
                                <IonToggle checked={checked20} onIonChange={e => setChecked20(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Rear Axle Left</p>
                                <IonToggle checked={checked21} onIonChange={e => setChecked21(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Rear Axle Right</p>
                                <IonToggle checked={checked22} onIonChange={e => setChecked22(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Trailer: Front Axle Left</p>
                                <IonToggle checked={checked23} onIonChange={e => setChecked23(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Trailer: Front Axle Right</p>
                                <IonToggle checked={checked24} onIonChange={e => setChecked24(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p> Trailer: Rear AxleLeft</p>
                                <IonToggle checked={checked25} onIonChange={e => setChecked25(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p> Trailer: Rear Axle Right</p>
                                <IonToggle checked={checked26} onIonChange={e => setChecked26(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <p>Other Issues:</p>
                                <p className='subtitle'>Please provide details on issues not listed above.</p>
                                <div className="input-container">
                                    <input id="issues" className="input" type="text" placeholder="" value={text} onChange={e => setText(e.target.value!)}/>
                                    <p className="placeholder">Comments</p>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <div className="space-between">
                                    <p style={{width: "80%"}} className="subtitle">Per FMCSR 396.11, the driver must complete this inspection at the end of each work day. If a defect is found, it must
                                        be reported and repaired prior to continuing on or beginning the next trip.</p>
                                    <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <div className="space-between">
                                    <p style={{width: "80%"}} className="subtitle">Legal Agreement. I approve the above information as complete and correct and certify that I am the driver identified
                                        above. I further acknowledge that and consent to the use of this submission as an electronic signature in accordance with 49 CFR § 390.32 and 15.U.S.C. §
                                        7001(c).</p>
                                    <IonIcon icon={alertCircleOutline} style={{color: "#ff0000", fontSize: "24px"}}></IonIcon>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container'>
                                <IonButton id="western-button-submit" expand="block" onClick={() => {
                                    getUserId();
                                    setLoading(true)
                                }}>Submit</IonButton>
                            </div>
                        </IonItem>
                    </IonList>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default PreTrip;
