import React, {useState} from 'react';
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonPage, IonTitle, IonToggle, IonToolbar} from '@ionic/react';
import {alertCircleOutline, chevronBack} from 'ionicons/icons';
import {useHistory} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {userStore} from "../api/AuthService";
// uninstall

const LeasePurchase: React.FC = () => {

    const history = useHistory();

    const {jsPDF} = require("jspdf")
    require("jspdf-autotable")
    const [text, setText] = useState("")
    const [text2, setText2] = useState("")
    const [tractor, setTractor] = useState(0)
    const [checked1, setChecked1] = useState(true);
    const [checked2, setChecked2] = useState(true);
    const [checked3, setChecked3] = useState(true);
    const [checked4, setChecked4] = useState(true);
    const [checked5, setChecked5] = useState(true);
    const [checked6, setChecked6] = useState(true);
    const [checked7, setChecked7] = useState(true);
    const [checked8, setChecked8] = useState(true);
    const [checked9, setChecked9] = useState(true);
    const [checked10, setChecked10] = useState(true);
    const [checked11, setChecked11] = useState(true);
    const [checked12, setChecked12] = useState(true);
    const [checked13, setChecked13] = useState(true);
    const [checked14, setChecked14] = useState(true);
    const [checked15, setChecked15] = useState(true);
    const [checked16, setChecked16] = useState(true);
    const [checked17, setChecked17] = useState(true);
    const [checked18, setChecked18] = useState(true);
    const [checked19, setChecked19] = useState(true);
    const [checked20, setChecked20] = useState(true);
    const [checked21, setChecked21] = useState(true);
    const [checked22, setChecked22] = useState(true);
    const [checked23, setChecked23] = useState(true);
    const [checked24, setChecked24] = useState(true);
    const [checked25, setChecked25] = useState(true);
    const [checked26, setChecked26] = useState(true);
    const [checked27, setChecked27] = useState(true);
    const [checked28, setChecked28] = useState(true);
    const [checked29, setChecked29] = useState(true);
    const [checked30, setChecked30] = useState(true);
    const [checked31, setChecked31] = useState(true);
    const [checked32, setChecked32] = useState(true);

    const [tread1, setTread1] = useState(true)
    const [tread2, setTread2] = useState(true)
    const [tread3, setTread3] = useState(true)
    const [tread4, setTread4] = useState(true)
    const [tread5, setTread5] = useState(true)
    const [tread6, setTread6] = useState(true)
    const [tread7, setTread7] = useState(true)
    const [tread8, setTread8] = useState(true)
    const [tread9, setTread9] = useState(true)
    const [tread10, setTread10] = useState(true)
    const [loading, setShowLoading] = useState(false)
    const [driverName, setDriverName] = useState("")
    const [showPrompt, setShowPrompt] = useState(true)

    const isIssue = !checked1 || !checked2 || !checked3 || !checked4 || !checked5 || !checked6 || !checked7 || !checked8 || !checked9 || !checked10 || !checked11 || !checked12 || !checked13 || !checked14 || !checked15 || !checked16 || !checked17 || !checked18 || !checked19 || !checked20 || !checked21 || !checked22 || !checked23 || !checked24 || !checked25 || !checked26 || !checked27 || !checked28 || !checked29 || !checked30 || !checked31 || !checked32;
    let array = [checked1, checked2, checked3, checked4, checked5, checked6, checked7, checked8, checked9, checked10, checked11, checked12, checked13, checked14, checked15, checked16, checked17, checked18, checked19, checked20, checked21, checked22, checked23, checked24]
    let problems = ["Wind Shield", "Fifth Wheel", "Fire Extinguisher", "Headache Rack", "Mirrors", "Body Panels", "Right Side Body Molding", "Curtains", "Sleeper", "Front Bumper", "Grill", "Left Side Body Molding", "Radio", "Tires", "Dash", "Lights", "Platform Science Tablet", "Shifter", "Wheels", "interior Panels", "Air Horn", "Flooring", "Dash Buttons", "Seats"]
    let treadDepth = [tread1, tread2, tread3, tread4, tread5, tread6, tread7, tread8, tread9, tread10]

    let treadItems = [
        {value: tread1, func: setTread1}, {value: tread2, func: setTread2}, {value: tread3, func: setTread3}, {value: tread4, func: setTread4}, {value: tread5, func: setTread5}, {
            value: tread6,
            func: setTread6
        }, {value: tread7, func: setTread7}, {value: tread8, func: setTread8}, {value: tread9, func: setTread9}, {value: tread10, func: setTread10}
    ]

    let boxes = [{value: "Wind Shield", func: setChecked1},
        {value: "Fifth Wheel", func: setChecked2},
        {value: "Fire Extinguisher", func: setChecked3},
        {value: "Headache Rack", func: setChecked4},
        {value: "Mirrors", func: setChecked5},
        {value: "Body Panels", func: setChecked6},
        {value: "Right Side Body Molding", func: setChecked7},
        {value: "Curtains", func: setChecked8},
        {value: "Sleeper", func: setChecked9},
        {value: "Front Bumper", func: setChecked10},
        {value: "Grill", func: setChecked11},
        {value: "Left Side Body Molding", func: setChecked12},
        {value: "Radio", func: setChecked13},
        {value: "Tires", func: setChecked14},
        {value: "Dash", func: setChecked15},
        {value: "Lights", func: setChecked16},
        {value: "Platform Science Tablet", func: setChecked17},
        {value: "Shifter", func: setChecked18},
        {value: "Wheels", func: setChecked19},
        {value: "interior Panels", func: setChecked20},
        {value: "Air Horn", func: setChecked21},
        {value: "Flooring", func: setChecked22},
        {value: "Dash Buttons", func: setChecked23},
        {value: "Seats", func: setChecked24}]


    let received = ["Fuel Card", "Platform Science Tablet", "License Plate", "Decals/Logo", "Contract", "Insurance", "Permit Book", "Received Fire Extinguisher"]
    let receivedValues = [checked25, checked26, checked27, checked28, checked29, checked30, checked31, checked32]
    let receivedItems = [
        {name: "Fuel Card", value: checked25, func: setChecked25}, {name: "Platform Science Tablet", value: checked26, func: setChecked26}, {
            name: "License Plate",
            value: checked27,
            func: setChecked27
        }, {name: "Decals/Logo", value: checked28, func: setChecked28}, {name: "Contract", value: checked29, func: setChecked29}, {
            name: "Insurance",
            value: checked30,
            func: setChecked30
        }, {name: "Permit Book", value: checked31, func: setChecked31}, {name: "Received Fire Extinguisher", value: checked32, func: setChecked32}
    ]

    function fillReport() {
        const unit = 'in'
        const size = 'A4'
        const orientation = 'p'
        const doc = new jsPDF(orientation, unit, size)
        doc.setFontSize(14)

        const driverHeader = [["Driver Name", "Driver Code", "Truck #"]]
        const driverBody = [[driverName, localStorage.getItem("username"), tractor]]


        let issues = ""
        let noissues = ""
        for (let i = 0; i < array.length; i++) {
            if (!array[i]) {
                issues += problems[i] + ", "
            } else {
                noissues += problems[i] + ", "
            }
        }
        let receivedParts = ""
        let didNotReceive = ""
        for (let i = 0; i < received.length; i++) {
            if (receivedValues[i]) {
                receivedParts += received[i] + ", "
            } else {
                didNotReceive += received[i] + ", "
            }
        }

        let treadArray = ""
        for (let i = 0; i < treadDepth.length; i++) {
            if (treadDepth[i]) {
                treadArray += i + 1 + ". Pass\n"
            } else {
                treadArray += i + 1 + ". Fail\n"
            }
        }

        const driverContent = {
            theme: "plain",
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: 1.6,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1

            },
            head: driverHeader,
            body: driverBody
        }
        const missingOrDamaged = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1

            },
            head: [["Missing or Damaged Parts:"]],
            body: [[issues !== "" ? issues : "N/A"]]
        }
        const noIssue = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1

            },
            head: [["No Issue:"]],
            body: [[noissues !== "" ? noissues : "N/A"]]
        }
        const receivedItems = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 4.2
            },
            head: [["Initialed upon receipt the following items:"]],
            body: [[receivedParts]]
        }
        const didNotReceivedItems = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0,
                left: 4.2
            },
            head: [["Did not receive:"]],
            body: [[didNotReceive !== "" ? didNotReceive : "N/A"]]
        }
        const treadContent = {
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0,
                left: 4.2
            },
            head: [["Tread Depth:"]],
            body: [[treadArray]]
        }
        const notes = {
            theme: "plain",
            headStyles: {fillColor: [248, 248, 248]},
            columnStyles: {0: {fillColor: [248, 248, 248]}},
            styles: {
                fontSize: 10,
                overflow: 'linebreak'
            },
            startY: doc.lastAutoTable.finalY,
            margin: {
                top: 0.1,
                left: 0.1,
                right: 0.1

            },
            head: [["Notes:"]],
            body: [[text + " " + text2]]
        }
        var logo = new Image()
        logo.src = "/assets/WE_logo-black.png"

        var treadImage = new Image()
        treadImage.src = "/assets/tires.JPG"

        doc.addImage(logo, 2.5, 0)
        doc.text('New Tractor Checklist | Date: ' + new Date().toLocaleDateString().slice(0, 10), 2.2, 1.5)
        doc.autoTable(driverContent)
        doc.autoTable(noIssue)
        doc.autoTable(missingOrDamaged)
        doc.addImage(treadImage, 0.1, doc.lastAutoTable.finalY, 4, 3)
        doc.autoTable(treadContent)
        doc.autoTable(receivedItems)
        doc.autoTable(didNotReceivedItems)
        doc.autoTable(notes)

        const datapath = doc.output("datauristring")
        submitForm(datapath)
        submitReport()

    }

    function createReport() {
        let issues: any = {}
        for (let i = 0; i < array.length; i++) {
            issues[problems[i]] = !array[i]
        }
        issues.comments = text + " " + text2
        issues.isIssue = isIssue

        let receivedItems: any = {}
        for (let i = 0; i < received.length; i++) {
            receivedItems[received[i]] = receivedValues[i]
        }

        let treads: any = {}
        for (let i = 0; i < treadDepth.length; i++) {
            treads[i + 1] = treadDepth[i]
        }

        let report: any = {issues, receivedItems, treads}

        return report
    }

    const b64toBlob = (base64:any) => 
    fetch(base64).then(res => res.blob())

    async function submitForm(data: string) {
      let tempFile = new Blob([(await b64toBlob(data))], {type: 'application/pdf'})
      ApiService.sendFileEmail("leasePurchase", {
          email: 'lpdocuments@westernexp.com',
          subject: 'New Tractor Checklist',
      },
      {
        file1: {
          fileName: "Lease Purchase Form",
          file: tempFile
        }
      }
      ).then(() => {
          toastStore.getState().setSuccessToast('Thank you! Your form was submitted.');
          history.push('/');
      }).finally(() => setShowLoading(false));
    }

    const submitReport = () => {
        ApiService.lpInspectionSubmission('leasePurchase', {dmCode: userStore.getState().user!.dmcode, unit: tractor, reportData: JSON.stringify(createReport())}).then(() => {
            toastStore.getState().setSuccessToast('Thank you! Your form was submitted.');
            history.push('/');
        }).finally(() => {
            setShowLoading(false);
        });
    }

    const checkList1 = boxes.map((problem, index) => {
        return (
            <IonItem lines="none" color="light" key={index}>
                <div style={{display: "flex", justifyContent: "space-between", width: "90%", marginLeft: "15px"}}>
                    {array[index] ? <p>{problem.value}</p> : <p style={{color: "red", display: "flex", alignItems: "center"}}>{problem.value} <IonIcon icon={alertCircleOutline}></IonIcon></p>}
                    <input type="checkbox" checked={array[index]} onChange={e => problem.func(e.target.checked)}/>
                </div>
            </IonItem>
        )
    })
    const checkList2 = receivedItems.map((item, index) => {
        return (
            <IonItem lines="none" color="light" key={index}>
                <div style={{display: "flex", justifyContent: "space-between", width: "90%"}}>
                    <p>{item.name}</p>
                    <IonToggle checked={item.value} color="success" onIonChange={(e) => item.func(e.detail.checked!)}/>
                </div>
            </IonItem>
        )
    })
    const checkList3 = treadItems.map((tread, index) => {
        return (
            <IonItem lines="none" color="light" key={index}>
                <div style={{display: "flex", justifyContent: "space-between", width: "90%"}}>
                    <p>{index + 1}. {tread.value ? "Pass" : "Fail"}</p>
                    <IonToggle checked={tread.value} color="success" onIonChange={(e) => tread.func(e.detail.checked!)}/>
                </div>
            </IonItem>
        )
    })

    return (
        <IonPage>
            <IonModal isOpen={loading} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                            <p>Please wait... your form is being submitted.</p>
                            <div className="snippet" data-title=".dot-flashing" style={{margin: "4px 16px"}}>
                                <div className="stage">
                                    <div className="dot-flashing"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonModal>
            <IonModal isOpen={showPrompt}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonIcon style={{fontSize: "x-large"}} icon={chevronBack} onClick={() => history.push('/')}/>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem lines="none">
                        <div className='item-container'>
                            <p className='title' style={{fontSize: "x-large"}}>Before you submit:</p>
                            <p>This form is for lease purchase / owner operator drivers, and is for new tractors only.</p>
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <div className='item-container'>
                            <button className='western-button submit' onClick={() => setShowPrompt(false)}>I Understand</button>
                        </div>
                    </IonItem>
                </IonContent>
            </IonModal>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home"/>
                    </IonButtons>
                    <IonTitle>Vehicle Inspection</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <div className="input-container">
                            <input id="tractor" className="input" type="number" placeholder=" " value={tractor} onChange={e => setTractor(parseInt(e.target.value!, 10))}/>
                            <p className="placeholder">Tractor Number</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <div className="input-container">
                            <input id="name" className="input" type="text" placeholder=" " value={driverName} onChange={e => setDriverName(e.target.value!)}/>
                            <p className="placeholder">Full Name</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <p className='subtitle'>Please review each area, and note damaged or missing parts.<span style={{color: "red", display: "flex", alignItems: "center"}}>Unchecked items have issues<IonIcon
                            icon={alertCircleOutline}></IonIcon></span></p>
                    </div>
                </IonItem>
                {checkList1}
                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <p className='subtitle'>Provide a brief summary of damage, or missing parts.</p>
                    </div>
                </IonItem>

                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <div className="input-container">
                            <textarea className="input" placeholder=" " value={text} onChange={e => setText(e.target.value!)}/>
                            <p className="placeholder">Type here</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className='item-container'>
                        <p className='subtitle' style={{color: "#D0A024"}}>Upon receipt, check the following items(should be in or on your truck, or given to you by adminstration):</p>
                    </div>
                </IonItem>

                <div className="container">
                    <IonCard color="whitesmoke">
                        <IonCardContent>

                            {checkList2}
                            <IonItem color="light" lines="none">
                                <div className='item-container'>
                                    <p className='subtitle'>Tread Depth: <span style={{color: "red"}}>(2/32 - 4/32 in)</span></p>
                                    <p className='subtitle'>Toggled items indicate the tire meets the requirements:</p>
                                </div>
                            </IonItem>
                            <img id="tire-diagram" src="./assets/tires.JPG" alt="tread depth"/>
                            {checkList3}

                            <IonItem color="light" lines="none">
                                <div className='item-container'>
                                    <p className='subtitle'>Provide a brief summary of tires that do not meet the requirements.</p>
                                </div>
                            </IonItem>

                            <IonItem color="light" lines="none">
                                <div className='item-container'>
                                    <div className="input-container">
                                        <textarea className="input" placeholder=" " value={text2} onChange={e => setText2(e.target.value!)}/>
                                        <p className="placeholder">Type here</p>
                                    </div>
                                </div>
                            </IonItem>

                            <IonItem lines="none" color="light">
                                <div className='item-container'>
                                    <div className="space-between">
                                        <p style={{width: "80%"}} className="subtitle">Legal Agreement. I approve the above information as complete and correct and certify that I am the driver
                                            identified above. I further acknowledge that and consent to the use of this submission as an electronic signature in accordance with 49 CFR § 390.32 and
                                            15.U.S.C. § 7001(c).</p>
                                        <IonIcon icon={alertCircleOutline} style={{color: "#ff0000", fontSize: "24px"}}></IonIcon>
                                    </div>
                                </div>
                            </IonItem>
                            <IonItem color="light" lines="none">
                                <div className='item-container'>
                                    <button className="western-button submit" onClick={() => {
                                        if (driverName !== "" && driverName !== " " && tractor !== 0 && tractor > 0) {
                                            setShowLoading(true);
                                            fillReport()
                                        } else {
                                            toastStore.getState().setToast('Please include tractor number, and name.')
                                        }
                                    }}>SUBMIT
                                    </button>
                                </div>
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default LeasePurchase;
