import React, {useEffect, useState} from "react";
import {IonItem} from "@ionic/react";

import historyIcon from "../imgs/clock-history.png"
import historyActiveIcon from "../imgs/clock-history-active.png"
import ReportDetail from "./ReportDetail";
import {ApiService} from "../api/ApiService";

export interface ReportReference {
    date: string,
    trainerCode: string
}

const ReportHistory = (props: any) => {

    const [showHistory, setShowHistory] = useState(false)
    const [reports, setReports] = useState<ReportReference[]>([]);

    useEffect(() => {
        ApiService.getTrainingReports2('reportHistory', {traineeCode: props.trainee.traineeCode}, true).then(it => {
            parseReports(it.reports);
        });
    }, [props]);

    const parseReports = (reportsArray: any[]) => {
        let tempArray: any[] = []
        reportsArray.forEach(element => {
            let obj: ReportReference = {date: '', trainerCode: ''};
            obj.date = element.date;
            obj.trainerCode = element.trainerCode
            tempArray.push(obj);
        });
        setReports(tempArray);
    }

    return (
        <IonItem lines="none" color="light">
            <div className='item-container' style={{overflow: "auto"}}>
                {showHistory ? <div className="report-history">
                        <div style={{textAlign: "center", position: "relative"}} onClick={() => setShowHistory(!showHistory)}><img src={historyActiveIcon} alt="" style={{
                            height: "2.5vh",
                            position: "absolute",
                            left: "8px",
                            top: "8px"
                        }}/>
                            <p className="subtitle">Show Less</p></div>
                        <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p style={{margin: 0}}>Report History</p>
                            </div>
                        </IonItem>
                        {reports.map(it => <ReportDetail trainee={props.trainee.traineeCode} date={it.date}/>)}
                        <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p className="subtile">Showing {reports.length} result(s).</p>
                            </div>
                        </IonItem>
                    </div> :
                    <div style={{display: "flex", alignItems: "center"}} onClick={() => setShowHistory(!showHistory)}>
                        <p className="subtitle" style={{margin: 0}}>See History</p>
                        <img src={historyIcon} alt="" style={{height: "2.5vh"}}/>
                    </div>
                }
            </div>
        </IonItem>
    )
}

export default ReportHistory
