import React from "react";
import {IonItem} from "@ionic/react";

function PrePlannedEntry(props: any) {
    return (
        <div style={{margin: "8px", borderRadius: "10px", overflow: "hidden", boxShadow: "0 4px 4px rgba(0,0,0,0.25)"}}>
            <IonItem color="white" lines="none">
                <h4 className='title boldest' style={{color: "#99cc00"}}>{props.load.orderNumber}</h4>
            </IonItem>
            <IonItem color="white" lines="none">
                <div>
                    <strong> Unit: {props.load.unitNumber}</strong><br/>
                    <strong> BOL: {props.load.blNumber}</strong><br/>
                </div>
            </IonItem>
            <IonItem color="white" lines="none">
                <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>L</p></div>
                <strong>Load At</strong>
            </IonItem>
            <IonItem color="white" lines="none">
                <div>
                    <p className='subtitle'>{props.load.pickupStartDate} {props.load.pickupStartTime} - {props.load.pickupEndDate} {props.load.pickupEndTime}</p>
                    <p>{props.load.shipperAddress1}<br/>{props.load.shipperAddress2}</p>
                    <p>{props.load.shipperCity}, {props.load.shipperState} {props.load.shipperZip}</p>
                </div>
            </IonItem>
            <IonItem color="white" lines="none">
                <div className='box-grid'>
                    <p>PO: {props.load.poNumber || "-"}</p><p>PICKUP: {props.load.pickupNumber || "-"}</p>
                    <p>ORDER: {props.load.orderNumber || "-"}</p>
                </div>
            </IonItem>
            <IonItem color="white" lines="none">
                <div className='letter-icon' style={{backgroundColor: "#FF8575"}}><p>C</p></div>
                <strong>Consignee</strong>
            </IonItem>
            <IonItem color="white" lines="none">
                <div>
                    <p className='subtitle'>{props.load.deliveryStartDate} {props.load.deliveryStartTime} - {props.load.deliveryEndDate} {props.load.deliveryEndTime}</p>
                    <p>{props.load.consigneeAddress1}<br/>{props.load.consigneeAddress2}</p>
                    <p>{props.load.consigneeCity}, {props.load.consigneeState} {props.load.consigneeZip}</p>
                </div>
            </IonItem>
        </div>
    )
}

export default PrePlannedEntry
