import React from 'react'

function PassengerPolicy() {
    return (

        <div className='item-container'>
            <p>The purpose of this coverage:</p>
            <ol>
                <li>Provides liability protection to Western Express in the event of a passenger injury or death, who is an authorized passenger on a Western Express owned or leased tractor.</li>
                <li>Provides an accidental injury or death benefit to an authorized passenger who has completed the insurance application.</li>
                <li>Provides a flexible passenger program for the driver with weekly deductions and sign up at any point.</li>
            </ol>
            <p>THINGS TO REMEMBER:</p>
            <ol>
                <li>Enrollment can be authorized at any point during the year.</li>
                <li>Opting out of the program is ONLY allowed in the months of December and June.</li>
                <li>All passenger authorizations are valid only on the most recent date application on file.</li>
                <li>Drivers will be able to get passenger insurance application/rider’s pass from their Driver Managers. This application is to be completed by the passenger, signed by the driver, and
                    faxed/emailed back to the Driver Manager. If the forms are filled our correctly, the Driver Manager will sign the bottom and submit to benefitshelp@westernexp.com. The passenger is
                    not allowed on the truck until the Driver Managers have confirmation from Benefits that they have received and added the insurance for the Driver.
                </li>
                <li>The driver will have a $6.00 weekly deduction taken form their payroll until the coverage is found to be null and void. If this deduction is not paid weekly, the coverage will not
                    be in place. It is the driver’s responsibility to be sure this deduction is on every paycheck stub.
                </li>
                <li>Drivers may have multiple passengers (one at a time) during the year. A new form must be filled out for each passenger.</li>
                <li>The passenger must be 18 years of age. Only two people per truck will be allowed, Driver plus one Passenger.</li>
            </ol>
            <p>The only reasons for cancellation of coverage are as follows:</p>
            <ol>
                <li>The driver is no longer an employee of Western Express.</li>
                <li>The driver waits until the “opt out” period, (December and June) and can be released from coverage by emailing benefitshelp@westernexp.com with their name, driver code, and asking
                    to remove the coverage.
                </li>
            </ol>
            <p>Any driver that is determined to have an unauthorized passenger, or a passenger that has not completed the insurance application, will be subject to disciplinary action up to and
                including termination. The passenger and driver must have copies of the passenger authorization/insurance application in their possession. The passenger will be removed from the truck
                if an Enforcement Inspection deems that the required paperwork for passenger is not in the possession of The Western Express Driver.</p>
            <br/>
            <br/>
            <p>If you have any questions, please reach out to benefitshelp@westernexp.com or 615-369-8279.</p>

        </div>
    )
}

export default PassengerPolicy
