import React, {useEffect, useState} from "react";
import settingsIcon from "../imgs/settings.png"
import notifyIcon from "../imgs/notification.png"
import messageIcon from "../imgs/messages.png"
import {ApiService} from "../api/ApiService";
import {Link} from "react-router-dom";

const Toolbar = () => {

    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        ApiService.getPushNotifications('toolBar').then(it => {
            setNotificationCount(it.filter(notification => !notification.read).length);
        });
    }, []);

    return (
        <div className="item-container" id="tooly">
            <div style={{float: "right"}} id="toolbar-right">
                <Link to="/Inbox">
                    <img src={messageIcon} alt="messages"/>
                </Link>
                <Link style={{position: "relative"}} to="/notifications">
                    <img src={notifyIcon} alt="notify"/>
                    {notificationCount ? <div className="notify-badge"><p>{notificationCount}</p></div> : null}
                </Link>
                <Link to="/Settings">
                    <img src={settingsIcon} alt="settings"/>
                </Link>
            </div>
        </div>
    )
}

export default Toolbar
