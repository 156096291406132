import React, { useState } from "react";
import { IonIcon, IonItem } from "@ionic/react";
import { alertCircleOutline, chevronDown, chevronUp } from "ionicons/icons";



const RoadTestToggle: React.FC<{element: any}> = ({element}) => {
    const [hidden, setHidden] = useState(false)
    return (
        <IonItem lines="none" color="light" onClick={() => setHidden(!hidden)}>
        <div style={{ width: "100%"}}>
        <p style={{margin: 0, display: "flex", gap: "4px", alignItems: "center", fontSize: "14px"}} className="subtitle">{element.name} {element.value === "Auto Fail" ? <span style={{ color: "#FF0000"}}>{element.value}</span> : <span style={{ color: "#5096FF"}}>{element.value}</span>}{hidden ? <IonIcon icon={chevronDown}></IonIcon> : <IonIcon icon={chevronUp}></IonIcon>}{element.value === "Auto Fail" ? <IonIcon icon={alertCircleOutline} style={{ color: "#FF0000", fontSize: "24px"}}></IonIcon> : null}</p>
        {!hidden ?<div className='item-container' style={{ display: "grid", alignItems: "center", backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px", margin: 0}}>
          <div style={{display: "grid", gridTemplateColumns: element.answers.length <= 2 ? "50% 50%" : "33% 33% 33%" , textAlign: "center"}}> 
          {element.answers.map((answer: string, index: number) => {
            return (
              <p key={index} style={{ color: element.value === answer && index ? "#FF0000" : element.value === answer ? "#2fd573" : "#212121",  borderRight: index + 1 === element.answers.length ? "transparent" : "#7c7c7c44 1px solid"}} onClick={() => element.func(answer)}>{answer}</p>
              )
            })}
            </div>
        </div> : null}
        </div>
      </IonItem>
    )
}

export default RoadTestToggle