import React, {useEffect, useState} from 'react';
import {IonButton, IonContent, IonIcon, IonPage, isPlatform} from '@ionic/react';
import {AppLauncher} from '@capacitor/app-launcher';
import {config} from '../Constants'
import {chevronForward} from 'ionicons/icons';
import Header from './Header';
import AnimateHeight from 'react-animate-height';

//  ICONS
import agreement from '../imgs/work/agreement.png';
import contacts from '../imgs/work/contacts.png';
import exchanging from '../imgs/work/exchanging.png';
import graduate from '../imgs/work/graduate.png';
import history from '../imgs/work/history.png';
import leasing from '../imgs/work/leasing.png';
import passenger from '../imgs/work/passenger.png';
import qrScan from '../imgs/work/qr-scan.png';
import reportCard from '../imgs/work/report-card.png';
import report from '../imgs/work/report.png';
import resume from '../imgs/work/resume.png';
import signpost from '../imgs/work/signpost.png';
import certificate from '../imgs/work/certificate.png';
import time from '../imgs/work/time.png';
import trainee from '../imgs/work/trainee.png';

//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app"
import {UserState, userStore} from "../api/AuthService";
import {PlatformUtils} from "../utils/PlatformUtils";
import {ApiService} from "../api/ApiService";

const Work: React.FC = () => {

    const {leasePurchase}: { leasePurchase: number } = userStore((state: UserState) => ({leasePurchase: state.leasePurchase}));

    const [storeLink, setStoreLink] = useState('');
    const [height1, setHeight1] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [height3, setHeight3] = useState(0);

    const buttonArray = ['orientation', 'training'];

    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }

    //Initialization:
    const app = initializeApp(firebaseConfig)

    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
    }

    useEffect(() => {
        setStoreLink(PlatformUtils.getStoreLink());
    }, []);

    const toggle = () => {
        if (height1 === 0) {
            activeButton('training');
            setHeight1(1);
            setHeight2(0)
        } else {
            setHeight1(0);
            document.getElementById('training')?.setAttribute('style', '');
        }
    }

    const toggle2 = () => {
        if (height2 === 0) {
            activeButton('orientation');
            setHeight2(1);
            setHeight1(0);
            setHeight3(0)
        } else {
            setHeight2(0);
            document.getElementById('orientation')?.setAttribute('style', '');
        }
    }

    const toggle3 = () => {
        if (height3 === 0) {
            activeButton('trainee');
            setHeight3(1);
            setHeight2(0);
        } else {
            setHeight3(0);
            document.getElementById('trainee')?.setAttribute('style', '');
        }
    }

    function activeButton(id: string) {
        document.getElementById(id)?.setAttribute('style', 'border: 4px solid #99cc00 !important; border-radius: 11px');
        for (let i = 0; i < buttonArray.length; i++) {
            if (buttonArray[i] !== id) {
                document.getElementById(buttonArray[i])?.setAttribute('style', '');
            }
        }
    }

    const transfloClick = () => {
        ApiService.sendEventCommand('Tranflo Route', 'eventCheck', 'User has been routed to the Transflo app');
        const deepLink = `transflomobileplus://scan?recipientid=WSXI&sourceapp=westerndrivermobile&doctypes=TOD,BOL,TRP&driverid=${localStorage.getItem('username')?.toUpperCase()}&truckid=${userStore.getState().driver.unit}`;
        AppLauncher.openUrl({url: deepLink});
    }

    return (
        <IonPage>
            <Header title="Work" titleStyle={{textAlign: 'end'}}/>
            <IonContent id="home" fullscreen>
                <div className={config.isPlatformSci ? "home-div-plat-sci" : "home-div"}>
                    <div className="menu-button">
                        {(isPlatform('desktop') || isPlatform('iphone') || isPlatform('ipad')) ? <IonButton className="home-btn" strong fill="solid" onClick={() => {
                                window.open(storeLink);
                                handleEventLog("scan", "access-PhotoUpload")
                            }}>
                                <div id="btn-text-menu">Scan Docs</div>
                                <div className="menu-icon"><img alt="" src={qrScan}/></div>
                            </IonButton> :
                            <IonButton className="home-btn" strong fill="solid" onClick={() => {
                                transfloClick()
                            }}>
                                <div id="btn-text-menu">Scan Docs</div>
                                <div className="menu-icon"><img alt="" src={qrScan}/></div>
                            </IonButton>}
                        <IonButton className="home-btn" strong fill="solid" href="/Work/passenger" onClick={() => handleEventLog("passenger", "access-PassengerPolicy")}>
                            <div id="btn-text-menu">Add Passenger</div>
                            <div className="menu-icon"><img alt="" src={passenger}/></div>
                        </IonButton>

                        <div className="menu-button">
                            {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ? <>
                                <IonButton id='orientation' className="home-btn" strong fill="solid" onClick={toggle2} aria-expanded={height2 !== 0}>
                                    <div id="btn-text-menu">Orientation</div>
                                    <div className="menu-icon"><img alt="" src={signpost}/></div>
                                </IonButton>
                                {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ? <>
                                    <IonButton id='training' className="home-btn" strong fill="solid" onClick={toggle} aria-expanded={height1 !== 0}>
                                        <div id="btn-text-menu">Training</div>
                                        <div className="menu-icon"><img alt="" src={graduate}/></div>
                                    </IonButton>
                                    <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height1 === 1 ? 'auto' : 0} id="loadSection">
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainingday">
                                            <div className="menu-item-icon"><img alt="" src={report}/></div>
                                            <div id="btn-text-submenu">Training Day</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/releasetrainee">
                                            <div className="menu-item-icon"><img alt="" src={certificate}/></div>
                                            <div id="btn-text-submenu">Release Trainee</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/dailyreports">
                                            <div className="menu-item-icon"><img alt="" src={time}/></div>
                                            <div id="btn-text-submenu">Daily Reports</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/agreements">
                                            <div className="menu-item-icon"><img alt="" src={agreement}/></div>
                                            <div id="btn-text-submenu">Agreements</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainingcontacts">
                                            <div className="menu-item-icon"><img alt="" src={contacts}/></div>
                                            <div id="btn-text-submenu">Training Contact</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                        <IonButton className="home-menu-item" color="transparent" href="/Work/newtrainee">
                                            <div className="menu-item-icon"><img alt="" src={resume}/></div>
                                            <div id="btn-text-submenu">New Trainee</div>
                                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                        </IonButton>
                                    </AnimateHeight> </> : null}
                                <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height2 === 1 ? 'auto' : 0} id="loadSection">
                                    <IonButton className="home-menu-item" color="transparent" href="/Work/roadtests">
                                        <div className="menu-item-icon"><img alt="" src={reportCard}/></div>
                                        <div id="btn-text-submenu">Road Tests</div>
                                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                    </IonButton>
                                    <IonButton className="home-menu-item" color="transparent" href="/Work/retests">
                                        <div className="menu-item-icon"><img alt="" src={history}/></div>
                                        <div id="btn-text-submenu">Re-Test</div>
                                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                    </IonButton>
                                </AnimateHeight>
                            </> : null}
                        </div>
                        <div className="menu-button">
                            <IonButton className="home-btn" strong fill="solid" href="/Work/referral" onClick={() => handleEventLog("driver_Referral", "access-Referral")}>
                                <div id="btn-text-menu">Driver Referral</div>
                                <div className="menu-icon"><img alt="" src={exchanging}/></div>
                            </IonButton>

                            {leasePurchase ? <IonButton className="home-btn" strong fill="solid" href="/Work/leasepurchase" onClick={() => handleEventLog("lease_icon", "access-LeasePurchase")}>
                                    <div id="btn-text-menu">Lease Purchase</div>
                                    <div className="menu-icon"><img alt="" src={leasing}/></div>
                                </IonButton>
                                : !leasePurchase && (localStorage.getItem('type') === "trainee" || localStorage.getItem('type') === "admin") ?
                                    <><IonButton id='trainee' className="home-btn" strong fill="solid" color="tertiary" onClick={toggle3} aria-expanded={height3 !== 0}>
                                        <div id="btn-text-menu">Course (Trainee)</div>
                                        <div className="menu-icon"><img alt="" src={certificate}/></div>
                                    </IonButton>
                                        <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height3 === 1 ? 'auto' : 0} id="traineeSection">
                                            <IonButton className="home-menu-item" color="transparent" href="/Work/traineeprofile">
                                                <div className="menu-item-icon"><img alt="" src={trainee}/></div>
                                                <div id="btn-text-submenu">Trainee Profile</div>
                                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                            </IonButton>
                                            <IonButton className="home-menu-item" color="transparent" href="/Work/trainingcontacts">
                                                <div className="menu-item-icon"><img alt="" src={contacts}/></div>
                                                <div id="btn-text-submenu">Training Contact</div>
                                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                                            </IonButton>
                                        </AnimateHeight></>
                                    : (localStorage.getItem('type') !== "trainee" || localStorage.getItem('type') !== "admin") && !leasePurchase ?
                                        <IonButton className="home-btn" fill="solid" color="transparent"></IonButton> : null}
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Work;
