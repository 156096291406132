import React from "react";

import orientationIcon from "../imgs/orientation.png"
import retestIcon from "../imgs/retest.png"
import trainingIcon from "../imgs/training.png"
import releaseIcon from "../imgs/release.png"

import agreementIcon from "../imgs/agreement.png"
import contractIcon from "../imgs/contract.png"
import {IonItem} from "@ionic/react";
import {Link} from "react-router-dom";


const Onboard = () => {
    return (
        <IonItem lines="none" color="light">
            <div className="item-container">
                <p style={{margin: "0 8px", color: "#212121"}}>Orientation</p>
                <div className="grid50">
                    <Link to="/orientationroadtests" className="western-button-link">
                        <div>
                            <img src={orientationIcon} alt="orientation" className="link-icon"/><p>Road Tests</p>
                        </div>
                    </Link>
                    <Link to="/retest" className="western-button-link">
                        <div>
                            <img src={retestIcon} alt="orientation" className="link-icon"/><p>Re-test</p>
                        </div>
                    </Link>
                </div>
                <p style={{margin: "0 8px", color: "#212121"}}>Training</p>
                <div className="grid50">
                    <Link to="/trainingday" className="western-button-link">
                        <div id="centered">
                            <p>New Report</p>
                            <img src={trainingIcon} alt="orientation"/>
                        </div>
                    </Link>
                    <div>
                        <Link to="/releasetrainee" className="western-button-link">
                            <div>
                                <img src={releaseIcon} alt="orientation" className="link-icon"/><p>Release Trainee</p>
                            </div>
                        </Link>
                        <Link to="/agreements" className="western-button-link">
                            <div>
                                <img src={agreementIcon} alt="orientation" className="link-icon"/><p>Agreements</p>
                            </div>
                        </Link>
                    </div>
                    <Link to="/trainingcontacts" className="western-button-link">
                        <div>
                            <img src={contractIcon} alt="orientation" className="link-icon"/><p>Training Contacts</p>
                        </div>
                    </Link>
                    <Link to="/traineeprofile" className="western-button-link">
                        <div>
                            <img src={contractIcon} alt="orientation" className="link-icon"/><p>Trainee<br/>Info</p>
                        </div>
                    </Link>
                </div>
            </div>
        </IonItem>
    )
}

export default Onboard
