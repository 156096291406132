import {IonToast} from "@ionic/react";
import React from "react";
import {create} from "zustand";

export interface ToastState {
    toast: string | null;
    color: 'danger' | 'success',
    setToast: (toast: string) => void;
    setSuccessToast: (toast: string) => void;
    removeToast: () => void;
}

export const toastStore = create<ToastState>((set) => ({
    toast: null,
    color: 'danger',
    setToast: (toast: string) => set(() => ({toast, color: 'danger'})),
    setSuccessToast: (toast: string) => set(() => ({toast, color: 'success'})),
    removeToast: () => set({toast: null})
}));

const AppToast = () => {
    const {toast, color, removeToast} = toastStore();

    return (
        <IonToast
            isOpen={!!toast}
            onDidDismiss={() => removeToast()}
            message={toast || ''}
            color={color}
            duration={3000}
        />
    )

}
export default AppToast
