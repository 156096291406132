import {LoginResponse} from "./model/LoginResponse";
import {DriverProfile} from "./model/DriverProfile";
import {UserDetails} from "./model/UserDetails";
import {create} from "zustand";
import {UserType} from "./enum/UserType";
import {ApiService} from "./ApiService";
import {LoadDetails} from "./model/LoadDetails";
import {LoadInfo} from "./model/LoadInfo";

export class AuthService {
    public static isLoggedIn(): boolean {
        return !!localStorage.getItem('token') && !!localStorage.getItem('username')?.toUpperCase();
    }

    public static processLogin(data: LoginResponse, credentials: { username: string, password: string }, loginData: { agreedToTerms: boolean, saveCredentials: boolean }): void {
        localStorage.setItem('token', data.loginToken);
        localStorage.setItem('type', data.type);
        localStorage.setItem('username', credentials.username);
        if (loginData.saveCredentials) {
            localStorage.setItem('save', 'true');
            localStorage.setItem('password', credentials.password);
        } else {
            localStorage.removeItem('save');
            localStorage.removeItem('password');
        }
        userStore.getState().clearUserData();
        userStore.getState().performLogin(credentials.username);
        window.history.pushState('', '', '/');
    }

    public static processLogout(): void {
        if (localStorage.getItem('save') === 'true') {
            // If remember me was checked then we keep only the credentials from local storage
            // We remove everything else that is in there
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            localStorage.clear();
            localStorage.setItem('username', username || '');
            localStorage.setItem('password', password || '');
            localStorage.setItem('save', 'true');
        } else {
            localStorage.clear();
        }
        userStore.getState().clearUserData();
        window.history.pushState('', '', '/');
    }
}

export interface UserState {
    initialized: boolean;
    isLoggedIn: boolean;
    performedLogin: boolean;
    driver?: DriverProfile;
    user?: UserDetails;
    dmUserCode?: string;
    loadNumber?: string;
    loadInfo?: LoadInfo;
    profilePictureUrl?: string;
    username: string;
    type: UserType;
    leasePurchase?: number;
    fetchUserData: () => Promise<void>;
    clearUserData: () => void;
    performLogin: (username: string) => void;
    reloadDriverProfile: () => Promise<void>;
}


export const userStore = create<UserState>((set) => ({
    initialized: false,
    isLoggedIn: AuthService.isLoggedIn(),
    performedLogin: false,
    driver: undefined,
    user: undefined,
    loadNumber: undefined,
    loadInfo: undefined,
    profilePictureUrl: '',
    leasePurchase: undefined,
    username: localStorage.getItem('username') || '',
    type: localStorage.getItem('type') as UserType,
    fetchUserData: async () => {
        if (!AuthService.isLoggedIn()) {
            set({initialized: true})
            return;
        }

        const type = localStorage.getItem('type') as UserType;

        const driver =  await ApiService.getDriverProfile('login');
        const promiseResult = await Promise.all([
            ApiService.getUser("login"),
            driver?.unit ? (await ApiService.getLoadDetails('login', {unit: driver.unit}).catch(() => {return undefined})) : Promise.resolve(undefined)
        ])
        const user = promiseResult[0] as UserDetails;
        const load = (promiseResult[1] as LoadDetails)?.load;
        const loadNumber = load?.loadNumber;

        set({
            driver,
            user,
            loadNumber: loadNumber,
            loadInfo: load,
            dmUserCode: user.dmcode,
            isLoggedIn: AuthService.isLoggedIn(),
            initialized: true,
            username: localStorage.getItem('username') || '',
            type,
            leasePurchase: driver.leasePurchase
        });
        localStorage.setItem('userId', user.id);
        localStorage.setItem('driverCode', driver.driverCode);
        localStorage.setItem('dmCode', driver.dmCode);
    },
    clearUserData: () => {
        set({isLoggedIn: false, driver: undefined, user: undefined, loadNumber: undefined, loadInfo: undefined, profilePictureUrl: '', username: '', type: undefined, leasePurchase: undefined});
    },
    performLogin: (username: string) => {
        set({performedLogin: true, initialized: false, username});
    },
    reloadDriverProfile: async () => {
        const driver = await ApiService.getDriverProfile("login");
        set({driver});
    }
}));
