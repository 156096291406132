import React, {useEffect, useState} from 'react';
import {IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import HelpDeskEntry from './HelpDeskEntry';
import scanIcon from '../imgs/work/qr-scan.png';
import {ApiService} from "../api/ApiService";
import {PlatformUtils} from "../utils/PlatformUtils";
import {Link} from "react-router-dom";

const HelpDesk: React.FC = () => {

    const [payrollFAQToggle, setPayrollFAQToggle] = useState(0);
    const [transfloFAQToggle, setTransfloFAQToggle] = useState(0);
    const [agentToggle, setAgentToggle] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [storeLink, setStoreLink] = useState('');
    const [FAQEntries, setFAQEntries] = useState<any[]>([]);

    useEffect(() => {
        ApiService.sendEventCommand('Helpdesk View', 'eventCheck', 'User viewed Helpdesk entries').then(() => {
        });
        ApiService.getFaqEntries("helpDesk", {isLp: 1}).then(it => setFAQEntries(it));
    }, []);

    const payrollToggle = () => {
      {payrollFAQToggle === 1 ? setPayrollFAQToggle(0) : setPayrollFAQToggle(1)}
    }
  
    const transfloToggle = () => {
      {transfloFAQToggle === 1 ? setTransfloFAQToggle(0) : setTransfloFAQToggle(1)}
    }
  
    const agentToggleHandler = () => {
      {agentToggle === 1 ? setAgentToggle(0) : setAgentToggle(1)}
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>FAQ</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={"You don't currently have the Transflo+ app installed. Would you like to download it from the app store?"}
                    message={"Reminder: when registering with Transflo+, please use WSXI as the Recipient ID."}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary'
                        },
                        {
                            text: 'Download',
                            handler: () => {
                                window.open(storeLink);
                            }
                        }
                    ]}
                />

        <IonItem lines="none" color="light"><div className="item-container" style={{textAlign: "center"}}><h1><strong>Frequently Asked Questions</strong></h1></div></IonItem> 
          <div className="question-box" onClick={payrollToggle}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p style={{ width: "80%"}}>Who do I reach out to if I have a payroll issue?</p>{payrollFAQToggle ? <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</div>
            <p style={{ fontSize: "small"}} id={payrollFAQToggle ? "expanded" : "collapsed"}><a href="/keycontacts">Click here</a> to speak with a member of our accounting team.</p>
          </div>
          <div className="question-box" onClick={transfloToggle}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p  style={{ width: "80%"}}>Can I be a trainer as a LP driver?</p><p>{transfloFAQToggle ?  <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</p></div>
            <p style={{ fontSize: "small"}} id={transfloFAQToggle ? "expanded" : "collapsed"}>Absolutely.  Training may be a great way for you to increase mileage and revenue in your business. Contact your agent for more information.</p>
          </div>
          <div className="question-box" onClick={agentToggleHandler}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}><p  style={{ width: "80%"}}>Who do I contact if I have an issue while my agent is out?</p><p>{agentToggle ?  <div className='letter-icon' style={{ backgroundColor: "#FF8575"}}><p>-</p></div>: <div className='letter-icon' style={{ backgroundColor: "#9975FF"}}><p>+</p></div>}</p></div>
            <p style={{ fontSize: "small"}} id={agentToggle ? "expanded" : "collapsed"}>There is someone here to assist with your business, 24/7.  If it’s late at night or on the weekend, send in a tablet message or give us a call and someone will assist you until your agent returns to work. <a href="/chainofcommand">Click here</a> for Chain of Contact.</p>
          </div>
        {FAQEntries.map(function(element){
          if (element.active !== 0) {
            let entry = 
            <HelpDeskEntry element={element}/>
            return entry
          }
        })}
            </IonContent>
        </IonPage>
    );
};

export default HelpDesk;
