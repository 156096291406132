import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import historyIcon from "../imgs/clock-history.png"
import historyActiveIcon from "../imgs/clock-history-active.png"

import {checkmarkCircle, closeCircle} from 'ionicons/icons';

//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

var moment = require('moment');

let mustWatch = false;

interface AdvanceHistory {
    date: string,
    amount: string,
    status: string
}

const PersonalAdvance: React.FC = () => {

    const [reason, setReason] = useState<string>();
    const [showHistory, setShowHistory] = useState(false)
    const [advanceAmount, setAdvanceAmount] = useState<number>();
    const [available, setAvailable] = useState<string>();
    const [showModal, setShowModal] = useState(mustWatch);
    const [advances, setAdvances] = useState<AdvanceHistory[]>([]);
    const [watchedVid, setWatchedVid] = useState(false);
    const [moneyAvailable, setMoneyAvailable] = useState(false);
    const [isDispatched, setIsDispatched] = useState(false);
    const [hasPaperwork, setHasPaperwork] = useState(false);
    const [owesWestern, setOwesWestern] = useState(false);
    const [videoLink, setVideoLink] = useState('');

    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }

    const app = initializeApp(firebaseConfig)

    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
    }

    useEffect(() => {
        ApiService.getVideosList('personalAdvance', {type: 'other'}).then(it => setVideoLink(it.find(video => video.title === 'Cash Advance Video')?.youtube!));
        if (advances?.length === 0) {
            ApiService.getCashAdvances('personalAdvance',).then(it => {
                setAdvances(it.map(advance => {
                    return {
                        date: moment(advance.date).format('M/D/YYYY'),
                        amount: advance.amount,
                        status: advance.status
                    }
                }));
            });
        }
        ApiService.getCashAdvanceEligibility('personalAdvance',).then(it => {
            setMoneyAvailable(parseFloat(it.available) > 0);
            setAvailable(it.available);
            setIsDispatched(!it.notDispatched);
            setHasPaperwork(!it.paperworkIncomplete);
            setOwesWestern(it.owing);
        });

        // Forced Video
        let last_watched = localStorage.getItem('lastwatchedadvance')
        if (last_watched) {
            // If true: the video has been watched in the last 24 hours
            if ((new Date().getTime() - new Date(last_watched).getTime()) < 86400000) {
                mustWatch = false
                return
            }
        }
        if (watchedVid) {
            let string = new Date()
            localStorage.setItem('lastwatchedadvance', string.toString())
        } else {
            mustWatch = true
            setShowModal(mustWatch)
        }
    }, [watchedVid]);

    async function submitRequest() {
        ApiService.sendEventCommand('Request Advance', 'eventCheck', 'User requested an advance');
        ApiService.submitCashAdvance('personalAdvance', {amount: advanceAmount!, comment: reason!}).then(() => {
            toastStore.getState().setSuccessToast('Your advance has been requested.');
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Personal Advance</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonModal isOpen={showModal} id="transparent">
                <div className="modal-div" onClick={() => setShowModal(false)}>
                    <div className="item-container" id="modal">
                        <p className='title'>Please watch this brief video before continuing</p>
                        <iframe style={{marginTop: "10px"}} src={"https://www.youtube.com/embed/" + videoLink} title="video" onEnded={function () {
                            mustWatch = false;
                            setWatchedVid(true);
                            setShowModal(false)
                        }}></iframe>

                    </div>
                </div>
            </IonModal>
            {<IonContent fullscreen color="light">
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='title' style={{fontSize: "x-large"}}>New Request</p>
                        {advances.length ? <p>Current Advance: ${advances[0].amount}<br/>Status: <span
                                style={{color: advances[0].status === "approved" ? "#2fd573" : advances[0].status === "pending" ? "#ffca22" : "#000"}}>{advances[0].status}</span></p> :
                            <p>You do not have any active requests.</p>}
                        {showHistory ? <div className="report-history">
                                <div style={{textAlign: "center", position: "relative"}} onClick={() => setShowHistory(!showHistory)}><img src={historyActiveIcon} alt="" style={{
                                    height: "min(5vw, 22px)",
                                    position: "absolute",
                                    left: "8px",
                                    top: "8px"
                                }}/>
                                    <p className="subtitle">Show Less</p></div>
                                <IonItem lines="none" color="white">
                                    <div className="item-container">
                                        <p style={{margin: 0}}>Request History</p>
                                    </div>
                                </IonItem>
                                {advances.map(function (adv, index) {

                                    return (
                                        <div key={index} className="item-container space-between">
                                            <div><p>Amount: ${adv.amount}</p><p className='subtitle'>Date: {adv.date}</p></div>
                                            <p style={{color: adv.status === "approved" ? "#2fd573" : adv.status === "pending" ? "#ffca22" : "#000"}}>{adv.status}</p></div>
                                    )

                                })}
                                <IonItem lines="none" color="white">
                                    <div className="item-container">
                                        <p className="subtile">Showing {advances.length} result(s).</p>
                                    </div>
                                </IonItem>
                            </div> :
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p className="subtitle" style={{margin: 0}}>See History</p>
                                <img src={historyIcon} alt="" style={{height: "2.5vh"}} onClick={() => setShowHistory(!showHistory)}/>
                            </div>
                        }
                    </div>
                </IonItem>
                <br/>
                <IonItem lines="none" color="light">
                    <p className='title' style={{fontSize: "large"}}>Am I Eligible for an advance?</p>
                </IonItem>
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        {moneyAvailable ? <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You have up to ${available} available for
                                advance.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
                        </div> : <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You have $0 available for advance.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
                        </div>}
                        {isDispatched ? <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are dispatched</strong><IonIcon
                                style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
                        </div> : <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are not dispatched</strong><IonIcon
                                style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
                        </div>}
                        {hasPaperwork ? <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are up to date on your paperwork.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
                        </div> : <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are not up to date on your paperwork.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
                        </div>}
                        {!owesWestern ? <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are not in the owing position.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
                        </div> : <div className='item-container'>
                            <div style={{display: 'flex', justifyContent: "space-between", color: "#a7a7a7", alignItems: "center"}}><strong>You are in the owing position.</strong><IonIcon
                                style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
                        </div>}

                        <div className="input-container">
                            <input disabled={owesWestern || !hasPaperwork || !available || !isDispatched} id="reason" className="input" type="text" placeholder=" " value={reason}
                                   onChange={e => setReason(e.target.value!)}/>
                            <p className="placeholder">{'Reason(optional)'}</p>
                        </div>
                        <div className="input-container">
                            <input disabled={owesWestern || !hasPaperwork || !available || !isDispatched} id="amount" className="input" type="number" placeholder=" " value={advanceAmount}
                                   onChange={e => setAdvanceAmount(parseFloat(e.target.value!))}/>
                            <p className="placeholder">{'Amount'}</p>
                        </div>
                        <div className='item-container'><p className='subtitle'>If approved, this advance will be deducted from your paycheck.</p></div>
                        <IonButton id="western-button-submit" disabled={owesWestern || !hasPaperwork || !available || !isDispatched} onClick={() => {
                            submitRequest();
                            handleEventLog("advance_PayRequest", "submit-PayRequest")
                        }}>Submit</IonButton>
                    </div>
                </IonItem>
            </IonContent>
            }
        </IonPage>
    );
};

export default PersonalAdvance;
