import React, {useState} from 'react'
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react'
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

function RegisterPlate() {

    const [number, setNumber] = useState('')
    const [confirming, setConfirming] = useState(false)

    const submitPlateInfo = () => {
        ApiService.vehicleRegister('registerPlate', {plateNumber: number.replace('-', '')}).then(() => {
            toastStore.getState().setSuccessToast('Your license plate is now registered. Thank you!');
        }).finally(() => {
            setConfirming(false);
            setNumber('');
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Register Vehicle</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="none" color="light">
                    <IonModal isOpen={confirming}>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <strong>Register: {number.replace('-', '')}</strong>
                                <p className='subtitle'>Is this correct?</p>
                                <button className="western-button submit" onClick={() => submitPlateInfo()}>Yes</button>
                                <br/>
                                <br/>
                                <button className="button" onClick={() => setConfirming(false)}>Cancel</button>
                            </div>
                        </IonItem>
                    </IonModal>
                    <div className='item-container'>
                        <strong>Register your license plate when you leave a vehicle at one the terminals.</strong>
                        <div className="input-container">
                            <input id="register" className="input" type="text" placeholder=" " value={number} onChange={e => setNumber(e.target.value!.toLocaleUpperCase())}/>
                            <p className="placeholder">License Plate #</p>
                        </div>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <button className="western-button submit" onClick={() => setConfirming(true)}>SUBMIT</button>
                            </div>
                        </IonItem>
                    </div>
                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default RegisterPlate;
