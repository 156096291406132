import React, {useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToggle, IonToolbar} from '@ionic/react';
import {alertCircleOutline, cameraOutline, trashOutline, warning} from 'ionicons/icons';
import {base64FromPath} from '@ionic/react-hooks/filesystem';
import {Camera, CameraResultType} from '@capacitor/camera';
import callIcon from "../imgs/call.png"
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {useHistory} from "react-router-dom";
import {DriverProfile} from "../api/model/DriverProfile";
import {UserState, userStore} from "../api/AuthService";
import {toastStore} from "../components/AppToast";
import {PhotoApiService} from "../api/PhotoApiService";
import {ApiService} from "../api/ApiService";

const timages: any[] = [];

interface Photo {
    filepath: string;
    webviewPath?: string;
    data: string;
}

const Breakdown: React.FC = () => {

    const history = useHistory();
    const {userInfo, loadNumber}: { userInfo: DriverProfile, loadNumber: string } = userStore((state: UserState) => ({userInfo: state.driver, loadNumber: state.loadNumber}));

    const [showModal1, setShowModal1] = useState(true);
    const [loading, setLoading] = useState(false);
    const [checked1, setChecked1] = useState(true);
    const [checked2, setChecked2] = useState(true);
    const [checked3, setChecked3] = useState(true);
    const [checked4, setChecked4] = useState(true);
    const [checked5, setChecked5] = useState(true);
    const [checked6, setChecked6] = useState(true);
    const [checked7, setChecked7] = useState(true);
    const [checked8, setChecked8] = useState(true);
    const [checked9, setChecked9] = useState(true);
    const [checked10, setChecked10] = useState(true);
    const [checked11, setChecked11] = useState(true);
    const [checked12, setChecked12] = useState(true);
    const [checked13, setChecked13] = useState(true);
    const [checked14, setChecked14] = useState(true);
    const [checked15, setChecked15] = useState(true);
    const [checked16, setChecked16] = useState(true);
    const [checked17, setChecked17] = useState(true);
    const [checked18, setChecked18] = useState(true);
    const [checked19, setChecked19] = useState(true);
    const [checked20, setChecked20] = useState(true);
    const [checked21, setChecked21] = useState(true);
    const [checked22, setChecked22] = useState(true);
    const [checked23, setChecked23] = useState(true);
    const [checked24, setChecked24] = useState(true);
    const [checked25, setChecked25] = useState(true);
    const [checked26, setChecked26] = useState(true);
    const [checked27, setChecked27] = useState(false);
    const [checked28, setChecked28] = useState(false);
    const [roadSideQuestion, setRoadSideQuestion] = useState(false);
    const [isRoadSide, setIsRoadSide] = useState(false);
    const [text, setText] = useState<string>();
    const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
    const [images, setImages] = useState<Photo[]>([]);
    const [trailerNumber, setTrailerNumber] = useState<string>(userInfo.trailer);
    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }

    const app = initializeApp(firebaseConfig)

    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
    }

    let array = [checked1, checked2, checked3, checked4, checked5, checked6, checked7, checked8, checked9, checked10, checked11, checked12, checked13, checked14, checked15, checked16, checked17, checked18, checked19, checked20, checked21, checked22, checked23, checked24, checked25, checked26, checked27, checked28]
    let problems = ["PM on current equipment", "Service Breaks", "Air Lines", "Parking Brakes", "Steering Mechanism", "Lighting and Reflectors", "Horn", "Windshield Wipers", "Rear Vision Mirrors", "Coupling Devices", "Wheels and Rims", "Emergency Equipment", "Fluid Level: Oil", "Fluid Level: Coolant", "Fluid Level: Fuel", "Fluid Level: Diesel Exhaust", "Steer: Left", "Steer: Right", "Drive: Front Axle Left", "Drive: Front Axle Right", "Drive: Rear Axle Left", "Drive: Rear Axle Right", "Trailer: Front Axle Left", "Trailer: Front Axle Right", "Trailer: Rear Axle Left", "Trailer: Rear Axle Right", "Issue with Tractor", "Issue with Trailer"]

    function getIssues() {
        let issues: any = {}
        for (let i = 0; i < array.length; i++) {
            if (problems[i] === "Issue with Tractor" || problems[i] === "Issue with Trailer") {
                issues[problems[i]] = array[i]
            } else {
                issues[problems[i]] = !array[i]
            }
        }
        issues.comments = text
        return issues
    }

    const submitPhotos = (inspectionId: number) => {
        if (!images?.length) {
            submitTrailerReport();
        }

        const refId = inspectionId.toString();
        ApiService.photoSubmission('breakdown', {
            type: 'breakdown',
            refId,
            dmCode: userInfo.dmCode,
            division: userInfo.division,
            unit: userInfo.unit,
            trailer: userInfo.trailer,
            load: loadNumber
        }).then(() => {
            PhotoApiService.uploadPhotos(images, refId, 'breakdown', true).then(() => {
                if (checked28) {
                    submitTrailerReport();
                } else {
                    toastStore.getState().setSuccessToast('Your breakdown was submitted.');
                    history.push('/');
                }
            }).catch(() => {
                toastStore.getState().setToast('Photo upload error.');
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const submitTrailerReport = () => {
        ApiService.trailerReject('breakdown', {trailerNumber: trailerNumber, rejectionType: 'breakdown', comment: (text || 'N/A'), unitNumber: userInfo.unit}).then(() => {
            toastStore.getState().setSuccessToast('Your breakdown was submitted.');
        }).finally(() => {
            setLoading(false);
        });
    }

    const startSubmission = (id: string) => {
        if (checked28 && trailerNumber === '') {
            toastStore.getState().setToast('Please enter a trailer number');
            setLoading(false);
            return;
        }

        ApiService.inboxSendMessage('breakdown', {
            id: 60,
            toUserId: id,
            body: 'Your breakdown event has been reported. The maintenance team will contact you shortly.',
            subject: 'breakdown',
            type: 'maintenance'
        }).then(it => {
            submitReport(it.conversationId);
        });
    }

    const submitReport = (conversationId: number) => {
        let reportData = getIssues();
        reportData.conversationId = conversationId;
        reportData.isRoadSide = isRoadSide;
        reportData = JSON.stringify(reportData);
        let unit = userInfo.unit;
        let trailer = userInfo.trailer;
        let type = "breakdown"
        let division = userInfo.division;
        localStorage.setItem('report', reportData);
        let photo = images.length;

        ApiService.inspectionSubmission('breakdown', {
            dmCode: userStore.getState().driver?.dmCode, unit: unit, trailer: trailer, division: division, reportData: reportData, load: loadNumber, type: type, photos: photo
        }).then(it => {
            submitPhotos(it);
        }).catch(() => setLoading(false));
    }

    async function takePicture() {
        const image = await Camera.getPhoto({
            quality: 30,
            // source: CameraSource.Prompt,
            allowEditing: false,
            resultType: CameraResultType.Uri
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        const fileName = new Date().getTime() + '.jpeg';
        const base64Data = await base64FromPath(image.webPath!);
        const newPhotos = [{
            filepath: fileName,
            webviewPath: image.webPath,
            data: base64Data
        }, ...images];
        setImages(newPhotos)
        timages.push(image)
        if (timages.length > 0) {
            setPhotoMsg("Take another photo")
        }
    }

    const removePhoto = (e: any) => {
        const name = e.filepath;
        setImages(images.filter(image => image.filepath !== name));
    }

    const handleSubmit = () => {
        setLoading(true);
        startSubmission(userStore.getState().user!.id);
        if (trailerNumber === '') {
            setTrailerNumber(userInfo.trailer);
        }
    }

    const handleTrailerSelect = () => {
        if (trailerNumber === '') {
            setTrailerNumber(userInfo.trailer)
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Breakdown Reporting</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonModal isOpen={loading} id="transparent">
                    <div className="modal-div">
                        <div id="modal">
                            <div className='loading-div'>
                                <p>Please wait... your breakdown is being submitted.</p>
                                <div className="snippet" data-title=".dot-flashing" style={{margin: "4px 16px"}}>
                                    <div className="stage">
                                        <div className="dot-flashing"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonModal>
                <IonModal isOpen={showModal1} cssClass='my-custom-class'>
                    {!roadSideQuestion ? <IonContent>
                            <div className='main'>

                                <div>
                                    <IonItem lines="none">
                                        <div className='item-container'>
                                            <h1 className='title' style={{color: "#ff0000"}}>Important Questions about the Incident</h1>
                                            <p className='subtitle'>Follow the steps below in order.</p>
                                        </div>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <div className='item-container'>
                                            <ol>
                                                <li>Did the incident involve an <b>INJURY</b> to anyone?</li>
                                                <li>Will any vehicles involved in the incident require a <b>TOW?</b></li>
                                                <li>Were there any human <b>FATALITIES</b> as a result of the incident?</li>
                                                <li>Will there be a <b>CITATION</b> issued to the Western Express driver?</li>
                                                <li>Were there any <b>FUEL SPILLS</b> from the Western Express vehicle?</li>
                                                <li>Was any Western Express property or cargo <b>STOLEN?</b></li>
                                                <li>Was there a <b>BUS</b> with passengers involved in the incident?</li>
                                            </ol>
                                        </div>
                                    </IonItem>

                                    <IonItem lines="none">
                                        <div className='item-container'>
                                            <p> If the answer to ANY of these questions is "Yes", call the Accident Hotline immediately (877) 459-3604</p>
                                            <a className="western-button-link" href="tel:877-459-3604" onClick={() => handleEventLog("accident_Hotline", "contact-AccidentHotline")}>
                                                <div>
                                                    <img src={callIcon} alt="key" style={{height: "3vh", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call Accident
                                                    Hotline</p>
                                                </div>
                                            </a>
                                        </div>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <div className='item-container'>
                                            <p className='subtitle'>If you are issued a traffic citation after an accident you must call it in immediately.<br/>Call the accident hotline back to provide
                                                details.</p>
                                        </div>
                                    </IonItem>
                                    <IonItem lines="none">
                                        <div className='item-container'>
                                            <div className="space-between">
                                                <p style={{width: "80%"}}>Failure to report an accident immediately is grounds for termination.</p>
                                                <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                            </div>
                                            <div className="space-between">
                                                <p style={{width: "80%"}}>Failure to report a citation issued as a result of an accident immediately is grounds for termination.</p>
                                                <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                            </div>
                                            <div className="space-between">
                                                <p style={{width: "80%"}}>Any DOT post-accident drug and alcohol test which is missed due to a driver's failure to report or reporting late will be
                                                    considered a refusal.</p>
                                                <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                            </div>
                                        </div>
                                    </IonItem>
                                </div>
                            </div>
                            <IonButton id="western-button-submit" expand="block" onClick={function () {
                                setRoadSideQuestion(true)
                            }}>None of these apply</IonButton>
                        </IonContent> :
                        <IonContent>
                            <IonItem lines="none">
                                <div className='item-container'>
                                    <h1 className='title' style={{color: "#ff0000"}}>Important Questions about the Incident</h1>
                                    <p className='subtitle'>Are you currently Roadside?</p>
                                </div>
                            </IonItem>
                            <div style={{display: 'flex'}}>
                                <IonItem lines="none">
                                    <div className='item-container'>
                                        <button className="button" onClick={function () {
                                            setShowModal1(false);
                                            setRoadSideQuestion(false);
                                            setIsRoadSide(true)
                                        }}>Yes
                                        </button>
                                    </div>
                                </IonItem>
                                <IonItem lines="none">
                                    <div className='item-container'>
                                        <button className="button" onClick={function () {
                                            setShowModal1(false);
                                            setRoadSideQuestion(false);
                                            setIsRoadSide(false)
                                        }}>No
                                        </button>
                                    </div>
                                </IonItem>
                            </div>
                        </IonContent>}
                </IonModal>
                <IonList>
                    <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <p>Tractor Number: {userInfo.unit}</p>
                            <p className='subtitle'>Please enter the number of the trailer you're reporting. If listed please confirm by entering again.</p>
                            <div className="input-container">
                                <input id="trailer" className="input" type="text" placeholder="" value={trailerNumber} onChange={e => setTrailerNumber(e.target.value!)}/>
                                <p className="placeholder">Trailer Number: {userInfo.trailer}</p>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <p>Inspection Areas: If anything has an issue, it should NOT be green.</p>
                        </div>
                    </IonItem>
                    <div className="container">
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Tractor</p>
                                <IonToggle checked={checked27} onIonChange={e => setChecked27(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Trailer</p>
                                <IonToggle checked={checked28} onIonChange={e => {
                                    handleTrailerSelect();
                                    setChecked28(e.detail.checked)
                                }} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>PM current on equipment</p>
                                <IonToggle checked={checked1} onIonChange={e => setChecked1(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Service Brakes</p>
                                <IonToggle checked={checked2} onIonChange={e => setChecked2(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Air Lines</p>
                                <IonToggle checked={checked3} onIonChange={e => setChecked3(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Parking Brakes</p>
                                <IonToggle checked={checked4} onIonChange={e => setChecked4(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steering Mechanism</p>
                                <IonToggle checked={checked5} onIonChange={e => setChecked5(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Lighting and Reflectors</p>
                                <IonToggle checked={checked6} onIonChange={e => setChecked6(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Horn</p>
                                <IonToggle checked={checked7} onIonChange={e => setChecked7(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Windshield Wipers</p>
                                <IonToggle checked={checked8} onIonChange={e => setChecked8(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Rear Vision Mirrors</p>
                                <IonToggle checked={checked9} onIonChange={e => setChecked9(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Coupling Devices</p>
                                <IonToggle checked={checked10} onIonChange={e => setChecked10(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Wheels and Rims</p>
                                <IonToggle checked={checked11} onIonChange={e => setChecked11(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Emergency Equipment</p>
                                <IonToggle checked={checked12} onIonChange={e => setChecked12(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Oil</p>
                                <IonToggle checked={checked13} onIonChange={e => setChecked13(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Coolant</p>
                                <IonToggle checked={checked14} onIonChange={e => setChecked14(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Fuel</p>
                                <IonToggle checked={checked15} onIonChange={e => setChecked15(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Fluid Level: Diesel Exhaust</p>
                                <IonToggle checked={checked16} onIonChange={e => setChecked16(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steer: Left</p>
                                <IonToggle checked={checked17} onIonChange={e => setChecked17(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Steer: Right</p>
                                <IonToggle checked={checked18} onIonChange={e => setChecked18(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Front Axle Left</p>
                                <IonToggle checked={checked19} onIonChange={e => setChecked19(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Front Axle Right</p>
                                <IonToggle checked={checked20} onIonChange={e => setChecked20(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Rear Axle Left</p>
                                <IonToggle checked={checked21} onIonChange={e => setChecked21(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Drive: Rear Axle Right</p>
                                <IonToggle checked={checked22} onIonChange={e => setChecked22(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Trailer: Front Axle Left</p>
                                <IonToggle checked={checked23} onIonChange={e => setChecked23(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p>Trailer: Front Axle Right</p>
                                <IonToggle checked={checked24} onIonChange={e => setChecked24(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p> Trailer: Rear AxleLeft</p>
                                <IonToggle checked={checked25} onIonChange={e => setChecked25(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container' style={{display: "flex", alignContent: "center", justifyContent: "space-between"}}>
                                <p> Trailer: Rear Axle Right</p>
                                <IonToggle checked={checked26} onIonChange={e => setChecked26(e.detail.checked)} color="success"/>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <p>Other Issues:</p>
                                <p className='subtitle'>Please provide details on issues not listed above.</p>
                                <div className="input-container">
                                    <input id="issues" className="input" type="text" placeholder="" value={text} onChange={e => setText(e.target.value!)}/>
                                    <p className="placeholder">Comments</p>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <button className='button' onClick={() => {
                                    takePicture();
                                    handleEventLog("breakdown_report", "submit-Photo")
                                }}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
                                {images.map((img, index) => {
                                    return (
                                        <div style={{position: 'relative'}} onClick={() => removePhoto(img)} key={index}>
                                            <img alt="breakdown img" src={img.webviewPath} style={{width: "100px", borderRadius: "10px"}}/>
                                            <button style={{backgroundColor: "rgba(0,0,0,0)", borderRadius: "50%", width: "32px", height: "32px", position: "absolute", top: "0", left: "0"}}><IonIcon
                                                style={{color: "#ff0000", fontSize: "large"}} icon={trashOutline}></IonIcon></button>
                                        </div>
                                    )
                                })}
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <div className="space-between">
                                    <p style={{width: "80%"}} className="subtitle">Per FMCSR 396.11, the driver must complete this inspection at the end of each work day. If a defect is found, it must
                                        be reported and repaired prior to continuing on or beginning the next trip.</p>
                                    <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem lines="none" color="light">
                            <div className='item-container'>
                                <div className="space-between">
                                    <p style={{width: "80%"}} className="subtitle">Legal Agreement. I approve the above information as complete and correct and certify that I am the driver identified
                                        above. I further acknowledge that and consent to the use of this submission as an electronic signature in accordance with 49 CFR § 390.32 and 15.U.S.C. §
                                        7001(c).</p>
                                    <IonIcon icon={alertCircleOutline} style={{color: "#ff0000", fontSize: "24px"}}></IonIcon>
                                </div>
                            </div>
                        </IonItem>
                        <IonItem color="light" lines="none">
                            <div className='item-container'>
                                <IonButton id="western-button-submit" expand="block" onClick={() => {
                                    handleSubmit();
                                    handleEventLog("breakdown_report", "submit-Report")
                                }}>Submit</IonButton>
                            </div>
                        </IonItem>
                    </div>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Breakdown;
