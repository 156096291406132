import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import './drive.css'
import {IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar,} from '@ionic/react'
import {config} from '../Constants'
import {chevronBackOutline, chevronForward} from 'ionicons/icons'
import AnimateHeight from 'react-animate-height'

//  ICONS
import calendar from '../imgs/drive/calendar.png'
import maps from '../imgs/drive/maps.png'
import payroll from '../imgs/drive/payroll.png'
import salary from '../imgs/drive/salary.png'
import search from '../imgs/drive/search.png'
import clipboard from '../imgs/drive/clipboard.png'
import trailer from '../imgs/drive/trailer.png'
import tarp from '../imgs/drive/tarp.png'
import Nav from './Nav'
import {ApiService} from "../api/ApiService";

const Drive: React.FC = () => {

    const history = useHistory();
    const [height1, setHeight1] = useState(0)
    const [height2, setHeight2] = useState(0)
    const [height3, setHeight3] = useState(0)
    const [scarcityMode, setScarcityMode] = useState('0');

    const buttonArray = ['load', 'trip', 'money', 'breakdown']

    useEffect(() => {
        ApiService.getUserState('Load', {name: 'scarcityMode', userId: 0}).then(it => setScarcityMode(it.value));
    }, []);

    const toggle = () => {
        if (height1 === 0) {
            activeButton('load')
            setHeight1(1)
            setHeight2(0)
            setHeight3(0)
        } else {
            setHeight1(0)
            document.getElementById('load')?.setAttribute('style', '')
        }
    }

    const toggle2 = () => {
        if (height2 === 0) {
            activeButton('trip')
            setHeight2(1)
            setHeight1(0)
            setHeight3(0)
        } else {
            setHeight2(0)
            document.getElementById('trip')?.setAttribute('style', '')
        }
    }

    const toggle3 = () => {
        if (height3 === 0) {
            activeButton('money')
            setHeight3(1)
            setHeight2(0)
            setHeight1(0)
        } else {
            setHeight3(0)
            document.getElementById('money')?.setAttribute('style', '')
        }
    }

    function activeButton(id: string) {
        document.getElementById(id)?.setAttribute('style', 'border: 4px solid #99cc00 !important; border-radius: 11px')
        for (let i = 0; i < buttonArray.length; i++) {
            if (buttonArray[i] !== id) {
                document.getElementById(buttonArray[i])?.setAttribute('style', '')
            }
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Drive</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent id=' ' fullscreen>
                <div className={config.isPlatformSci ? "home-div-plat-sci" : "home-div"}>
                    <div className="menu-button" style={{background: '#ffffff'}}>
                        <IonButton className="home-menu-item" color="white" onClick={toggle} aria-expanded={height1 !== 0}>
                            <div id="btn-text-menu">Load</div>
                            <div className={height1 !== 0 ? "fa fa-arrow-down open" : "fa fa-arrow-down"}><IonIcon slot="icon-only" icon={chevronBackOutline}/></div>
                        </IonButton>
                        <AnimateHeight style={{width: '100vw', overflowY: 'scroll', background: "#fff"}} duration={500} height={height1 === 1 ? 'auto' : 0} id="loadSection">
                            <IonButton className="home-menu-item" color="white" href={scarcityMode ? "/preplanned" : "/loadselection"}>
                                <div className="menu-item-icon"><img alt="" src={calendar}/></div>
                                <div id="btn-text-submenu">{scarcityMode ? "Preplanned" : "Load Plan"}</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="white" href="/currentload">
                                <div className="menu-item-icon"><img alt="" src={trailer}/></div>
                                <div id="btn-text-submenu">Current Load</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="white" href="/loadhistory">
                                <div className="menu-item-icon"><img alt="" src={search}/></div>
                                <div id="btn-text-submenu">Load History</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                        </AnimateHeight>
                        <IonButton className="home-menu-item" color="white" onClick={toggle2} aria-expanded={height2 !== 0}>
                            <div id="btn-text-menu">Trip</div>
                            <div className={height2 !== 0 ? "fa fa-arrow-down open" : "fa fa-arrow-down"}><IonIcon slot="icon-only" icon={chevronBackOutline}/></div>
                        </IonButton>
                        <AnimateHeight style={{width: '100vw', overflowY: 'scroll', background: "#fff"}} duration={500} height={height2 === 1 ? 'auto' : 0} id="loadSection">
                            <IonButton className="home-menu-item" color="white" href="/pretrip">
                                <div className="menu-item-icon"><img alt="" src={clipboard}/></div>
                                <div id="btn-text-submenu">Pre-Trip</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="white" href="/photoupload">
                                <div className="menu-item-icon"><img alt="" src={tarp}/></div>
                                <div id="btn-text-submenu">Tarp Pay</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="white" href="/trailertracking">
                                <div className="menu-item-icon"><img alt="" src={maps}/></div>
                                <div id="btn-text-submenu">Trailer Request</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                        </AnimateHeight>
                    </div>
                    <div className='menu-button' style={{background: '#ffffff'}}>
                        <IonButton
                            className='home-menu-item'
                            color='white'
                            strong
                            fill='solid'
                            onClick={toggle3}
                            aria-expanded={height3 !== 0}
                        >
                            <div id='btn-text-menu'>Money</div>
                            <div className={height3 !== 0 ? 'fa fa-arrow-down open' : 'fa fa-arrow-down'}>
                                <IonIcon slot='icon-only' icon={chevronBackOutline}/>
                            </div>
                        </IonButton>
                        <AnimateHeight
                            style={{width: '100vw', overflowY: 'scroll', background: '#fff'}}
                            duration={500}
                            height={height3 === 1 ? 'auto' : 0}
                            id='loadSection'
                        >
                            <IonButton className='home-menu-item' color='white' href='/Drive/payroll'>
                                <div className='menu-item-icon'>
                                    <img alt='' src={payroll}/>
                                </div>
                                <div id='btn-text-submenu'>Payroll</div>
                                <div className='btn-menu-arrow'>
                                    <IonIcon style={{color: 'black'}} slot='icon-only' icon={chevronForward}/>
                                </div>
                            </IonButton>
                            <IonButton className='home-menu-item' color='white' href='/Drive/deferment'>
                                <div className='menu-item-icon'>
                                    <img alt='' src={salary}/>
                                </div>
                                <div id='btn-text-submenu'>Deferment</div>
                                <div className='btn-menu-arrow'>
                                    <IonIcon style={{color: 'black'}} slot='icon-only' icon={chevronForward}/>
                                </div>
                            </IonButton>
                        </AnimateHeight>
                        <IonButton className='home-menu-item' color='white' strong fill='solid' href='/Drive/breakdown'>
                            <div id='btn-text-menu'>Maintenance Request</div>
                        </IonButton>
                    </div>
                </div>
            </IonContent>
            <IonFooter>
                <Nav onSearch={() => history.push('/home?search=true')}/>
            </IonFooter>
        </IonPage>
    )
}

export default Drive;
