import React, { useState, useEffect } from "react"
import icon from "../imgs/search.png"
import inactive from "../imgs/search_inactive.png"

type Props = {
    onChange: (target: string) => void,
    featured: string
}

const Search = ({ onChange, featured }: Props) => {
    const [text, setText] = useState("")

     useEffect(() => {
        const input = document.querySelector("input")
        input?.focus()
     }, [])
    function handleSearch (value: string) {
        setText(value)
        onChange(value)
    } 
    return ( 
        <div  className="searchoo" style={{ backgroundColor: "transparent", padding: "32px 16px 16px 16px"}}>
            <div className="item-container"><h1 style={{ margin: '0', fontWeight: '500', fontFamily: 'Montserrat, sans-serif', color: "#ffffff"}}>{featured}</h1></div>
            <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#ffffff", alignItems: "center", borderRadius: "10px"}}><input style={{ backgroundColor: "#ffffff", borderRadius: "10px", border: "none", outline: "none", color: "black", fontFamily: "Montserrat, sans-serif", fontWeight: 500, padding: "16px", width: "90%"}} type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value!)}/><img src={text ? icon : inactive} style={{ height: 'min(6vw, 32px)', padding: "0 8px"}} alt="icon"/></div>
        </div>
    )
}

export default Search