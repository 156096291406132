import React, {useState} from 'react';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import {config} from '../Constants'
import {chevronForward} from 'ionicons/icons';
import Header from './Header';
import AnimateHeight from 'react-animate-height';

//  ICONS
import accident from '../imgs/help/accident.png';
import caution from '../imgs/help/caution.png';
import contactCard from '../imgs/help/contact-card.png';
import documents from '../imgs/help/documents.png';
import group from '../imgs/help/group.png';
import localization from '../imgs/help/localization.png';
import shield from '../imgs/help/shield.png';
import technicalSupport from '../imgs/help/technical-support.png';
import welfare from '../imgs/help/welfare.png';
import wrench from '../imgs/help/wrench.png';
import cone from '../imgs/help/traffic-cone.png';

//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app"


const Help: React.FC = () => {

    const [height1, setHeight1] = useState(0);
    const [height2, setHeight2] = useState(0);

    const buttonArray = ['contacts', 'docs'];

    //Connection Param. Google Analytics:
    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }
    //Initialization:
    const app = initializeApp(firebaseConfig)

    //Event Handler:
    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
    }

    const toggle = () => {
        if (height1 === 0) {
            activeButton('contacts');
            setHeight1(1);
            setHeight2(0)
        } else {
            setHeight1(0);
            document.getElementById('contacts')?.setAttribute('style', '');
        }
    }

    const toggle2 = () => {
        if (height2 === 0) {
            activeButton('docs');
            setHeight2(1);
            setHeight1(0)
        } else {
            setHeight2(0);
            document.getElementById('docs')?.setAttribute('style', '');
        }
    }

    function activeButton(id: string) {
        document.getElementById(id)?.setAttribute('style', 'border: 4px solid #99cc00 !important; border-radius: 11px');
        for (let i = 0; i < buttonArray.length; i++) {
            if (buttonArray[i] !== id) {
                document.getElementById(buttonArray[i])?.setAttribute('style', '');
            }
        }
    }

    return (
        <IonPage>
            <Header title="Help" titleStyle={{textAlign: 'end'}}/>
            <IonContent id="home" fullscreen>

                <div className={config.isPlatformSci ? "home-div-plat-sci" : "home-div"}>
                    <div className="menu-button">
                        <IonButton id="contacts" className="home-btn" strong fill="solid" onClick={toggle} aria-expanded={height1 !== 0}>
                            <div id="btn-text-menu">Contacts</div>
                            <div className="menu-icon"><img alt="" src={contactCard}/></div>
                        </IonButton>
                        <IonButton className="home-btn" strong fill="solid" href="/Help/servicelocations">
                            <div id="btn-text-menu" onClick={() => handleEventLog("service_Locations", "access-ServiceLocations")}>Service Locations</div>
                            <div className="menu-icon"><img alt="" src={localization}/></div>
                        </IonButton>
                        <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height1 === 1 ? 'auto' : 0} id="loadSection">
                            <IonButton className="home-menu-item" color="transparent" href="/Help/keycontacts" onClick={() => handleEventLog("contacts", "access-KeyContacts")}>
                                <div className="menu-item-icon"><img alt="" src={caution}/></div>
                                <div id="btn-text-submenu">Key Contacts</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="transparent" href="/Help/safety/contacts" onClick={() => handleEventLog("contacts", "access-SafetyContacts")}>
                                <div className="menu-item-icon"><img alt="" src={cone}/></div>
                                <div id="btn-text-submenu">Safety</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="transparent" href="/Help/chainofcommand" onClick={() => handleEventLog("contacts", "access-ChainOfCommand")}>
                                <div className="menu-item-icon"><img alt="" src={group}/></div>
                                <div id="btn-text-submenu">Chain of Contact</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="transparent" href="/Help/helpdesk" onClick={() => handleEventLog("contacts", "access-Helpdesk")}>
                                <div className="menu-item-icon"><img alt="" src={technicalSupport}/></div>
                                <div id="btn-text-submenu">Helpdesk</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                        </AnimateHeight>
                    </div>
                    <div className="menu-button">
                        <IonButton id='docs' className="home-btn" strong fill="solid" onClick={toggle2} aria-expanded={height2 !== 0}>
                            <div id="btn-text-menu">Docs & Videos</div>
                            <div className="menu-icon"><img alt="" src={documents}/></div>
                        </IonButton>
                        <IonButton className="home-btn" strong fill="solid" href="/Help/accidentinfo" onClick={() => handleEventLog("accidents", "access-AccidentInfo")}>
                            <div id="btn-text-menu">Accidents</div>
                            <div className="menu-icon"><img alt="" src={accident}/></div>
                        </IonButton>
                        <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height2 === 1 ? 'auto' : 0} id="loadSection">
                            <IonButton className="home-menu-item" color="transparent" href="/Help/safety" onClick={() => handleEventLog("docs", "access-SafetyDocs")}>
                                <div className="menu-item-icon"><img alt="" src={shield}/></div>
                                <div id="btn-text-submenu">Safety</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="transparent" href="/Help/maintenancevids" onClick={() => handleEventLog("docs", "access-MaintenanceVids")}>
                                <div className="menu-item-icon"><img alt="" src={wrench}/></div>
                                <div id="btn-text-submenu">Maintenance</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                            <IonButton className="home-menu-item" color="transparent" href="/Help/benefits" onClick={() => handleEventLog("docs", "access-Benefits")}>
                                <div className="menu-item-icon"><img alt="" src={welfare}/></div>
                                <div id="btn-text-submenu">Benefits</div>
                                <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                            </IonButton>
                        </AnimateHeight>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Help;
