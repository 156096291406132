import React from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
//Google Analytics:
import ContactCard from './ContactCard';


const ServiceLocations: React.FC = () => {

    const locations = [
        {
            name: "Nashville, TN",
            email: "7135 Centennial Pl Nashville, TN 37209",
            phone: "615-259-9920",
            subcategory: "Hub",
            directions: "I-40 WEST TO EXIT 204A FOR TN HWY 155 NORTH/BRILEY PKWY/TAKE X.26B-CENTENNIAL BLVD/2ND SL TR THEN NEXT LEFT/WESTERN ON LHS. FROM I24/I65 TAKE BRILEY PKWY WEST TO X.26 THEN 1ST SL TR/TAKE NEXT LEFT TO JOHN C.TUNE AIRPORT & WESTERN IS ON LEFT HAND SIDE"
        },
        {
            name: "Laredo, TX",
            email: "1669 Word Trade Center Loop Laredo, TX 78045",
            phone: "",
            subcategory: "Hub",
            directions: ""
        },
        {
            name: "Bloomington, CA",
            email: "18244 Valley Blvd Bloomington, CA 92316",
            phone: "866-726-8509",
            subcategory: "Service Center",
            directions: "TAKE I 10 EAST TO CHERRY ST EXIT, GO SOUTH TO SLOVER, GO LEFT ON SLOVER TO ELM AVE, NEW OFFICE IS ON THE CORNER OF ELM/SLOVER. AS SOON AS YOU PASS ELM OUR DRIVEWAY IS ON THE RIGHT TO TURN INTO THE YARD"
        },
        {
            name: "Richmond, VA",
            email: "5600 Midlothian Turnpike Richmond, VA 23225",
            phone: "",
            subcategory: "Service Center",
            directions: ""
        },
        {
            name: "Bethlehem, PA",
            email: "2925 Schoenersville Road Bethlehem, PA 18017",
            phone: "877-369-0289",
            subcategory: "Service Center",
            directions: ""
        },
        {
            name: "Fort Dodge, IA",
            email: "2039 Quail Ave Fort Dodge, IA 50501",
            phone: "800-654-0164",
            subcategory: "Operation Center",
            directions: "FROM US 20 EAST OR WEST TAKE COUNTY ROAD P59 (EXIT 124) NORTH AROUND 2 CURVES, OVER TRACKS, ON THE LEFT (WEST SIDE) 3 MILES NORTH."
        }
    ]

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Support" text=""/>
                    </IonButtons>
                    <IonTitle>Service Locations</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                {locations.map((location, index) => {
                    return (
                        <div>
                            <IonItem lines="none" color="light" key={index}><ContactCard contact={location} location={true}/></IonItem>
                        </div>
                    )
                })}
            </IonContent>
        </IonPage>
    );
};

export default ServiceLocations;
