import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonIcon, IonButtons, IonBackButton } from '@ionic/react';

import { call } from 'ionicons/icons';

const ServiceLocationsTN: React.FC = () => {
  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Help/servicelocations" />
          </IonButtons>
          <IonTitle>Service Location: TN</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <iframe title="TN Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-C99R1wtlYAZdY8SxrbY_te_Exs7Tfus&q=7135+Centennial+Place,Nashville+TN">
          </iframe>
          <IonCard>
            <IonCardContent>
              <h1>7135 Centennial Place</h1>
              <h2>Nashville, TN 37209</h2>
              <IonButton id="western-button" href="tel:615-259-9920" expand="block" >
                <IonIcon slot="start" icon={call}></IonIcon>
                 615-259-9920
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Directions</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              I-40 WEST TO EXIT 204A FOR TN HWY 155 NORTH/BRILEY PKWY/TAKE X.26B-CENTENNIAL BLVD/2ND SL TR THEN NEXT LEFT/WESTERN ON LHS. FROM I24/I65 TAKE BRILEY PKWY WEST TO X.26 THEN 1ST SL TR/TAKE NEXT LEFT TO JOHN C.TUNE AIRPORT & WESTERN IS ON LEFT HAND SIDE
            </IonCardContent>
            </IonCard>
      
      </IonContent>
    </IonPage>
  );
};

export default ServiceLocationsTN;
