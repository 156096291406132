import React, {createRef, useState} from "react"
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonItemGroup, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import {PDFDocument} from 'pdf-lib'
import SignaturePad from "react-signature-canvas"
import ReactSignatureCanvas from "react-signature-canvas"
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";

const NewDriverPacket = () => {

    const history = useHistory();
    const [lessee, setLessee] = useState<any>("");
    const [lesseeAddress, setLesseeAddress] = useState<any>("");
    const [lesseeSignature, setLesseeSignature] = useState<any>("");
    const [lesseeTitle, setLesseeTitle] = useState<any>("");
    const [showLesseeSignature, setShowLesseeSignature] = useState(true);
    const lesseeSigCanvas = createRef<ReactSignatureCanvas>()

    const save = () => {
        const signed = lesseeSigCanvas.current!.toDataURL()
        setLesseeSignature(signed)
        setShowLesseeSignature(!showLesseeSignature)
    }

    const clear = () => lesseeSigCanvas.current!.clear()

    const submit = async () => {
        const formURL = "./assets/deferralAgreement.pdf"
        const pdfFormBytes = await fetch(formURL).then((res) => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdfFormBytes)
        const form = pdfDoc.getForm()
        var lesseeField = form.getTextField('Lessee');
        var lesseeAddressField = form.getTextField('Lessee Address');
        var lessorTitleField = form.getTextField('Lessee Title');

        lesseeField.setText(lessee)
        lesseeAddressField.setText(lesseeAddress)
        lessorTitleField.setText(lesseeTitle)
        const lesseeSignatureBytes = await fetch(lesseeSignature).then((res) => res.arrayBuffer())
        const lesseeImageSignature = await pdfDoc.embedPng(lesseeSignatureBytes)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const pngDims = lesseeImageSignature.scale(0.2)
        firstPage.drawImage(lesseeImageSignature, {
            x: firstPage.getWidth() / 2 - pngDims.width / 2 + 65,
            y: firstPage.getHeight() / 2 - pngDims.height / 2 - 150,
            width: pngDims.width,
            height: pngDims.height,
        })

        const usefulData = await pdfDoc.saveAsBase64({dataUri: true})
        // fillForm(usefulData)
    }

    // async function fillForm(data: string) {
    //     ApiService.sendFileEmail('newDriverPacket', {
    //         email: 'emorrison@westernexp.com',
    //         subject: 'Passenger Authorization Form',
    //         filename: 'passenger insurance ' + lessee + '.pdf',
    //         fileData: data.split('base64,')[1]
    //     }).then(() => {
    //         ApiService.sendEventCommand('New Driver Packet', 'eventCheck', 'User submitted a passenger form')
    //     }).then(() => {
    //         toastStore.getState().setSuccessToast('Success. Your form was submitted.');
    //         history.push('/');
    //     });
    // }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>New Driver Packet</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen style={{margin: "8px 15px"}}>

                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <p style={{textAlign: "center", fontSize: "18px"}}>New Driver Packet</p>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <div className="input-container">
                            <input id="name" className="input" type="text" placeholder=" " value={lessee} onChange={e => setLessee(e.target.value!)}/>
                            <p className="placeholder">Full Name</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <div className="input-container">
                            <input id="name" className="input" type="text" placeholder=" " value={lesseeAddress} onChange={e => setLesseeAddress(e.target.value!)}/>
                            <p className="placeholder">Intials</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className="item-container">
                        <div className="input-container">
                            <input id="orientation" className="input" type="text" placeholder=" " value={lesseeTitle} onChange={e => setLesseeTitle(e.target.value!)}/>
                            <p className="placeholder">Lessee Title</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem color="light" lines="none">
                    <div className="item-container" style={{lineHeight: "20px"}}>
                        <p>I, {lessee === "" ? "___________" : <span style={{textDecoration: "underline"}}>{lessee}</span>} , ("Contractor") understand that I have entered into a Contract Hauling
                            Agreement as an independent contractor doing business with Western Express. As an independent contractor, I am required to provide proof of insurance. My Contract Hauling
                            Agreement with Western Express outlines minimal coverage requirements regarding liability, cargo, and non-trucking liability coverage. Additionally, I am required to
                            provide proof of Worker's Compensation coverage. I am fully aware that I am not an employee of Western Express, therefore I am not eligible for Worker's Compensation
                            benefits from Western Express. I must provide Western Express with proof of Worker's Compensation or similar coverage for myself and my employees.</p>

                        <p>{lessee === "" ? "___________" : <span style={{textDecoration: "underline"}}>{lessee}</span>} have elected to procure insurance through a vendor provided by Western Express.
                            I acknowledge that said coverage shall satisfy the minimum requirements listed in my Contract Hauling Agreement with Western Express. I have been provided with a copy of my
                            Contract Hauling Agreement, insurance certificate, and insurance infonnation brochure. I understand that I may elect to cancel my coverage and procure insurance through any
                            vendor of my choice at any time. I also agree that policy standards, policies, premiums, or deductibles are subject to change.</p>

                        <p>{lessee === "" ? "___________" : <span style={{textDecoration: "underline"}}>{lessee}</span>} I have elected to deny coverage from the vendor provided for me by Western
                            Express. I acknowledge the minimum insurance coverage outlined in my Contract Hauling Agreement. I will provide Western Express with a copy an insurance certificate
                            indicating that the minimum coverage has been satisfied, and I have voluntarily authorized Western Express to be acknowledged as an additionally insured party and
                            Certificate Holder on my policy. In the event of cancellation or modification of any policy, wiitten notice shall be given to Western Express at least thirty (30) days
                            prior to the effective date of such cancellation or modification.</p>

                        <p>As an independent contractor I have the right to make all decisions relating to my business, including the vendor providing my insurance coverage. I agree to maintain, in
                            full force, any and all coverage required y my Contract Hauling Agreement with Western Express.
                            <br/>All terms, provisions, and agreements set forth in the Contract Hauling Agreement are hereby incorporated herein by reference with the same force and effect as though
                            fully set forth herein. To the extent that the terms set forth in this Addendum are inconsistent with the terms of the Contract Hauling Agreement, the terms set forth in
                            the Contract Hauling Agreement shall apply. </p>

                    </div>
                </IonItem>
                <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <p>Lessee Signature</p>
                            <div hidden={!showLesseeSignature} style={{textAlign: "center", width: "90%", overflow: "hidden", backgroundColor: "white", margin: "auto", borderRadius: "20px"}}>
                                <SignaturePad
                                    ref={lesseeSigCanvas}
                                    canvasProps={{width: 500, height: 175, className: 'sigCanvas'}}
                                />
                            </div>
                            <div className="item-container" style={{display: "flex", fontSize: "17px"}}>
                                {showLesseeSignature ? <p onClick={save} style={{color: "#212121", margin: "8px 10px"}}>Save</p> : <button onClick={save} className="button">Re-sign</button>}
                                {showLesseeSignature ? <p style={{color: "#5096FF", margin: "8px 10px"}} onClick={clear}>Clear</p> : null}
                            </div>
                        </div>
                    </IonItem>
                </IonItemGroup>
                <IonItemGroup>
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <button className="western-button submit" onClick={() => {
                                if (lesseeSignature === "") {
                                    toastStore.getState().setToast('Please save signature.');
                                } else if (lessee === "" || lesseeAddress === "" || lesseeTitle === "") {
                                    toastStore.getState().setToast('Please complete all fields.');
                                } else {
                                    submit();
                                    toastStore.getState().setToast('Your form is being submitted')
                                }
                            }}>SUBMIT
                            </button>
                        </div>
                    </IonItem>
                </IonItemGroup>
            </IonContent>
        </IonPage>
    )
}

export default NewDriverPacket
