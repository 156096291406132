import React from "react"
import {chevronBack} from 'ionicons/icons';
import {IonIcon, IonItem} from "@ionic/react";

function NewsPost(props: any) {

    const formatDate = (date: string) => {
        if (date) {
            let dateObj = new Date(date).getTime() + new Date().getTimezoneOffset() * 60000
            return new Date(dateObj).toLocaleString().split(',')[0]
        }
    }

    return (
        <div className="news-post">
            <div style={{backgroundImage: `url(${props.photo})`, height: "max(25vh, 200px)", width: '100%', backgroundSize: "cover"}}>
                <IonIcon icon={chevronBack} style={{fontSize: "max(32px, 3vw)", margin: "16px"}} onClick={() => props.close(false)}></IonIcon>
            </div>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <h5 style={{fontWeight: '600', width: "80%"}}>{props.article.title}<span><strong
                        style={{color: "#666667", margin: ' 0 8px', fontSize: "12px"}}>{formatDate(props.article.date)}</strong></span></h5>
                    <p>{props.article.article}</p>
                </div>
            </IonItem>
        </div>
    )
}

export default NewsPost
