import {IonContent, IonFooter, IonHeader, IonItem, IonPage, IonToolbar} from "@ionic/react";
import React from "react";
import Contacts from "./Contacts";

import Nav from "./Nav";
import Toolbar from "./Toolbar";
import {Link, useHistory} from "react-router-dom";

const Support = () => {

    const history = useHistory();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="none" color="light">
                    <div className="item-container"><h3><strong style={{margin: '0 8px'}}>Support</strong></h3></div>
                </IonItem>
                <Contacts/>
                <IonItem lines="none" color="light">
                    <div className="item-container">
                        <Link to="/servicelocations">
                            <p>Looking for a terminal?</p>
                        </Link>
                    </div>
                </IonItem>
            </IonContent>
            <IonFooter>
                <Nav onSearch={() => history.push('/Home?search=true')}/>
            </IonFooter>
        </IonPage>
    )
}

export default Support
