import React, {useState} from 'react';
import {IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonItemGroup, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {toastStore} from "../components/AppToast";

const Referral: React.FC = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [driverCode, setDriverCode] = useState(localStorage.getItem('username')!);
    const [state, setState] = useState('AL');
    const [zipCode, setZipCode] = useState('');
    const [cdla, setCdla] = useState(false);
    const [finished, setFinished] = useState(false);
    const [infoModal, setInfoModal] = useState(false)

    let submitApp = async () => {
        let driverId = "DriverAppReferral-" + driverCode;
        if (!firstName || !lastName || !email || !phone || !zipCode || !state || !driverCode) {
            toastStore.getState().setToast('All Fields Required');
        } else {
            // Submit to API
            let cdlVar = 'No'
            if (cdla) {
                cdlVar = 'Yes'
            }
            let data = new FormData();
            data.append('firstname', firstName);
            data.append('lastname', lastName);
            data.append('email', email);
            data.append('phone', phone);
            data.append('zip', zipCode);
            data.append('state', state);
            data.append('CDL', cdlVar);
            data.append('ref', driverId);
            const requestOptions = {
                method: 'POST',
                body: data
            };
            fetch("https://driveforwestern.com/php/leadscors.php", requestOptions)
                .then(function (response) {
                    if (response.status === 222) {
                        resetForm();
                        setFinished(true);
                    } else {
                    }
                })
        }
    }

    function resetForm() {
        setFinished(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setDriverCode("");
        setState("");
        setZipCode("");
        setCdla(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Driver Referral</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonModal id="submit-modal" isOpen={infoModal}>
                <IonCard id="submission-card">
                    <IonItem lines='none' color="light">
                        <div className='item-container'>
                            <p className='title'>Info</p>
                            <p>$500 is paid once the referral is active for 30 days. $500 is paid once the referral is active for 6 months.</p>
                            <p className='subtitle'>Subject to change without notice. Some restrictions may apply.</p>
                        </div>

                    </IonItem>
                    <IonItem lines='none' color="light">
                        <div className='item-container'>
                            <button className="button" onClick={() => setInfoModal(false)}>Done</button>
                        </div>
                    </IonItem>
                </IonCard>
            </IonModal>

            <IonContent fullscreen color="light">
                {!finished ? <IonItemGroup><IonItem lines="none" color="light">
                    <div className="item-container">
                        <h3><strong>Driver You are Referring</strong></h3>
                        <div className="input-container">
                            <input id="firstname" className="input" type="text" placeholder=" " value={firstName} onChange={e => setFirstName(e.target.value!)}/>
                            <p className="placeholder">First Name</p>
                        </div>
                        <div className="input-container">
                            <input id="lastname" className="input" type="text" placeholder=" " value={lastName} onChange={e => setLastName(e.target.value!)}/>
                            <p className="placeholder">Last Name</p>
                        </div>
                        <div className="input-container">
                            <input id="email" className="input" type="email" placeholder=" " value={email} onChange={e => setEmail(e.target.value!)}/>
                            <p className="placeholder">Email</p>
                        </div>
                        <div className="input-container">
                            <input id="phone" className="input" type="tel" placeholder=" " value={phone} onChange={e => setPhone(e.target.value!)}/>
                            <p className="placeholder">Phone</p>
                        </div>
                        <div className="input-container">
                            <select id="state" className="input" placeholder=" " value={state} onChange={e => setState(e.target.value!)} style={{height: "20px"}}>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <p className="placeholder">State</p>
                        </div>
                        <div className="input-container">
                            <input id="zip" className="input" type="number" placeholder=" " value={zipCode} onChange={e => setZipCode(e.target.value!)}/>
                            <p className="placeholder">Zip Code</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p>Does the driver have a CDL-A?</p>
                            <input id="cdla" type="checkbox" onChange={e => setCdla(e.target.checked!)}/>
                        </div>
                        <br/>
                        <button className="button" onClick={() => setInfoModal(true)}>See Details</button>
                    </div>
                </IonItem>

                    <IonItem lines="none" color="light">
                        <div className='item-container'>
                            <button className="western-button submit" onClick={() => submitApp()}>SUBMIT</button>
                        </div>
                    </IonItem>
                </IonItemGroup> : <IonItem color="light" style={{border: "2px solid white"}} lines="none">
                    <div className='item-container'>
                        <p>Thank you for referring</p>
                        <p>The referree will be contacted soon about opportunities</p>
                    </div>
                </IonItem>}
            </IonContent>
        </IonPage>
    );
};

export default Referral;
