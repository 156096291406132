import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonIcon, IonButtons, IonBackButton } from '@ionic/react';

import { call } from 'ionicons/icons';

const ServiceLocationsPA: React.FC = () => {
  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Help/servicelocations" />
          </IonButtons>
          <IonTitle>Service Location: PA</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <iframe title="PA Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-C99R1wtlYAZdY8SxrbY_te_Exs7Tfus&q=2925+Schoener+Road,Bethlehem+PA">
          </iframe>
          <IonCard>
            <IonCardContent>
              <h1>2925 Schoenersville Road</h1>
              <h2>Bethlehem, PA 18017</h2>
              <IonButton id="western-button" href="tel:877-369-0289" expand="block" >
                <IonIcon slot="start" icon={call}></IonIcon>
                 877-369-0289
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Directions</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              Coming Soon!
            </IonCardContent>
            </IonCard>
      
      </IonContent>
    </IonPage>
  );
};

export default ServiceLocationsPA;
