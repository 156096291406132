import React, {useEffect, useState} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useParams} from "react-router-dom";
import AnimateHeight from 'react-animate-height';
import {chevronBackOutline, chevronForward} from 'ionicons/icons';
import {ApiService} from "../api/ApiService";
import {LpSettlement} from "../api/model/LpSettlement";

const PaycheckDetail: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [checkInfo, setCheckInfo] = useState<LpSettlement>();
    const [revenueOrderArray, setRevenueOrderArray] = useState<any[]>([]);
    const [deductionsOrderArray, setDeductionsOrderArray] = useState<any[]>([]);
    const [reimBursementsArray, setReimBursementsArray] = useState<any[]>([])
    const [activeOrder, setActiveOrder] = useState<any[]>([]);
    const [activeOrderNumber, setActiveOrderNumber] = useState("");
    const [activeDeduction, setActiveDeduction] = useState<any>({});
    const [activeDeductionDesc, setActiveDeductionDesc] = useState("");
    const [activeOrigin, setActiveOrigin] = useState<any>({});
    const [activeReimbursementsOrigin, setActiveReimbursementsOrigin] = useState("");
    const [height1, setHeight1] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [height3, setHeight3] = useState(0);

    let {checkno}: any = useParams();
    const buttonArray = ['revenue', 'deductions', 'reimbursments'];

    useEffect(() => {
        ApiService.lpSettlementGet('paycheckDetail', {checknum: checkno}).then(it => {
            processData(it.settlement);
            getOrders(it.settlement);
        });
    }, []);

    const processData = (settlement: any) => {
        let items = settlement
        let info: any = {revenue: [], deductions: [], general: [], reimbursements: []}
        Object.keys(items).forEach((element: any) => {
            if (element === 'revenue') {
                info.revenue = parseObj(items[element])
            } else if (element === 'general') {
                items[element].total = Math.ceil(items[element].total * 100) / 100
                info.general = items[element]
            } else if (element === 'reimbursements') {
                info.reimbursements = parseObj(items[element])
            } else {
                info.deductions = parseObj(items[element])
            }
        });
        setIsLoading(false)
        setCheckInfo(info)
    }

    function parseObj(items: any) {
        let tempArray: any[] = []
        items.forEach((element: any) => {
            tempArray.push(element)
        });
        return tempArray;
    }

    function getOrders(settlement: any) {
        let tempReveuneArray: any[] = [];
        let tempDeductionArray: any[] = [];
        let tempReimbursmentsArray: any[] = [];
        settlement.revenue.forEach((element: any) => {
            if (!tempReveuneArray.includes(element.order) && element.order !== "       ") {
                tempReveuneArray.push(element.order)
            } else if (element.order === "       " || element.order === null) {
                tempReveuneArray.push("Additional Pay")
            }
        });
        settlement.deductions.forEach((element: any) => {
            if (!tempDeductionArray.includes(element.desc)) {
                tempDeductionArray.push(element.desc)
            }
        })
        settlement.reimbursements.forEach((element: any) => {
            if (!tempReimbursmentsArray.includes(element.origin)) {
                tempReimbursmentsArray.push(element.origin)
            }
        })
        setRevenueOrderArray(tempReveuneArray);
        setDeductionsOrderArray(tempDeductionArray);
        setReimBursementsArray(tempReimbursmentsArray);
    }

    const pullRevenueInfo = (order: string) => {
        let tempArray: any[] = []
        checkInfo?.revenue.forEach((element: any) => {
            if (element.order === order || element.order === "       ") {
                tempArray.push(element);
            }
        });
        setActiveOrder(tempArray);
        setShowModal1(true)
    }

    const pullDeductionInfo = (desc: string) => {
        let tempArray: {} = {}
        checkInfo?.deductions.forEach((element: any) => {
            if (element.desc === desc) {
                tempArray = element;
            }
        });
        setActiveDeduction(tempArray);
        setShowModal2(true)
    }

    const pullReimbursmentInfo = (origin: string) => {
        let tempArray: {} = {}
        checkInfo?.reimbursements.forEach((element: any) => {
            if (element.origin === origin) {
                tempArray = element;
            }
        });
        setActiveOrigin(tempArray);
        setShowModal3(true)
    }

    const toggle = () => {
        if (height1 === 0) {
            activeButton('load');
            setHeight1(1);
            setHeight2(0);
            setHeight3(0)
        } else {
            setHeight1(0);
            document.getElementById('revenue')?.setAttribute('style', '');
        }
    }

    const toggle2 = () => {
        if (height2 === 0) {
            activeButton('trip');
            setHeight2(1);
            setHeight1(0);
            setHeight3(0)
        } else {
            setHeight2(0);
            document.getElementById('deductions')?.setAttribute('style', '');
        }
    }

    const toggle3 = () => {
        if (height3 === 0) {
            activeButton('money');
            setHeight3(1);
            setHeight2(0);
            setHeight1(0)
        } else {
            setHeight3(0);
            document.getElementById('reimbursements')?.setAttribute('style', '');
        }
    }

    function activeButton(id: string) {
        document.getElementById(id)?.setAttribute('style', 'border: 4px solid #99cc00 !important; border-radius: 11px');
        for (let i = 0; i < buttonArray.length; i++) {
            if (buttonArray[i] !== id) {
                document.getElementById(buttonArray[i])?.setAttribute('style', '');
            }
        }
    }

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton color="#0e375a" style={{"color": "#0e375a"}} defaultHref="/settlements"/>
                    </IonButtons>
                    <IonTitle>Settlement Details</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen style={{"--background": "white", backgrounColor: "white"}}>

                <IonModal isOpen={showModal1} cssClass='my-custom-class'>
                    <IonContent fullscreen style={{"--background": "#FFFFFF"}}>
                        <IonCard style={{border: "2px solid white", background: "#ffffff"}}>
                            <IonCardHeader className="load-details-card-header">
                                <IonCardTitle className="load-title-card">Order: {activeOrderNumber}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="contact-page-content">
                                <div style={{marginTop: '10px'}}>
                                    {activeOrder?.map(element => <IonItem style={{"--background": "transparent"}}>
                                        <IonLabel className="ion-text-wrap">
                                            <h3><b>Origin:</b> {element.origin !== null && element.origin !== ", null" ? element.origin : 'N/A'}</h3>
                                            {element.destination !== null && element.destination !== ", null" && element.destination !== "" ? <h3><b>Destination:</b> {element.destination}</h3> : null}
                                            <h3><b>Dispatch Number:</b> {element.disp}</h3>
                                            {element.loadedMiles !== null ? <h3><b>Loaded Miles:</b> {element.loadedMiles} </h3> : null}
                                            {element.loadedRate !== null ? <h3><b>Loaded Rate:</b> {element.loadedRate} </h3> : null}
                                            <h3><b>Additional Pay:</b> ${Math.round(element.addlPay * 100) / 100}</h3>
                                            <h2><b>Amount Due:</b> ${Math.round(element.dueOwner * 100) / 100}</h2>
                                        </IonLabel>
                                    </IonItem>)}
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonContent>
                    <IonFooter>
                        <div style={{margin: 'auto', textAlign: 'center', background: "#ffffff"}}>
                            <IonButton onClick={() => setShowModal1(false)} style={{
                                "--background": "#3F77E2",
                                background: "#3F77E2",
                                width: '90%',
                                color: 'white',
                                '--border-radius': "10px",
                                borderRadius: "10px",
                                boxShadow: "0px 2px 2px #092339"
                            }}>Done</IonButton>
                        </div>
                    </IonFooter>
                </IonModal>

                <IonModal isOpen={showModal2} cssClass='my-custom-class'>
                    <IonContent fullscreen style={{"--background": "#FFFFFF"}}>
                        <IonCard style={{border: "2px solid white", background: "#ffffff"}}>
                            <IonCardHeader className="load-details-card-header">
                                <IonCardTitle className="load-title-card">{activeDeductionDesc}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="contact-page-content">
                                <div style={{marginTop: '10px'}}>
                                    <IonItem style={{"--background": "transparent"}}>
                                        <IonLabel className="ion-text-wrap">
                                            <h2><b>Order #:</b> {activeDeduction.order !== null ? activeDeduction.order : 'N/A'}</h2>
                                            <h3><b>Date:</b> {activeDeduction.date}</h3>
                                            <h3><b>Description:</b> {activeDeduction.desc}</h3>
                                            {activeDeduction.leaseBegin !== null ? <h3><b>Lease Beginning:</b> ${Math.round(activeDeduction.leaseBegin * 100) / 100} </h3> : null}
                                            {activeDeduction.leaseEnd !== null ? <h3><b>Lease End:</b> ${Math.round(activeDeduction.leaseEnd * 100) / 100} </h3> : null}
                                            <h2><b>Amount:</b> ${Math.round(activeDeduction.amount * 100) / 100}</h2>
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonContent>
                    <IonFooter>
                        <div style={{margin: 'auto', textAlign: 'center', background: "#ffffff"}}>
                            <IonButton onClick={() => setShowModal2(false)} style={{
                                "--background": "#3F77E2",
                                background: "#3F77E2",
                                width: '90%',
                                color: 'white',
                                '--border-radius': "10px",
                                borderRadius: "10px",
                                boxShadow: "0px 2px 2px #092339"
                            }}>Done</IonButton>
                        </div>
                    </IonFooter>
                </IonModal>

                <IonModal isOpen={showModal3} cssClass='my-custom-class'>
                    <IonContent fullscreen style={{"--background": "#FFFFFF"}}>
                        <IonCard style={{border: "2px solid white", background: "#ffffff"}}>
                            <IonCardHeader className="load-details-card-header">
                                <IonCardTitle className="load-title-card">{activeReimbursementsOrigin}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="contact-page-content">
                                <div style={{marginTop: '10px'}}>
                                    <IonItem style={{"--background": "transparent"}}>
                                        <IonLabel className="ion-text-wrap">
                                            <h2><b>CD #:</b> {Math.round(activeOrigin.cd * 100) / 100}</h2>
                                            <h3><b>Origin:</b> {Math.round(activeOrigin.origin * 100) / 100}</h3>
                                            <h3><b>Due to Owner:</b> ${Math.round(activeOrigin.dueOwner * 100) / 100}</h3>
                                        </IonLabel>
                                    </IonItem>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonContent>
                    <IonFooter>
                        <div style={{margin: 'auto', textAlign: 'center', background: "#ffffff"}}>
                            <IonButton onClick={() => setShowModal3(false)} style={{
                                "--background": "#3F77E2",
                                background: "#3F77E2",
                                width: '90%',
                                color: 'white',
                                '--border-radius': "10px",
                                borderRadius: "10px",
                                boxShadow: "0px 2px 2px #092339"
                            }}>Done</IonButton>
                        </div>
                    </IonFooter>
                </IonModal>
                <IonList>
                    <IonLabel><span style={{
                        fontSize: "22px",
                        textAlign: 'center',
                        marginLeft: '1%'
                    }}><b>Settlement Date: {!isLoading ? checkInfo?.general?.date.substr(5) + '-' + checkInfo?.general?.date.substr(0, 4) :
                        <IonSpinner style={{height: '15px'}} color="black" name="dots"/>}</b></span>
                        <div style={{marginLeft: '2%'}}>
                            <h2><b>Check #:</b> {!isLoading ? checkInfo?.general.checknum : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h2>
                            <h3><b>Unit:</b> {!isLoading ? checkInfo?.general.unit : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h3>
                            <h3><b>Loaded Miles:</b> {!isLoading ? checkInfo?.general.loadedMiles : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h3>
                            <h3><b>Revenue:</b> ${!isLoading ? checkInfo?.general.revenue : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h3>
                            <h3><b>Deductions:</b> ${!isLoading ? checkInfo?.general.deductions : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h3>
                            <h3><b>Reimbursements:</b> ${!isLoading ? checkInfo?.general.reimbursements : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h3>
                            <h2><b>Total:</b> ${!isLoading ? checkInfo?.general.total : <IonSpinner style={{height: '15px'}} color="black" name="dots"/>} </h2>
                        </div>
                    </IonLabel>
                </IonList>
                <br/>
                <IonButton className="home-menu-item" color="white" onClick={toggle} aria-expanded={height1 !== 0}>
                    <div id="btn-text-menu">Revenue</div>
                    <div className={height1 !== 0 ? "fa fa-arrow-down open" : "fa fa-arrow-down"}><IonIcon slot="icon-only" icon={chevronBackOutline}/></div>
                </IonButton>
                <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height1 === 1 ? 'auto' : 0} id="loadSection">
                    <IonList>
                        {!isLoading ? <>
                                {revenueOrderArray?.map(revenue => <IonButton style={{
                                    "--background": "#0E375A",
                                    width: '85%',
                                    background: "#0e375a",
                                    marginLeft: '7%',
                                    color: 'white',
                                    '--border-radius': "10px",
                                    borderRadius: "10px",
                                    boxShadow: "0px 2px 2px #CECECE"
                                }} onClick={() => {
                                    setActiveOrderNumber(revenue);
                                    pullRevenueInfo(revenue)
                                }}>{revenue !== "Additional Pay" ? ("Order: " + revenue) : "Addtional Pay"}
                                    <div className="btn-menu-arrow"><IonIcon style={{color: 'white'}} slot="icon-only" icon={chevronForward}/></div>
                                </IonButton>)}
                            </> :
                            <div style={{width: "100%", textAlign: 'center'}}>
                                <IonSpinner style={{height: '45px', color: 'black'}} color="black" name="crescent"/>
                            </div>}
                    </IonList>
                </AnimateHeight>
                <br/>
                <IonButton className="home-menu-item" color="white" onClick={toggle2} aria-expanded={height2 !== 0}>
                    <div id="btn-text-menu">Deductions</div>
                    <div className={height2 !== 0 ? "fa fa-arrow-down open" : "fa fa-arrow-down"}><IonIcon slot="icon-only" icon={chevronBackOutline}/></div>
                </IonButton>
                <AnimateHeight style={{width: '100vw', overflowY: 'scroll'}} duration={500} height={height2 === 1 ? 'auto' : 0} id="loadSection">
                    <IonList>
                        {!isLoading ? <>
                                <IonLabel className="ion-text-wrap"><br/>
                                    {deductionsOrderArray?.map(item => <IonButton style={{
                                        "--background": "#0E375A",
                                        width: '85%',
                                        background: "#0e375a",
                                        marginLeft: '7%',
                                        textAlign: 'center',
                                        color: 'white',
                                        '--border-radius': "10px",
                                        fontSize: "100%",
                                        borderRadius: "10px",
                                        boxShadow: "0px 2px 2px #CECECE"
                                    }} onClick={() => {
                                        setActiveDeductionDesc(item);
                                        pullDeductionInfo(item)
                                    }}>{item}
                                        <div className="btn-menu-arrow"><IonIcon style={{color: 'white'}} slot="icon-only" icon={chevronForward}/></div>
                                    </IonButton>)}
                                </IonLabel> </> :
                            <div style={{width: "100%", textAlign: 'center'}}>
                                <IonSpinner style={{height: '45px', color: 'black'}} color="black" name="crescent"/>
                            </div>}
                    </IonList>
                </AnimateHeight>
                <br/>
                <IonButton className="home-menu-item" color="white" onClick={toggle3} aria-expanded={height3 !== 0}>
                    <div id="btn-text-menu">Reimbursements</div>
                    <div className={height3 !== 0 ? "fa fa-arrow-down open" : "fa fa-arrow-down"}><IonIcon slot="icon-only" icon={chevronBackOutline}/></div>
                </IonButton>
                <AnimateHeight style={{width: '100vw', overflowY: 'scroll', background: "#transparent"}} duration={500} height={height3 === 1 ? 'auto' : 0} id="loadSection">
                    <IonList>
                        {!isLoading ? <>
                                {reimBursementsArray?.map(item => <IonButton style={{
                                    "--background": "#0E375A",
                                    width: '85%',
                                    background: "#0e375a",
                                    marginLeft: '7%',
                                    textAlign: 'center',
                                    color: 'white',
                                    '--border-radius': "10px",
                                    fontSize: "100%",
                                    borderRadius: "10px",
                                    boxShadow: "0px 2px 2px #CECECE"
                                }} onClick={() => {
                                    setActiveReimbursementsOrigin(item);
                                    pullReimbursmentInfo(item)
                                }}>{item}
                                    <div className="btn-menu-arrow"><IonIcon style={{color: 'white'}} slot="icon-only" icon={chevronForward}/></div>
                                </IonButton>)} </> :
                            <div style={{width: "100%", textAlign: 'center'}}>
                                <IonSpinner style={{height: '45px', color: 'black'}} color="black" name="crescent"/>
                            </div>}
                    </IonList>
                </AnimateHeight>
                <br/>
            </IonContent>
        </IonPage>
    );
};

export default PaycheckDetail;
