import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';

import commentIcon from "../imgs/quotes.png"
import {ApiService} from "../api/ApiService";
import {UserState, userStore} from "../api/AuthService";
import {toastStore} from "../components/AppToast";
import {useHistory} from "react-router-dom";

interface LoadInfo {
    approved: string;
    blNumber: string;
    comments: string;
    consigneeAddr1: string;
    consigneeAddr2: string;
    consigneeCity: string;
    consigneeCode: string;
    consigneeName: string;
    consigneeNameOrExt: string;
    consigneePhone: string;
    consigneeState: string;
    consigneeZip: string;
    deliveryEndTime: string;
    deliveryStartTime: string;
    pickupNumber: string;
    poNumber: string;
    deliveryNumber: string;
    dispatchNumber: string;
    driver1: string;
    driver2: string;
    driverLoad: boolean;
    driverUnload: boolean;
    loadNumber: string;
    milesEmpty: number;
    milesLoaded: number;
    milesTotal: number;
    numberOfStops: string;
    orderNumber: string;
    pickupEndTime: string;
    pickupStartTime: string;
    rejectReason: string;
    relayCode: string;
    shipperAddr1: string;
    shipperAddr2: string;
    shipperCity: string;
    shipperCode: string;
    shipperName: string;
    shipperPhone: string;
    shipperState: string;
    shipperZip: string;
    trailer: string;
    tarpPay: boolean;
    orderStatus: string;
}

const CurrentLoad: React.FC = () => {

    const history = useHistory();
    const {storedLoadInfo}: { storedLoadInfo: LoadInfo } = userStore((state: UserState) => ({storedLoadInfo: state.loadInfo}));

    const [loadInfo, setLoadInfo] = useState<LoadInfo>();
    const [hasTarpPay, setHasTarpPay] = useState(false);
    const [receivedPhotos, setReceivedPhotos] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!storedLoadInfo) {
            toastStore.getState().setToast('You do not have any load assigned.')
            history.push('/');
            return;
        }
        processLoadInfo(storedLoadInfo);
        ApiService.sendEventCommand('Checked Load', 'eventCheck', 'User checked their current load');
    }, []);

    const processLoadInfo = (load: LoadInfo) => {
        let ld = JSON.parse(JSON.stringify(load));
        ld.comments = ld.comments.join('\n');
        switch (ld.driverLoad) {
            case 'N':
                ld.driverLoad = 'Live Load'
                break
            case 'Y':
                ld.driverLoad = 'Y'
                break
            case 'W':
                ld.driverLoad = 'Pre loaded - pickup by end of window'
                break
            case 'P':
                ld.driverLoad = 'Pre loaded'
                break
            default:
                break
        }
        switch (ld.driverUnload !== null ? ld.driverUnload : ld.driverUnloadAlt) {
            case 'N':
                ld.driverUnload = 'Live Unload'
                break
            case 'Y':
                ld.driverUnload = 'Y'
                break
            case 'W':
                ld.driverUnload = 'Drop off - deliver by end of window'
                break
            case 'D':
                ld.driverUnload = 'Drop off'
                break
            default:
                break
        }
        setLoadInfo(ld);
        setLoading(false)
        if (ld.tarpPay) {
            checkTarpPhotos(ld.orderNumber);
            setHasTarpPay(true)
        }
    }

    const checkTarpPhotos = (orderNum: number) => {
        ApiService.getPhotoList('currentLoad', {load: orderNum, type: 'tarp'}).then(it => {
            setReceivedPhotos(it.photos.length !== 0);
        });
    }
    const formatComment = (comment: string) => {
        if (comment) {
            return comment
        } else {
            return 'No Comment'
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Current Load</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="white">
                <IonModal isOpen={loading} id="transparent">
                    <div className="modal-div">
                        <div id="modal">
                            <div className='loading-div'>
                                <div className="snippet" data-title=".dot-flashing" style={{margin: "4px 16px"}}>
                                    <div className="stage">
                                        <div className="dot-flashing"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonModal>
                <div className='main' style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <div>
                        <IonItem color="white" lines="none">
                            <h4 className='title boldest' style={{color: "#99cc00"}}>{loadInfo?.loadNumber}</h4>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div>
                                <strong> Trailer: {loadInfo?.trailer} <span
                                    style={{color: "#FF0000"}}>{parseInt(loadInfo?.dispatchNumber || "0") >= 1 && loadInfo?.orderStatus === "D" ? "" : "Bring a trailer to shipper"}</span></strong><br/>
                                <p className='subtitle'>Dispatch: {loadInfo?.dispatchNumber}</p>
                                <p className='subtitle'>Status: {loadInfo?.orderStatus}</p>
                                <strong style={{color: "#99cc00"}}>{'Pickup & Delivery - '}{loadInfo?.driverLoad} / {loadInfo?.driverUnload}</strong><br/>
                                <strong style={{fontSize: "large"}}>Tarp Photos Received: <span
                                    style={{textDecoration: "underline"}}>{hasTarpPay ? receivedPhotos ? "Yes" : "No" : 'Not Required'}</span></strong>
                            </div>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div>
                                <strong>Route: {loadInfo?.milesTotal} mi, {parseInt(loadInfo?.numberOfStops ?? '0')} Stop(s)</strong>
                                <p className='subtitle'>Empty: {loadInfo?.milesEmpty} Loaded: {loadInfo?.milesLoaded}</p>
                            </div>
                        </IonItem>
                    </div>
                    <div>
                        <IonItem color="white" lines="none">
                            <div className='letter-icon' style={{backgroundColor: "#9975FF"}}><p>L</p></div>
                            <strong>Load At</strong>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div>
                                <p className='subtitle'>{loadInfo?.pickupStartTime} - {loadInfo?.pickupEndTime}</p>
                                <h5 className='title'>{loadInfo?.shipperName}</h5>
                                <p>{loadInfo?.shipperAddr1}<br/>{loadInfo?.shipperAddr2}</p>
                                <p>{loadInfo?.shipperCity}, {loadInfo?.shipperState} {loadInfo?.shipperZip}</p>
                                <p>{loadInfo?.shipperPhone}</p>
                            </div>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div className='box-grid'>
                                <p>PO: {loadInfo?.poNumber || "-"}</p><p>PICKUP: {loadInfo?.pickupNumber || "-"}</p>
                                <p>ORDER: {loadInfo?.orderNumber || "-"}</p><p>BL: {loadInfo?.blNumber || "-"}</p>
                            </div>
                        </IonItem>

                        <IonItem color="white" lines="none">
                            <div className='letter-icon' style={{backgroundColor: "#FF8575"}}><p>C</p></div>
                            <strong>Consignee</strong>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div>
                                <p className='subtitle'>{loadInfo?.deliveryStartTime} - {loadInfo?.deliveryEndTime}</p>
                                <h5 className='title'>{loadInfo?.consigneeName} {loadInfo?.consigneeNameOrExt}</h5>
                                <p>{loadInfo?.consigneeAddr1}<br/>{loadInfo?.consigneeAddr2}</p>
                                <p>{loadInfo?.consigneeCity}, {loadInfo?.consigneeState} {loadInfo?.consigneeZip}</p>
                                <p>{loadInfo?.consigneePhone}</p>
                            </div>
                        </IonItem>
                        <IonItem color="white" lines="none">
                            <div className='box-grid'>
                                <p>DELIVERY: {loadInfo?.deliveryNumber || "-"}</p>
                            </div>
                        </IonItem>
                    </div>
                </div>
                <IonItem color="white" lines="none">
                    <div className='letter-icon' style={{backgroundColor: "#212121"}}><img src={commentIcon} alt="" style={{height: "60%", margin: "auto"}}/></div>
                    <strong>Comments</strong>
                </IonItem>
                <IonItem color="white" lines="none">
                    <p>{formatComment(loadInfo?.comments ?? "")}</p>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default CurrentLoad;
