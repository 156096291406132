import React, {useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";


const Orientation: React.FC = () => {

    const history = useHistory();

    const [governmentId, setGovernmentId] = useState("")
    const [showLoading, setShowLoading] = useState(false)

    const requalifyDriver = () => {
        ApiService.updateOrientation("orientation", {
            ssn: governmentId.replace(/-/g, ""),
            passed: 0,
            disqualified: 0,
            completed: 0,
        }).then(() => {
            toastStore.getState().setSuccessToast('Your request was submitted');
            history.push('/orientationroadtests');
        }).finally(() => {
            setShowLoading(false);
        });
    }

    const handleId = (id: string) => {
        let val = id
        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val
    }

    return (
        <IonPage>
            <IonModal isOpen={showLoading} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                            <p>Please wait...Your request is being submitted.</p>
                            <div className="snippet" data-title=".dot-flashing" style={{margin: "4px 16px"}}>
                                <div className="stage">
                                    <div className="dot-flashing"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonModal>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Retest Driver</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="none">
                    <div className="item-container">
                        <div className="input-container">
                            <input id="orientation" className="input" placeholder=" " value={handleId(governmentId)} onChange={e => setGovernmentId(e.target.value!)}/>
                            <p className="placeholder">SSN</p>
                        </div>
                    </div>
                </IonItem>
                <IonItem lines="none">
                    <div className="item-container">
                        <button className="western-button submit" onClick={() => {
                            setShowLoading(true);
                            requalifyDriver()
                        }}>SUBMIT
                        </button>
                    </div>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default Orientation;
