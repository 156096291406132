import {config} from "../Constants";
import {PhotoUploadRequest} from "./model/PhotoUploadRequest";

export class PhotoApiService {
    public static uploadPhotos(photos: PhotoUploadRequest[], refId: string, uploadType: string, pictureSerial?: boolean): Promise<Response[]> {
        return Promise.all(photos.map(async (photo, index) => {
            var data = new FormData();
            data.append('uploadType', uploadType);
            data.append('refId', refId);
            data.append('file', new Blob([(await fetch(photo.data).then(res => res.blob()))], {type: 'application/octet-stream'}));
            if (pictureSerial) {
                data.append('pictureSerial', index.toString());
            }
            return fetch(config.url.BASE_URL_PHOTOS, {method: 'POST', body: data});
        }));
    }

}
