import React, {useEffect, useState} from "react"
import {calendarClearOutline} from 'ionicons/icons';
import {IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonPage, IonToolbar} from "@ionic/react";
import NewsPost from "./NewsPost";
import Toolbar from "./Toolbar";
import Nav from "./Nav";
import {ApiService} from "../api/ApiService";
import {useHistory} from "react-router-dom";
import {NewsArticle} from "../api/model/NewsArticle";

function News() {

    const history = useHistory();

    const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);
    const [showPost, setShowPost] = useState(false)
    const [article, setArticle] = useState<NewsArticle>()
    const [postPhoto, setPostPhoto] = useState("")

    useEffect(() => {
        ApiService.sendEventCommand('News View', 'eventCheck', 'User has viewed the News page');
        ApiService.getNewsArticles('news').then(it => {
            it.filter(article => article.active).forEach(article => {
                ApiService.getPhotoList('news', {refId: article.id.toString(), type: 'news'}).then(photoResponse => {
                    if (photoResponse.photos?.length) {
                        article.photoArray = photoResponse.photos.map((photo, index) => 'https://westernexpressmobile.com/v2/news_' + photo.refId + '-' + index + '.jpg');
                        setNewsArticles((articles) => articles.concat(article));
                    }
                });
            })
        });
    }, []);

    const formatDate = (date: string) => {
        if (date) {
            let dateObj = new Date(date).getTime() + new Date().getTimezoneOffset() * 60000
            return new Date(dateObj).toLocaleString().split(',')[0]
        }
    }

    const posts = newsArticles.map((article, index) => {
        if (index !== 0) {
            const photo = article.photoArray ? article.photoArray.reverse()[0] : ""
            return (
                <div style={{backgroundColor: "#f4f5f8", padding: '16px', fontFamily: "Montserrat, sans-serif"}} key={index} onClick={() => {
                    setArticle(article);
                    setPostPhoto(photo);
                    setShowPost(true)
                }}>
                    <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "#f4f5f8"}}>
                        <div style={{width: '60%'}}>
                            <h5 style={{fontWeight: '900'}}>{article.title}</h5>
                            <div style={{display: "flex", alignItems: "center"}}><strong style={{color: "#666667", margin: ' 0 8px', fontSize: "12px"}}>{formatDate(article.date)}</strong><IonIcon
                                icon={calendarClearOutline}></IonIcon></div>
                        </div>
                        <img src={photo} style={{height: "min(25vw, 150px)", borderRadius: '10px'}} alt=""/>
                    </div>
                </div>

            )
        } else {
            return <></>
        }
    })

    function featured() {
        const post = newsArticles[0]
        const photo = post ? post.photoArray.reverse()[0] : ""
        return (
            <div onClick={() => {
                setArticle(post);
                setPostPhoto(photo);
                setShowPost(true)
            }} style={{
                backgroundImage: `url(${photo})`,
                backgroundSize: "cover",
                height: 'min(50vh, 500px)',
                width: '90%',
                backgroundPositionX: "center",
                margin: "auto",
                borderRadius: "10px",
                position: "relative"
            }}>
                <h5 style={{
                    fontWeight: '900',
                    fontFamily: "Montserrat, san-serif",
                    padding: "8px 16px",
                    color: "#ffffff",
                    width: "70%",
                    position: "absolute",
                    bottom: "0"
                }}>{post ? post.title : ""}</h5>
            </div>
        )
    }

    return (
        <IonPage>
            {showPost ? null : <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar/>
                </IonToolbar>
            </IonHeader>}
            <IonContent fullscreen color="light">
                {!showPost ? <IonItem color="light" lines="none">
                    <div className="item-container"><h3><strong style={{margin: '0 8px'}}>News</strong></h3></div>
                </IonItem> : null}
                {showPost ? <NewsPost article={article} photo={postPhoto} close={setShowPost}/> :
                    <div className="main">
                        <div>
                            {showPost ? null : featured()}
                        </div>
                        <div>
                            {posts}
                        </div>
                    </div>}
            </IonContent>
            <IonFooter>
                <Nav onSearch={() => history.push('/home?search=true')}/>
            </IonFooter>
        </IonPage>
    )
}

export default News;
