import React, {useEffect, useState} from 'react'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {dateDoy} from '../Util'
import {config} from '../Constants'
import ContactCard from "./ContactCard"
import Loader from 'react-loader-spinner'
import map from '../imgs/mapImg.png'
import {chevronBackOutline} from 'ionicons/icons'
import {ApiService} from "../api/ApiService";
import {userStore} from "../api/AuthService";
import {DriverManager} from "../api/model/DriverManager";

type Load = {
    consigneeCity: string
    consigneeCode: string
    consigneeState: string
    deadhead: string
    deliveryEndTime: string
    deliveryStartTime: string
    dispatch: string
    miles: string
    orderNumber: string
    pickupEndTime: string
    pickupStartTime: string
    shipperCity: string
    shipperCode: string
    shipperState: string
}

const LoadSelection: React.FC = () => {

    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [loads, setLoads] = useState<any>([]);
    const [selectedLoad, setSelectedLoad] = useState<any>({});
    const [noPrePlanned, setNoPrePlanned] = useState(false);
    const [notModeled, setNotModeled] = useState(false);
    const [dm, setDM] = useState<DriverManager>({} as DriverManager);

    useEffect(() => {
        ApiService.topLoadsGet('load selection', {unit: userStore.getState().driver.unit}).then(it => {
            if (it.loads.error === 'Unit already planned') {
                setNoPrePlanned(true);
                setLoads(['loaded']);
            } else if (it.loads.error === 'Unit not modeled') {
                setNotModeled(true);
                setLoads(['notModeled']);
            } else {
                parseOrders(it.loads.orders);
            }
        });
        ApiService.getDriverManager('load selection', {dmCode: localStorage.getItem('dmCode')!}).then(it => setDM(it));
        ApiService.sendEventCommand('Checked Loads', 'eventCheck', 'User checked their load boards');
    }, []);

    async function parseOrders(orders: any) {
        let tempArray: Load[] = []
        console.log("orders", orders);
        if (orders[0].orderNumber === 'WAIT') orders.shift();
        console.log("orders shift", orders);
        for (let i = 0; i < 1 && typeof orders[i] !== 'undefined'; i++) {
            if (orders[i].orderNumber !== 'WAIT' && orders[i].orderNumber !== 'HOME') {
                orders[i].details.pickupStartDate =
                await getDate(orders[i].details.pickupStartDate)
                orders[i].details.pickupEndDate =
                await getDate(orders[i].details.pickupEndDate)
                orders[i].details.deliveryStartDate =
                await getDate(orders[i].details.deliveryStartDate)
                orders[i].details.deliveryEndDate =
                await getDate(orders[i].details.deliveryEndDate)
                orders[i].details.pickupStartTime =
                orders[i].details.pickupStartTime.slice(0, 2) + ':' + orders[i].details.pickupStartTime.slice(2)
                orders[i].details.pickupEndTime =
                orders[i].details.pickupEndTime.slice(0, 2) + ':' + orders[i].details.pickupEndTime.slice(2)
                orders[i].details.deliveryStartTime =
                orders[i].details.deliveryStartTime.slice(0, 2) + ':' + orders[i].details.deliveryStartTime.slice(2)
                orders[i].details.deliveryEndTime =
                orders[i].details.deliveryEndTime.slice(0, 2) + ':' + orders[i].details.deliveryEndTime.slice(2)
                orders[i].details.weight = orders[i].details.weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                tempArray.push(orders[i])
            } else if ((orders[i].orderNumber === 'WAIT' || orders[i].orderNumber === 'HOME') && orders.length === 1) {
                tempArray.push(orders[i]);
                setNotModeled(true);
            }
        }
        setLoads(tempArray)
    }

    const getDate = async (dateData: any) => {
        const year = dateData.toString().slice(0, -4)
        const day = dateData.toString().slice(4)
        const date = new Date(dateDoy(year, day))
        return `${date.getMonth() + 1}-${date.getDate() - 1}`
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Select Load</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonModal isOpen={showModal1} cssClass='my-custom-class'>
                    <IonHeader translucent>
                        <IonToolbar className='load-toolbar'>
                            <IonButtons slot='start'>
                                <IonButton
                                    style={{width: "65px"}}
                                    color='primary'
                                    onClick={function () {
                                        setShowModal1(false)
                                    }}
                                >
                                    <IonIcon slot='icon-only' icon={chevronBackOutline}/>
                                </IonButton>
                            </IonButtons>
                            <IonTitle>
                                <span style={{color: "#0E375A"}}><b>ORDER #: {selectedLoad.orderNumber}</b></span>
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen style={{'--background': '#FFFFFF'}}>
                        {/* TIME */}
                        <IonCard style={{border: '2px solid white', background: '#ffffff'}}>
                            <IonCardHeader className='load-details-card-header'>
                                <IonCardTitle className='load-title-card'>Pick-up/Drop-off</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className='contact-page-content'>
                                <div style={{marginTop: '10px'}}>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle
                                            style={{color: '#9e9e9e', fontSize: '16px', backgroundColor: 'transparent !important'}}
                                        >
                                            Pick Up:{' '}
                                            <b>
												<span style={{color: '#3F77E2', textAlign: 'center'}}>
													{selectedLoad.details?.pickupStartDate} | {selectedLoad.details?.pickupStartTime} CST
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Drop Off:{' '}
                                            <b>
												<span style={{color: '#ED8254', textAlign: 'center'}}>
													{selectedLoad.details?.deliveryStartDate} | {selectedLoad.details?.deliveryStartTime} CST
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>
                        {/* LOCATION */}
                        <IonCard style={{border: '2px solid white', background: '#ffffff'}}>
                            <IonCardHeader className='load-details-card-header'>
                                <IonCardTitle className='load-title-card'>Locations</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className='contact-page-content'>
                                <div style={{marginTop: '10px'}}>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Origin:{' '}
                                            <b>
												<span style={{color: '#3F77E2', textAlign: 'center'}}>
													{selectedLoad.details?.shipperCity} | {selectedLoad.details?.shipperState}
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Dest:{' '}
                                            <b>
												<span style={{color: '#ED8254', textAlign: 'center'}}>
													{selectedLoad.details?.consigneeCity} | {selectedLoad.details?.consigneeState}
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>
                        {/* MILEAGE */}
                        <IonCard style={{border: '2px solid white', background: '#ffffff'}}>
                            <IonCardHeader className='load-details-card-header'>
                                <IonCardTitle className='load-title-card'>Mileage</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className='contact-page-content'>
                                <div style={{marginTop: '10px'}}>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Loaded:{' '}
                                            <b>
												<span style={{color: '#3F77E2', textAlign: 'center'}}>
                                                    {console.log("type", typeof selectedLoad?.miles, selectedLoad?.miles)}
													{parseInt(selectedLoad?.miles)} mi
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Empty:{' '}
                                            <b>
												<span style={{color: '#ED8254', textAlign: 'center'}}>
													{parseInt(selectedLoad?.deadhead)} mi
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>
                        {/* LOAD INFO */}
                        <IonCard style={{border: '2px solid white', background: '#ffffff'}}>
                            <IonCardHeader className='load-details-card-header'>
                                <IonCardTitle className='load-title-card'>Weight (lbs)</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className='contact-page-content'>
                                <div style={{marginTop: '10px'}}>
                                    <div style={{marginTop: '5px'}}>
                                        <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                            Load Weight:{' '}
                                            <b>
												<span style={{color: '#ED8254', textAlign: 'center'}}>
													{selectedLoad.details?.weight} lbs
												</span>
                                            </b>
                                        </IonCardSubtitle>
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonContent>
                    <IonFooter>
                        <div style={{margin: 'auto', textAlign: 'center', background: '#ffffff'}}>
                        </div>
                    </IonFooter>
                </IonModal>

                <IonModal isOpen={showModal2} cssClass='my-custom-class'>
                    <IonHeader translucent>
                        <IonToolbar className='load-toolbar'>
                            <IonButtons slot='start'>
                                <IonButton
                                    style={{width: "65px"}}
                                    color='primary'
                                    onClick={function () {
                                        setShowModal2(false)
                                    }}
                                >
                                    <IonIcon slot='icon-only' icon={chevronBackOutline}/>
                                </IonButton>
                            </IonButtons>
                            <IonTitle>
                                <span style={{color: "#0E375A"}}><b>ORDER #: {selectedLoad.orderNumber}</b></span>
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen style={{'--background': '#FFFFFF'}}>
                        {/* TIME */}
                        <IonCard>
                            {console.log("notModeled", notModeled)}
                            {loads[0] === 'loaded' ? (
                                <>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            <div style={{color: '#0E375A'}}>
                                                <b>Already Loaded</b>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent style={{paddingTop: '7px', backgroundColor: '#dcdcdc', color: 'black'}}>
										<span>
											<b>You are already on the road with a load. Check back here after to get a new one!</b>
										</span>
                                    </IonCardContent>
                                </>
                            ) : notModeled ? (
                                <>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <div style={{color: '#0E375A'}}>
                                            <b>Not Available</b>
                                        </div>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent style={{paddingTop: '7px', backgroundColor: '#dcdcdc', color: 'black'}}>
                                    <span>
                                        <b>This feature is not currently available for your unit. Please try again later.</b>
                                    </span>
                                </IonCardContent>
                            </>
                            ) : (
                                <>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            <div style={{color: '#0E375A'}}>
                                                <b>Contact your Carrier Agent</b>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent style={{paddingTop: '7px', backgroundColor: '#0E3761', color: 'white', textAlign: "left"}}>
										<span>
											<b>Please contact your carrier agent to get a new load. They can be reached with the information below.</b>
										</span>
                                        <span>
											<div className="modal-div" style={{background: "#fff !important"}}>
												<div className="item-container" id="modal">
                                                    { typeof dm.firstName !== 'undefined' ?
													    <ContactCard contact={{name: dm.firstName + " " + dm.lastName, email: dm.email, subcategory: "Driver Agent", phone: dm.phone}}/>
                                                    :
                                                    null
                                                    }      
												</div>
											</div>
										</span>
                                    </IonCardContent>
                                </>
                            )}
                        </IonCard>
                    </IonContent>
                </IonModal>

                <div className={config.isPlatformSci ? 'home-div-plat-sci' : 'home-div'}>
                    {loads.length !== 0 ? (
                        <IonList style={{'background-color': 'transparent !important', background: 'transparent !important'}}>
                            {noPrePlanned ? (
                                <>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <div style={{color: '#0E375A'}}>
                                                    <b>Already Loaded</b>
                                                </div>
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent style={{paddingTop: '7px', backgroundColor: '#dcdcdc', color: 'black'}}>
                                            <span>You are already on the road with a load. Check back here after to get a new one!</span>
                                        </IonCardContent>
                                    </IonCard>
                                </>
                            ) : notModeled ? (
                                <>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <div style={{color: '#0E375A'}}>
                                            <b>Not Available</b>
                                        </div>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent style={{paddingTop: '7px', backgroundColor: '#dcdcdc', color: 'black'}}>
                                    <span>
                                        <b>This feature is not currently available for your unit. Please try again later.</b>
                                    </span>
                                </IonCardContent>
                            </>
                            ) : (
                                <>
                                    {loads.map(function (element: any) {
                                        return (
                                            <IonCard style={{borderRadius: '10px', background: '#ffffff'}}>
                                                <IonCardHeader style={{paddingTop: '3px'}}>
                                                    <img className='load-arrow-background' src={map} alt='arrow'/>
                                                    <div>
                                                        <div style={{marginTop: '8px', textAlign: 'start'}}>
                                                            <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px'}}>
                                                                Order #:{' '}
                                                                <b>
                                                                    <span style={{color: '#3F77E2', textAlign: 'center'}}>{element.orderNumber}</span>
                                                                </b>
                                                            </IonCardSubtitle>
                                                        </div>
                                                        <div style={{marginTop: '8px', textAlign: 'start'}}>
                                                            <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px', lineHeight: '22px'}}>
                                                                Origin City/State: <br/>
                                                                <b>
																	<span style={{color: '#ED8254', textAlign: 'center'}}>
																		{element.details.shipperCity} | {element.details.shipperState}
																	</span>
                                                                </b>
                                                            </IonCardSubtitle>
                                                        </div>
                                                        <div style={{marginTop: '8px', textAlign: 'start'}}>
                                                            <IonCardSubtitle style={{color: '#9e9e9e', fontSize: '16px', lineHeight: '22px'}}>
                                                                Dest. City/State: <br/>
                                                                <b>
																	<span style={{color: '#3F77E2', textAlign: 'center'}}>
																		{element.details.consigneeCity} | {element.details.consigneeState}
																	</span>
                                                                </b>
                                                            </IonCardSubtitle>
                                                        </div>
                                                    </div>
                                                </IonCardHeader>
                                                <IonCardContent
                                                    style={{
                                                        background: '#ffffff',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px',
                                                        paddingBottom: '0px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRadius: '10px',
                                                            display: 'flex',
                                                            textAlign: 'center',
                                                            boxShadow: '0px -2px 2px #CECECE',
                                                        }}
                                                    >
                                                        <div style={{marginTop: '10px', width: '50%'}}>
                                                            <IonCardSubtitle style={{color: '#9e9e9e'}}>
                                                                Pickup Time:
                                                                <br/>
                                                                <span style={{borderTop: '1px solid #9e9e9e', paddingTop: '2px', color: '#3F77E2'}}>
																	<b>{element.details.pickupStartDate} | {element.details.pickupStartTime} CST</b>
																	<br/>
																</span>
                                                                <span style={{color: '#3F77E2'}}>
																	<b>{element.details.pickupEndDate} | {element.details.pickupEndTime} CST</b>
																</span>
                                                            </IonCardSubtitle>
                                                        </div>
                                                        <div style={{marginTop: '10px', width: '50%'}}>
                                                            <IonCardSubtitle style={{color: '#9e9e9e'}}>
                                                                Delivery Time:
                                                                <br/>
                                                                <span style={{borderTop: '1px solid #9e9e9e', paddingTop: '2px', color: '#ED8254'}}>
																	<b>{element.details.deliveryStartDate} | {element.details.deliveryStartTime} CST</b>
																	<br/>
																</span>
                                                                <span style={{color: '#ED8254'}}>
																	<b>{element.details.deliveryEndDate} | {element.details.deliveryEndTime} CST</b>
																</span>
                                                            </IonCardSubtitle>
                                                        </div>
                                                    </div>
                                                    <div style={{display: 'flex', textAlign: 'center'}}>
                                                        <IonButton
                                                            style={{
                                                                background: '#3F77E2',
                                                                "--background": "#3F77E2",
                                                                width: '50%',
                                                                color: 'white',
                                                                '--border-radius': '10px',
                                                                borderRadius: '10px',
                                                                boxShadow: '0px 2px 2px #CECECE',
                                                            }}
                                                            onClick={() => {
                                                                setShowModal2(true)
                                                                setSelectedLoad(element)
                                                            }}
                                                        >
                                                            Request
                                                        </IonButton>
                                                        <IonButton
                                                            style={{
                                                                background: '#ED8254',
                                                                '--background': '#ED8254',
                                                                width: '50%',
                                                                color: 'white',
                                                                '--border-radius': '10px',
                                                                borderRadius: '10px',
                                                                boxShadow: '0px 2px 2px #CECECE',
                                                            }}
                                                            onClick={() => {
                                                                setShowModal1(true)
                                                                console.log('selectedLoad', element)
                                                                setSelectedLoad(element)
                                                            }}
                                                        >
                                                            Details
                                                        </IonButton>
                                                    </div>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    })}
                                </>
                            )}
                        </IonList>
                    ) : (
                        <div className='loading-box'>
                            <Loader type='Oval' color='#208cff' height={'15vw'} width={'15vw'}/>
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default LoadSelection
