import {IonBackButton, IonButtons, IonCard, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import React, {useEffect, useState} from "react"
import {close} from 'ionicons/icons';
import OrientationRoadTest from "./OrientationRoadTest"
import {ApiService} from "../api/ApiService";
import {OrientationDriverList} from "../api/model/OrientationDriverList";
import {Link} from "react-router-dom";

const OrientationDrivers: React.FC = () => {

    const [startTime, setStartTime] = useState(new Date().toJSON().slice(0, 10));
    const [endTime, setEndTime] = useState(new Date(new Date().getTime() + 86400000).toJSON().slice(0, 10));
    const [drivers, setDrivers] = useState<OrientationDriverList[]>([]);
    const [showRoadTest, setShowRoadTest] = useState(false);
    const [driverName, setDriverName] = useState("Unknown");
    const [driverId, setDriverId] = useState("");
    const [terminal, setTerminal] = useState(localStorage.getItem("terminal") ?? "NAS");

    useEffect(() => {
        getOrientationDrivers(terminal, startTime, endTime);
    }, []);

    const available = drivers.map((driver, index) => {
        return (
            <IonItem key={index} button onClick={() => {
                selectDriver(driver.name, driver.governmentId);
                setShowRoadTest(true)
            }} color="light">
                <div className="item-container"><p>{driver.name}</p></div>
            </IonItem>
        )
    })

    const getOrientationDrivers = (terminal: string, startTime: string, endTime: string) => {
        ApiService.getOrientationDriverList("orientation", {
            disqualified: 0,
            startTime: startTime.slice(0, 10),
            endTime: endTime.slice(0, 10),
            start: 0,
            length: 1000,
            terminal: terminal
        }).then(it => setDrivers(it));
    }

    const selectDriver = (name: string, id: string) => {
        setDriverName(name);
        setDriverId(id);
    }

    const handleStartTimeChange = (time: string) => {
        setStartTime(time);
        setDrivers([]);
        getOrientationDrivers(terminal, time, endTime);
    }

    const handleEndTimeChange = (time: string) => {
        setEndTime(time);
        setDrivers([]);
        getOrientationDrivers(terminal, startTime, time);
    }

    const handleTerminal = (terminal: string) => {
        if (localStorage.getItem('terminal') === null) {
            localStorage.setItem('terminal', terminal);
        }
        setTerminal(terminal);
        setDrivers([]);
        getOrientationDrivers(terminal, startTime, endTime);
    }

    function closeRoadTest() {
        setShowRoadTest(false);
        window.location.reload();
    }

    function resetTerminal() {
        localStorage.removeItem('terminal');
        setTerminal('NAS');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Orientation</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <div className="container">
                    <IonItem lines="none" color="light">
                        <div className="item-container">
                            <p className="title">Filter:</p>
                            <div className="input-container">
                                <select id="state" className="input" placeholder=" " value={terminal} onChange={e => handleTerminal(e.target.value!)} style={{height: "20px"}}>
                                    <option value="VIR">VIR</option>
                                    <option value="PLA">PLA</option>
                                    <option value="NAZ">NAZ</option>
                                    <option value="NAS">NAS</option>
                                    <option value="FON">FON</option>
                                    <option value="BTW">BTW</option>
                                    <option value="AHO">AHO</option>
                                </select>
                                <p className="placeholder">Select Terminal</p>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem lines="none" color="light">
                        <div className="item-container" style={{display: "flex", gap: "8px"}}>
                            <div className="input-container">
                                <input type="date" className="input" value={startTime} onChange={e => handleStartTimeChange(e.target.value!.slice(0, 10))}/>
                                <p className="placeholder">From</p>
                            </div>
                            <div className="input-container">
                                <input type="date" className="input" value={endTime} onChange={e => handleEndTimeChange(e.target.value!.slice(0, 10))}/>
                                <p className="placeholder">To</p>
                            </div>
                        </div>
                    </IonItem>
                    <IonList style={{padding: 0}}>
                        {available.length > 0 ? available : (
                            <IonItem lines="none" color="light">
                                <div className="item-container"><p style={{color: "#FF0000"}}>No Drivers available for evaluation.</p></div>
                            </IonItem>)}
                        <IonItem color='light' lines="none">
                            <div className="item-container" style={{backgroundColor: "#FFFFFF", padding: "8px", borderRadius: "10px"}}>
                                <p className="title">Manage the list you see here</p>
                                <p className="subtitle">If you were expecting drivers for the dates selected, contact your supervisor to assist you.</p>
                                <button className="western-button" onClick={() => resetTerminal()}><p>Reset Terminal</p></button>
                                <Link to="/chainofcommand" style={{fontFamily: "Montserrat, san-serrif", fontWeight: "600"}}><p>Who's my Supervisor?</p></Link>
                            </div>
                        </IonItem>
                    </IonList>
                </div>
            </IonContent>
            <IonModal isOpen={showRoadTest} cssClass='my-custom-class'>
                <IonCard className="container" color="gains" style={{overflowY: "auto", width: "100%", height: "100%", margin: 0, borderRadius: 0}}>
                    <IonCardHeader>
                        <IonCardTitle>Driver Evaluation Form
                            <IonIcon style={{float: "right", fontSize: "25px"}} color="primary" icon={close} onClick={() => closeRoadTest()}/>
                        </IonCardTitle>
                    </IonCardHeader>
                    <OrientationRoadTest name={driverName} governmentId={driverId} hideTest={() => {
                        setShowRoadTest(false)
                    }}></OrientationRoadTest>
                </IonCard>
            </IonModal>
        </IonPage>
    )
}

export default OrientationDrivers
