import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonIcon, IonButtons, IonBackButton } from '@ionic/react';

import { call } from 'ionicons/icons';

const ServiceLocationsIA: React.FC = () => {
  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Help/servicelocations" />
          </IonButtons>
          <IonTitle>Service Location: IA</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <iframe title="IA Map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-C99R1wtlYAZdY8SxrbY_te_Exs7Tfus&q=2039+Quail+Ave,Fort+Dodge+iA">
          </iframe>
          <IonCard>
            <IonCardContent>
              <h1>2039 Quail Ave</h1>
              <h2>Fort Dodge, IA 50501</h2>
              <IonButton id="western-button" href="tel:800-654-0164" expand="block" >
                <IonIcon slot="start" icon={call}></IonIcon>
                 800-654-0164
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Directions</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              FROM US 20 EAST OR WEST TAKE COUNTY ROAD P59 (EXIT 124) NORTH AROUND 2 CURVES, OVER TRACKS, ON THE LEFT (WEST SIDE) 3 MILES NORTH.
            </IonCardContent>
            </IonCard>
      
      </IonContent>
    </IonPage>
  );
};

export default ServiceLocationsIA;
