import React, {useEffect, useState} from "react"
import {IonItem, IonModal} from "@ionic/react"
import keyIcon from "../imgs/key.png"
import chainIcon from "../imgs/chain.png"
import callIcon from "../imgs/call.png"
import safeIcon from "../imgs/safety.png"
import helpdeskIcon from "../imgs/helpdesk.png"
import accidentsIcon from "../imgs/airbag.png"
import ContactCard from "./ContactCard"
import {userStore} from "../api/AuthService";
import {ApiService} from "../api/ApiService";
import {DriverManager} from "../api/model/DriverManager";
import {Link} from "react-router-dom";

const Contacts = () => {
    const [showDM, setShowDM] = useState(false);
    const [dm, setDM] = useState<DriverManager>({} as DriverManager);

    useEffect(() => {
        if (userStore.getState().driver?.dmCode) {
            ApiService.getDriverManager('contacts', {dmCode: userStore.getState().driver.dmCode}).then(it => {
                if (it) {
                    setDM(it);
                }
            });
        }
    }, []);

    return (
        <IonItem lines="none" color="light">
            <IonModal isOpen={showDM} id="transparent">
                <div className="modal-div" onClick={() => setShowDM(false)}>
                    <div className="item-container" id="modal">
                        <ContactCard contact={{name: dm.firstName + " " + dm.lastName, email: dm.email, subcategory: "Driver Agent", phone: dm.phone}}/>
                    </div>
                </div>
            </IonModal>
            <div className="item-container">
                <div style={{margin: "8px"}} className="grid50">
                    <h5>More Questions?</h5>
                    <h5 style={{color: "#5096FF", textAlign: "right"}} onClick={() => setShowDM(true)}>View Agent Info</h5>
                </div>
                <div className="grid50">
                    <Link to="/chainofcommand" className="western-button-link">
                        <div style={{height: "100%"}}>
                            <img src={chainIcon} alt="key" className="link-icon"/><p>Company Directory</p>
                        </div>
                    </Link>
                <div>
                    <Link to={`tel:${dm.phone}`} className="western-button-link">
                        <div>
                            <img src={callIcon} alt="key" style={{height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call Agent</p>
                        </div>
                    </Link>
                    <Link to="/safetycontacts" className="western-button-link">
                        <div>
                            <img src={safeIcon} alt="key" className="link-icon"/><p>Safety Contact</p>
                        </div>
                    </Link>
                </div>
                </div>
                <Link to="/helpdesk" className="western-button-link">
                    <div className="green">
                        <img src={helpdeskIcon} alt="key" style={{height: "min(10vw, 44px)", margin: '4px'}}/><p>Frequently Asked Questions</p>
                    </div>
                </Link>
                <Link className="western-button-link" to="/accidentinfo">
                    <div className="red">
                        <img src={accidentsIcon} alt="key" className="link-icon"/><p>Accidents</p>
                    </div>
                </Link>
            </div>
        </IonItem>
    )
}
export default Contacts
