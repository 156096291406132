import React, {useEffect, useState} from "react"
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonToolbar} from "@ionic/react";

const DivisionInfo = () => {
    const [divInfo, setDivInfo] = useState<any>({});
    const [additionalInfo, setAdditionalInfo] = useState(false)

    const FBOTR500 = {
        "info": ["14-16 days out/Home 2-3", "Aggressive pay increases every 30 days through the first 6 months of employment", "$25 tarp pay", "Potential to run 2,500+ miles per week", "Potential to run all 48 States"],
    }
    const EastCoastVan100 = {
        "info": ["10-12 days out/ home 2-3", "Aggressive pay increases every 30 days through the first 6 months of employment", "Potential to run 2,500+ miles per week", "Potential to run everything east of the MS river"],
    }
    const WestCoastVan800 = {
        "info": ["2 weeks out/Home 2-3 days", "Aggressive pay increases every 30 days through the first 6 months of employment", "Potential to run 2,500+ miles per week", "Potential to run Western 11 states"],
    }
    const FNER = {
        "info": ["Home weekly for 34 hour reset", "Aggressive pay increases every 30 days through the first 6 months of employment", "$25 tarp pay", "Potential to run 2,000+ miles per week", "Runs East of Columbus, OH and North of NC"],
        "columns": [
        {"name": "Miles"},
        {"name": "Loads"},
        {"name": "Minimum Pay"}],
        "rows": [
            ["1700", "6", "$1500", "$0.88"],
            ["1600", "5", "$1200", "$0.75"],
            ["1500", "4", "$1000", "$0.67"]
        ],
        "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const EastCoastRegional = {
        "info": ["Home weekly for 34 hour reset", "Aggressive pay increases every 30 days through the first 6 months of employment", "Potential to run 2,000+ miles per week", "Potential to run everything East of the MS river"],
        "columns": [
        {"name": "Miles"},
        {"name": "Loads"},
        {"name": "Minimum Pay"}],
        "rows": [
            ["2000", "6", "$1200", "$0.60"],
            ["1800", "5", "$1000", "$0.56"],
            ["1600", "4", "$800", "$0.50"]
        ],
        "overbalance": "**Every mile over 1600 paid at the greater of $0.50 or their tenure rate"
    }
    const PPV = {
        "info": ["Home weekly for 34 hour reset", "Semi-dedicated lanes, stay within defined hired region", "Dedicated planners"],
        "columns": [
        {"name": "Miles"},
        {"name": "Loads"},
        {"name": "Minimum Pay"}],
        "rows": [
            ["2000", "+8", "$1200", "$0.60"],
            ["1800", "7", "$1050", "$0.55"],
            ["1600", "6", "$800", "$0.50"]
        ],
        "overbalance": "$150 for each additional load, no cap, 2000+ miles, .50 cpm or load count pay, greater of the 2",
    }

    useEffect(() => {
        findDivisionInfo();
    }, [])

    async function findDivisionInfo() {
        if(await localStorage.getItem("userObj")) {
            let division = JSON.parse(localStorage.getItem("userObj")!).division
            console.log(division)
            switch (division) {
                case "100":
                    setDivInfo(EastCoastVan100);
                    break;
                case "500":
                    setDivInfo(FBOTR500);
                    break;
                case "800":
                    setDivInfo(WestCoastVan800);
                    break;
                case "FNR":
                    setDivInfo(FNER);
                    setAdditionalInfo(true)
                    break;
                case "NER":
                    setDivInfo(EastCoastRegional);
                    setAdditionalInfo(true)
                    break;
                case "PPV":
                    setDivInfo(PPV);
                    setAdditionalInfo(true)
                    break;
                case "VCT":
                    setDivInfo(EastCoastRegional);
                    setAdditionalInfo(true)
                    break;
                case "VVA":
                    setDivInfo(EastCoastRegional);
                    setAdditionalInfo(true)
                    break;
                default:
                    break;
            }
        }
        
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""></IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
            <IonItem lines="none"><div className="item-container"><h3><strong>Division Info</strong></h3></div></IonItem> 
            <ul>
                {divInfo.info?.map((item: string, index: number) => {
                    return <li key={index}><p>{item}</p></li>
                })}
            </ul>
            {additionalInfo ? <div><IonItem lines="none"><div className="item-container"><h3><strong>Minimum Pay</strong></h3>
            <div style={{ backgroundColor: "#ffffff" , borderRadius: "10px"}}>
                <div style={{ color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", padding: "8px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottom: "none"}}>
                    {divInfo.columns !== undefined ? divInfo.columns?.map(function(element: any) {
                                        return <p style={{ margin: 'auto'}}>{element.name}</p>   
                                }
                    ) : null}
               </div>
         
               {divInfo.rows !== undefined ?divInfo.rows?.map(function(element: any) {
                   return <div style={{color: "#212121", display: "flex", justifyContent: "space-between", border: "2px solid #eee", borderBottom: "none"}}>
                                    {element.map(function (nElement: any) {
                                        return <p style={{margin: 'auto'}}>{nElement}</p>;
                                    })}
                                </div>;
                            }
                ) : null}
                <p style={{ color: "#212121", border: "2px solid #eee", padding: '8px', margin: 0, borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>{divInfo.overbalance}</p>
            </div>
                </div></IonItem></div> : null} 
            </IonContent>
        </IonPage>
    )
}

export default DivisionInfo
