import React, {useState} from "react"
import {IonIcon} from "@ionic/react"
import {compassOutline, informationCircleOutline, personCircle} from "ionicons/icons"

const ContactCard = (props: any) => {
    const [showDirections, setShowDirections] = useState(false)
    return (
        <div className="item-container" style={{backgroundColor: "white", padding: "8px", borderRadius: "10px"}}>
            <div className="space-between">
                <div style={{width: "80%"}}>
                    <p style={{fontSize: "medium", margin: "4px"}}>{props.contact.name ? props.contact.name : props.contact.subcategory} {props.contact.title ? `- ${props.contact.title}` : ""}</p>
                    <div style={{display: "flex"}}><p style={{
                        fontSize: "small",
                        color: "#656565",
                        padding: "4px 8px",
                        backgroundColor: "#ed825463",
                        borderRadius: "16px",
                        textAlign: "center",
                        margin: 0
                    }}>{props.contact.subcategory}</p></div>
                    <p style={{fontSize: "small", margin: "4px"}}>{props.contact.email}</p>
                    <p style={{fontSize: "small", margin: "4px"}}>{props.contact.phone}<span>{props.contact.extension ? `Ext: ${props.contact.extension}` : ''}</span></p>
                </div>
                {props.location ? <IonIcon icon={compassOutline} style={{fontSize: "36px"}}></IonIcon> : <IonIcon icon={personCircle} style={{fontSize: "36px"}}></IonIcon>}
            </div>
            {props.location && props.contact.directions !== "" ?
                <div className='item-container' style={{display: "flex", alignItems: "center", gap: "4px"}} onClick={() => setShowDirections(!showDirections)}><p className='subtitle'>Directions</p>
                    <IonIcon style={{color: "#666667"}} icon={informationCircleOutline}></IonIcon></div> : null}
            {showDirections ? <div style={{backgroundColor: "#fff", padding: "8px", borderRadius: "10px"}}>{props.contact.directions}</div> : null}
        </div>
    )
}

export default ContactCard
