import React from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';

const ChainOfCommand: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Training Contact</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="light">
                <IonItem lines="none" color='light'><ContactCard
                    contact={{phone: "615-277-7615", email: "traineesupport@westernexp.com", name: "Training Contact Info", subcategory: "Training"}}/></IonItem>
            </IonContent>
        </IonPage>
    );
};

export default ChainOfCommand;

