import React from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {warning} from 'ionicons/icons';
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import callIcon from "../imgs/call.png"

const AccidentInfo: React.FC = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }

    const app = initializeApp(firebaseConfig);

    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""/>
                    </IonButtons>
                    <IonTitle>Accident Information</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className='main'>

                    <div>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <h1 className='title' style={{color: "#ff0000"}}>All accidents must be reported immediately</h1>
                                <p className='subtitle'>Follow the steps below in order.</p>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p>1. Call Emergency Services 911</p>
                                <a className="western-button-link" href="tel:911" onClick={() => handleEventLog("accident_Hotline", "contact-911")}>
                                    <div>
                                        <img src={callIcon} alt="key" style={{height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#ff0000", padding: "4px", borderRadius: "50%"}}/><p>Call
                                        Now</p>
                                    </div>
                                </a>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p>2. Call Accident Hotline (877) 459-3604</p>
                                <a className="western-button-link" href="tel:877-459-3604" onClick={() => handleEventLog("accident_Hotline", "contact-AccidentHotline")}>
                                    <div>
                                        <img src={callIcon} alt="key" style={{height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call
                                        Accident Hotline</p>
                                    </div>
                                </a>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p>3. Send in a Macro 14</p>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p>4. Take Photographs. Include the following:</p>
                                <ul>
                                    <li>A photo of your truck number</li>
                                    <li>The accident scene from multiple angles (360 degrees if possible)</li>
                                    <li>Specific damage to vehicles and property</li>
                                    <li>Skid marks, gouge marks, and debris field(s)</li>
                                    <li>Any signs or traffic control devices</li>
                                </ul>
                            </div>
                        </IonItem>
                    </div>
                    <div>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p>5. Get Witness Names and Phone Numbers</p>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <p className='subtitle'>If you are issued a traffic citation after an accident you must call it in immediately.<br/>Call the accident hotline back to provide details.
                                </p>
                            </div>
                        </IonItem>
                        <IonItem lines="none">
                            <div className='item-container'>
                                <div className="space-between">
                                    <p style={{width: "80%"}}>Failure to report an accident immediately is grounds for termination.</p>
                                    <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                </div>
                                <div className="space-between">
                                    <p style={{width: "80%"}}>Failure to report a citation issued as a result of an accident immediately is grounds for termination.</p>
                                    <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                </div>
                                <div className="space-between">
                                    <p style={{width: "80%"}}>Any DOT post-accident drug and alcohol test which is missed due to a driver's failure to report or reporting late will be considered a
                                        refusal.</p>
                                    <IonIcon icon={warning} style={{color: "#ffc409", fontSize: "24px"}}></IonIcon>
                                </div>
                            </div>
                        </IonItem>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AccidentInfo;
