import {IonButton, IonIcon, isPlatform} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {config} from '../Constants'
import {AppLauncher} from '@capacitor/app-launcher';
import {chevronForward} from 'ionicons/icons';
import AnimateHeight from 'react-animate-height';

import agreement from '../imgs/work/agreement.png';
import contacts from '../imgs/work/contacts.png';
import delivery from '../imgs/work/delivery.png';
import history from '../imgs/work/history.png';
import reportCard from '../imgs/work/report-card.png';
import report from '../imgs/work/report.png';
import resume from '../imgs/work/resume.png';
import certificate from '../imgs/work/certificate.png';
import time from '../imgs/work/time.png';
import trainee from '../imgs/work/trainee.png';
import calendar from '../imgs/drive/calendar.png';
import maps from '../imgs/drive/maps.png';
import payroll from '../imgs/drive/payroll.png';
import salary from '../imgs/drive/salary.png';
import search from '../imgs/drive/search.png';
import clipboard from '../imgs/drive/clipboard.png';
import trailer from '../imgs/drive/trailer.png';
import tarp from '../imgs/drive/tarp.png';
import caution from '../imgs/help/caution.png';
import group from '../imgs/help/group.png';
import shield from '../imgs/help/shield.png';
import technicalSupport from '../imgs/help/technical-support.png';
import welfare from '../imgs/help/welfare.png';
import wrench from '../imgs/help/wrench.png';
import cone from '../imgs/help/traffic-cone.png';
//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {ApiService} from "../api/ApiService";
import {userStore} from "../api/AuthService";
import {PlatformUtils} from "../utils/PlatformUtils";

const SideMenu: React.FC<{ handleMenuToggle: any }> = ({handleMenuToggle}) => {

    const [hasPlatSci, setHasPlatSci] = useState(false);
    const [storeLink, setStoreLink] = useState('');
    const [height1, setHeight1] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [height3, setHeight3] = useState(0);
    const [height4, setHeight4] = useState(0);
    const [height5, setHeight5] = useState(0);
    const [height6, setHeight6] = useState(0);
    const [height7, setHeight7] = useState(0);
    const [height8, setHeight8] = useState(0);

    const firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
    }

    const app = initializeApp(firebaseConfig)

    const handleEventLog = (eventType: string, info: string) => {
        const analytics = getAnalytics(app);
        logEvent(analytics, eventType, {eventType: info})
        console.log(eventType);
    }

    useEffect(() => {
        setStoreLink(PlatformUtils.getStoreLink());
        setHasPlatSci(userStore.getState().driver.platsci);
    }, [])

    const transfloClick = () => {
        ApiService.sendEventCommand('Tranflo Route', 'eventCheck', 'User has been routed to the Transflo app');
        const deepLink = `transflomobileplus://scan?recipientid=WSXI&sourceapp=westerndrivermobile&doctypes=TOD,BOL,TRP&driverid=${localStorage.getItem('username')?.toUpperCase()}&truckid=${userStore.getState().driver.unit}`;
        AppLauncher.openUrl({url: deepLink});
    }

    const setDefaultToggleState = async () => {
        setHeight1(0);
        setHeight2(0);
        setHeight3(0);
        setHeight4(0);
        setHeight5(0);
        setHeight6(0);
        setHeight7(0);
        setHeight8(0);
    }

    const toggle = async () => {
        if (height1 === 0) {
            await setDefaultToggleState();
            setHeight1(1);
        } else {
            setHeight1(0)
        }
    }

    const toggle2 = async () => {
        if (height2 === 0) {
            await setDefaultToggleState();
            setHeight2(1);
        } else {
            setHeight2(0)
        }
    }

    const toggle3 = async () => {
        if (height3 === 0) {
            await setDefaultToggleState();
            setHeight3(1);
        } else {
            setHeight3(0)
        }
    }

    const toggle4 = async () => {
        if (height4 === 0) {
            await setDefaultToggleState();
            setHeight4(1);
        } else {
            setHeight4(0)
        }
    }

    const toggle5 = async () => {
        if (height5 === 0) {
            await setDefaultToggleState();
            setHeight5(1);
        } else {
            setHeight5(0)
        }
    }

    const toggle6 = async () => {
        if (height6 === 0) {
            await setDefaultToggleState();
            setHeight6(1);
        } else {
            setHeight6(0)
        }
    }

    const toggle7 = async () => {
        if (height7 === 0) {
            await setDefaultToggleState();
            setHeight7(1);
        } else {
            setHeight7(0)
        }
    }

    const toggle8 = async () => {
        if (height8 === 0) {
            await setDefaultToggleState();
            setHeight8(1);
        } else {
            setHeight8(0)
        }
    }

    const renderSidebar = () => {
        return <div className="sidebar-menu" onClick={e => e.stopPropagation()}>
            <div className={config.isPlatformSci ? "sidebar-menu-area-plat-sci" : "sidebar-menu-area"} style={{marginBottom: '8vh'/*, paddingTop:'5px'*/}}>

                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={e => {
                    e.stopPropagation();
                    toggle()
                }} aria-expanded={height1 !== 0}>
                    <div id="sidebar-text">Load</div>
                </IonButton>
                <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height1 === 1 ? 'auto' : 0} id="loadSection">
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/currentload" onClick={() => handleEventLog("load", "access-CurrentLoad")}>
                        <div className="menu-item-icon"><img alt="" src={trailer}/></div>
                        <div id="sidebar-text">Current Load</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/loadhistory" onClick={() => handleEventLog("load", "access-LoadHistory")}>
                        <div className="menu-item-icon"><img alt="" src={search}/></div>
                        <div id="sidebar-text">Load History</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/preplanned" onClick={() => handleEventLog("load", "access-PrePlanned")}>
                        <div className="menu-item-icon"><img alt="" src={calendar}/></div>
                        <div id="sidebar-text">Preplanned Loads</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                </AnimateHeight>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle2} aria-expanded={height2 !== 0}>
                    <div id="sidebar-text">Trip</div>
                </IonButton>
                <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height2 === 1 ? 'auto' : 0} id="loadSection">
                    {hasPlatSci ? <IonButton className="home-menu-item" color="transparent" href="/Drive/pretrip" onClick={() => handleEventLog("trip", "access-PreTrip")}>
                        <div className="menu-item-icon"><img alt="" src={clipboard}/></div>
                        <div id="sidebar-text">Pre-Trip</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton> : null}
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/photoupload" onClick={() => handleEventLog("trip", "access-PhotoUpload")}>
                        <div className="menu-item-icon"><img alt="" src={tarp}/></div>
                        <div id="sidebar-text">Tarp Pay</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/trailertracking" onClick={() => handleEventLog("trip", "access-TrailerTracking")}>
                        <div className="menu-item-icon"><img alt="" src={maps}/></div>
                        <div id="sidebar-text">Trailer Request</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                </AnimateHeight>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle3} aria-expanded={height3 !== 0}>
                    <div id="sidebar-text">Money</div>
                </IonButton>
                <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height3 === 1 ? 'auto' : 0} id="loadSection">
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/payroll" onClick={() => handleEventLog("money_Payment", "access-Payroll")}>
                        <div className="menu-item-icon"><img alt="" src={payroll}/></div>
                        <div id="sidebar-text">Payroll</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Drive/personaladvance" onClick={() => handleEventLog("money_Payment", "access-PersonalAdvance")}>
                        <div className="menu-item-icon"><img alt="" src={salary}/></div>
                        <div id="sidebar-text">Personal Advance</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                </AnimateHeight>
                {hasPlatSci ? <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" href="/Drive/breakdown" onClick={() => handleEventLog("breakdown", "access-Breakdown")}>
                    <div id="sidebar-text">Breakdown</div>
                </IonButton> : null}
                {(isPlatform('desktop') || isPlatform('iphone') || isPlatform('ipad')) ? <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={() => {
                        window.open(storeLink);
                        handleEventLog("scan_sideMenu", "access-ScanDocument")
                    }}>
                        <div id="sidebar-text">Scan Documents</div>
                    </IonButton> :
                    <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={() => {
                        transfloClick()
                    }}>
                        <div id="sidebar-text">Scan Documents</div>
                    </IonButton>}
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" href="/Work/passenger" onClick={() => handleEventLog("passenger", "access-PassengerPolicy")}>
                    <div id="sidebar-text">Add Passenger</div>
                </IonButton>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" href="/Work/referral" onClick={() => handleEventLog("driver_Referral", "access-Referral")}>
                    <div id="sidebar-text">Driver Referral</div>
                </IonButton>

                {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ? <>
                    <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle4} aria-expanded={height4 !== 0}>
                        <div id="sidebar-text">Training</div>
                    </IonButton>
                    <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height4 === 1 ? 'auto' : 0} id="loadSection">
                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainingday">
                            <div className="menu-item-icon"><img alt="" src={report}/></div>
                            <div id="sidebar-text">Training Day</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/traineeprofile">
                            <div className="menu-item-icon"><img alt="" src={trainee}/></div>
                            <div id="sidebar-text">Trainee Profiles</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/releasetrainee">
                            <div className="menu-item-icon"><img alt="" src={certificate}/></div>
                            <div id="sidebar-text">Release Trainee</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/dailyreports">
                            <div className="menu-item-icon"><img alt="" src={time}/></div>
                            <div id="sidebar-text">Daily Reports</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/backingevents">
                            <div className="menu-item-icon"><img alt="" src={delivery}/></div>
                            <div id="sidebar-text">Backing Events</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/agreements">
                            <div className="menu-item-icon"><img alt="" src={agreement}/></div>
                            <div id="sidebar-text">Agreements</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainingcontacts">
                            <div className="menu-item-icon"><img alt="" src={contacts}/></div>
                            <div id="sidebar-text">Training Contact</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/newtrainee">
                            <div className="menu-item-icon"><img alt="" src={resume}/></div>
                            <div id="sidebar-text">New Trainee</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                    </AnimateHeight> </> : null}
                {/*[...]*/}
                {localStorage.getItem('type') === "trainee" || localStorage.getItem('type') === "admin" ? <>
                    <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle5} aria-expanded={height4 !== 0}>
                        <div id="sidebar-text">Course (Trainee)</div>
                    </IonButton>
                    <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height5 === 1 ? 'auto' : 0} id="loadSection">
                        <IonButton className="home-menu-item" color="transparent" href="/Work/traineeprofile">
                            <div className="menu-item-icon"><img alt="" src={trainee}/></div>
                            <div id="sidebar-text">Trainee Profile</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainerfeedback">
                            <div className="menu-item-icon"><img alt="" src={clipboard}/></div>
                            <div id="sidebar-text">Daily Feedback</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/trainingcontacts">
                            <div className="menu-item-icon"><img alt="" src={contacts}/></div>
                            <div id="sidebar-text">Training Contacts</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/agreements">
                            <div className="menu-item-icon"><img alt="" src={agreement}/></div>
                            <div id="sidebar-text">Agreements</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                    </AnimateHeight> </> : null}

                {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ? <> <IonButton className="sidebar-btn" strong
                                                                                                                                                                         fill="solid" color="tertiary"
                                                                                                                                                                         onClick={toggle6}
                                                                                                                                                                         aria-expanded={height5 !== 0}>
                    <div id="sidebar-text">Orientation</div>
                </IonButton>
                    <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height6 === 1 ? 'auto' : 0} id="loadSection">
                        <IonButton className="home-menu-item" color="transparent" href="/Work/roadtests">
                            <div className="menu-item-icon"><img alt="" src={reportCard}/></div>
                            <div id="sidebar-text">Road Tests</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                        <IonButton className="home-menu-item" color="transparent" href="/Work/retests">
                            <div className="menu-item-icon"><img alt="" src={history}/></div>
                            <div id="sidebar-text">Re-Test</div>
                            <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                        </IonButton>
                    </AnimateHeight> </> : null}
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" href="/Help/accidentinfo" onClick={() => handleEventLog("accidents", "access-AccidentInfo")}>
                    <div id="sidebar-text">Accidents</div>
                </IonButton>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle7} aria-expanded={height7 !== 0}>
                    <div id="sidebar-text">Contacts</div>
                </IonButton>
                <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height7 === 1 ? 'auto' : 0} id="loadSection">
                    <IonButton className="home-menu-item" color="transparent" href="/Help/keycontacts" onClick={() => handleEventLog("contacts", "access-KeyContacts")}>
                        <div className="menu-item-icon"><img alt="" src={caution}/></div>
                        <div id="sidebar-text">Key Contacts</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Help/safety/contacts" onClick={() => handleEventLog("contacts", "access-SafetyContacts")}>
                        <div className="menu-item-icon"><img alt="" src={cone}/></div>
                        <div id="btn-text-submenu">Safety</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Help/chainofcommand" onClick={() => handleEventLog("contacts", "access-ChainOfCommand")}>
                        <div className="menu-item-icon"><img alt="" src={group}/></div>
                        <div id="sidebar-text">Chain of Command</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Help/helpdesk" onClick={() => handleEventLog("contacts", "access-Helpdesk")}>
                        <div className="menu-item-icon"><img alt="" src={technicalSupport}/></div>
                        <div id="sidebar-text">Helpdesk</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                </AnimateHeight>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" href="/Help/servicelocations" onClick={() => handleEventLog("service_Locations", "access-ServiceLocations")}>
                    <div id="sidebar-text">Service Locations</div>
                </IonButton>
                <IonButton className="sidebar-btn" strong fill="solid" color="tertiary" onClick={toggle8} aria-expanded={height8 !== 0}>
                    <div id="sidebar-text">Docs & Videos</div>
                </IonButton>
                <AnimateHeight style={{background: "#c6c6c6", width: '100vw', overflowY: 'scroll'}} duration={500} height={height8 === 1 ? 'auto' : 0} id="loadSection">
                    <IonButton className="home-menu-item" color="transparent" href="/Help/safety" onClick={() => handleEventLog("docs", "access-SafetyDocs")}>
                        <div className="menu-item-icon"><img alt="" src={shield}/></div>
                        <div id="sidebar-text">Safety</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Help/maintenancevids" onClick={() => handleEventLog("docs", "access-MaintenanceVids")}>
                        <div className="menu-item-icon"><img alt="" src={wrench}/></div>
                        <div id="sidebar-text">Maintience</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                    <IonButton className="home-menu-item" color="transparent" href="/Help/benefits" onClick={() => handleEventLog("docs", "access-Benefits")}>
                        <div className="menu-item-icon"><img alt="" src={welfare}/></div>
                        <div id="sidebar-text">Benefits</div>
                        <div className="btn-menu-arrow"><IonIcon style={{color: 'black'}} slot="icon-only" icon={chevronForward}/></div>
                    </IonButton>
                </AnimateHeight>
                <IonButton className="sidebar-btn" style={{'--background': '#ee5b67'}} strong fill="solid" onClick={() => ApiService.userLogout('sideMenu')}>
                    <div id="sidebar-text" style={{color: "#f8f8f8"}}>Logout</div>
                </IonButton>
                <IonButton className="sidebar-btn-v" style={{'--background': 'transparent'}} strong fill="solid"
                           onClick={() => AppLauncher.openUrl({url: 'market://details?id=com.westernexp.mobile'})}>
                    <div id="sidebar-text">App Version: 2.10.2</div>
                </IonButton>
            </div>
        </div>
    }

    return (
        <div className='sidebar-base' onClick={handleMenuToggle}>
            {renderSidebar()}
        </div>
    );

};
export default SideMenu;
